import { ErrorMessage, Field, Formik } from 'formik';
import { Avatar } from 'modules/homepage/components/atoms';
import { Button, Typography } from 'modules/shared/components/atoms';
import React, { useState } from 'react';
import * as Yup from 'yup';
const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
});

const jobTitleValidationSchema = Yup.object().shape({
  jobTitle: Yup.string().required('Job title is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
});

const EmailForwarding = () => {
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div className="grid gap-10">
      <article className="w-full p-8 shadow-lg rounded-md ">
        <Typography className="font-semibold text-xl tracking-widest text-primary">Preferred date format</Typography>

        <div className="grid gap-8 px-4 pt-8">
          <div className="flex gap-4 items-center">
            <Avatar classNames="w-6 h-6 font-normal text-sm" avatarUrl={''} alt={'1'} />
            <Typography className="font-semibold text-base tracking-wider ">
              Forward your candidate to this email
            </Typography>
          </div>
          <Formik initialValues={{ email: '' }} validationSchema={emailValidationSchema} onSubmit={() => {}}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="grid gap-8">
                <div className="flex items-center gap-4">
                  <Typography className="text-base right-6 bottom-8 w-20 tracking-widest">Email</Typography>
                  <div className="relative w-1/2">
                    <img
                      src="/assets/icons/document_icon.svg"
                      alt="icon"
                      className="absolute right-6 top-3 h-6 w-5 z-50 cursor-pointer"
                      onClick={() => handleCopyToClipboard(values.email)}
                    />
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Company@mail.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={`bg-white-100 py-3 px-6 w-full rounded-md ${
                        errors.email && touched.email ? 'border border-error' : ''
                      }`}
                    />
                    <ErrorMessage name="email" component="div" className="text-error text-sm" />
                  </div>
                </div>
                <div className="grid gap-2">
                  <div className="flex gap-4 items-center">
                    <Avatar classNames="w-6 h-6 font-normal text-sm" avatarUrl={''} alt={'2'} />
                    <Typography className="font-semibold text-base tracking-wider ">All Done !</Typography>
                  </div>
                  <Typography className="text-base right-6 bottom-8  tracking-widest">
                    Upon receiving your email, Our system will create the candidate in your account.
                  </Typography>
                </div>
                <div className="grid w-full justify-end">
                  <Button classNames={'w-20 py-2 px-16 tracking-wider bg-primary text-white border-none'} type="submit">
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </article>
      <article className="w-full p-8 shadow-lg rounded-md ">
        <Typography className="font-semibold text-xl tracking-widest text-primary">Add a candidate to a job</Typography>

        <div className="grid gap-8 px-4 pt-8 ">
          <Typography className="text-base tracking-widest">
            Have them added to the job of your choice by sending an email.
          </Typography>
          <div className="flex gap-4 items-center">
            <Avatar classNames="w-6 h-6 font-normal text-sm" avatarUrl={''} alt={'1'} />
            <Typography className="font-semibold text-base tracking-wider ">
              Select the job the candidate will be added to
            </Typography>
          </div>
          <Formik
            initialValues={{ jobTitle: '', email: '' }}
            validationSchema={jobTitleValidationSchema}
            onSubmit={() => {}}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="grid gap-8">
                <div className="grid items-center gap-8">
                  <div className="flex items-center gap-4">
                    <Typography className="text-base right-6 bottom-8 w-20 tracking-widest">Job title</Typography>
                    <div>
                      <Field
                        type="text"
                        id="jobTitle"
                        name="jobTitle"
                        placeholder="Write a job title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jobTitle}
                        className={`bg-white-100 py-3 px-6 w-full rounded-md ${
                          errors.jobTitle && touched.jobTitle ? 'border border-error' : ''
                        }`}
                      />
                      <ErrorMessage name="jobTitle" component="div" className="text-error text-sm" />
                    </div>
                  </div>
                  <div className="flex gap-4 items-center">
                    <Avatar classNames="w-6 h-6 font-normal text-sm" avatarUrl={''} alt={'2'} />
                    <Typography className="font-semibold text-base tracking-wider ">
                      Forward your candidate to this email
                    </Typography>
                  </div>
                  <div className="flex items-center gap-4">
                    <Typography className="text-base right-6 bottom-8 w-20 tracking-widest">Email</Typography>
                    <div className="relative w-1/2">
                      <img
                        src="/assets/icons/document_icon.svg"
                        alt="icon"
                        className="absolute right-6 top-3 h-6 w-5 z-50 cursor-pointer"
                        onClick={() => handleCopyToClipboard(values.email)}
                      />
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Company@mail.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={`bg-white-100 py-3 px-6 w-full rounded-md ${
                          errors.email && touched.email ? 'border border-error' : ''
                        }`}
                      />
                      <ErrorMessage name="email" component="div" className="text-error text-sm" />
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <div className="flex gap-4 items-center">
                      <Avatar classNames="w-6 h-6 font-normal text-sm" avatarUrl={''} alt={'3'} />
                      <Typography className="font-semibold text-base tracking-wider ">All Done !</Typography>
                    </div>
                    <Typography className="text-base right-6 bottom-8  tracking-widest">
                      Upon receiving your email, Our system will create the candidate in your account.
                    </Typography>
                  </div>
                  <div className="grid w-full justify-end">
                    <Button classNames={'w-20 py-2 px-16 tracking-wider bg-primary text-white border-none'}>
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </article>
    </div>
  );
};

export default EmailForwarding;
