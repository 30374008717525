import React from 'react';

const EducationCard = ({ educationDAta }) => {
  return (
    <div className="p-5 bg-white shadow-lg border border-gray-100 rounded-lg mt-10">
      <h2 className="text-xl ">Education</h2>

      {educationDAta.map((education, index) => {
        return (
          <div key={index} className="mt-4 flex flex-col gap-2">
            <h3 className="text-lg">{education.degree}</h3>
            <p className="text-gray-200">
              {education.start_date} - {education.end_date ? education.end_date : 'Present'}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default EducationCard;
