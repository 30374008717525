import React, { useState, useEffect, useRef } from 'react';
import { Button, TextInput } from 'modules/shared/components/atoms';

const colors = ['#077D96', '#6B6B6B', '#E92C2C', '#00599A', '#00D609', '#694C86', '#8CD4ED', '#FF9F2D', '#FFEA00'];

const AddNewTag = ({ updateTag, closeModal, onAddTag, selectedTag }) => {
  const [selectedColor, setSelectedColor] = useState('');
  const [tagText, setTagText] = useState('');
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const colorPickerRef = useRef(null);

  useEffect(() => {
    if (selectedTag) {
      setSelectedColor(selectedTag.color);
      setTagText(selectedTag.name);
    }
  }, [selectedTag]);
  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
  };
  const handleTagTextChange = (e) => {
    setTagText(e.target.value);
  };

  const handleAddTag = (e) => {
    e.preventDefault();
    if (selectedColor && tagText) {
      if (selectedTag) {
        // Update existing tag
        updateTag({ ...selectedTag, name: tagText, color: selectedColor });
      } else {
        // Add new tag
        onAddTag(selectedColor, tagText);
      }
      closeModal();
    }
  };

  const handleIconClick = () => {
    setIsColorPickerOpen(true);
    colorPickerRef.current.click();
  };

  return (
    <div>
      <form onSubmit={handleAddTag} className="grid gap-4 mt-6">
        <div>
          <TextInput
            classNames="text-lg "
            isListOpenedClass="text-base "
            type="text"
            id="tagText "
            label="Tag Text *"
            onBlur={() => setIsColorPickerOpen(false)}
            value={tagText}
            onChange={handleTagTextChange}
            className="border rounded p-2"
          />
        </div>
        <div className="grid gap-2 items-center">
          <label htmlFor="tagText" className={`block text-base `}>
            Enter Tag Text:
          </label>
          <div className="flex gap-3">
            <div className="flex gap-3 items-center text-center">
              {colors.map((color, index) => (
                <div
                  className={`w-12 h-12 rounded-full ${selectedColor === color ? 'border-2 border-white' : ''}`}
                  key={index}
                  style={{
                    backgroundColor: color,
                    boxShadow: selectedColor === color ? `0 0 5px ${color}` : 'none',
                  }}
                  onClick={() => setSelectedColor(color)}
                  role="button"
                ></div>
              ))}
            </div>
            <div
              className="border-none rounded-full w-12 h-12 bg-primary bg-opacity-10 p-2  flex items-center justify-center cursor-pointer"
              onClick={handleIconClick}
            >
              <span role="img" aria-label="color-picker" className="text-white">
                <img className="filter-primary" src="/assets/icons/icon_plus.svg" alt="" />
              </span>
            </div>
          </div>
          <input
            type="color"
            id="colorPicker"
            value={selectedColor}
            onChange={handleColorChange}
            ref={colorPickerRef}
            className="hidden"
          />
        </div>
        <Button type="submit" classNames="bg-primary px-4 py-3 w-3/4 mx-auto tracking-widest text-white border-none">
          {updateTag ? 'Save' : 'Add tag'}
        </Button>
      </form>
    </div>
  );
};
export default AddNewTag;
