import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

const Dropdown = ({ buttonContent, menuItems, iconUrl, classNames = '', buttonClassName = '' }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = () => {
    setDropdownOpen(false);
    // Add any additional logic when an item is clicked
  };

  return (
    <div className={twMerge(`relative inline-flex flex-col items-center`, classNames)}>
      <button
        className={twMerge(
          `outline-none font-medium rounded-md border-white-300 px-5 py-2.5 text-center inline-flex items-center`,
          buttonClassName,
        )}
        type="button"
        onClick={toggleDropdown}
      >
        {buttonContent}
        <img className="ml-2 first-letter:" src={iconUrl} alt="" />
      </button>

      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div className="z-10 w-full bg-white divide-y rounded-md shadow-xs    absolute mt-12">
          <ul className="py-2 text-sm text-gray ">
            {menuItems.map((item, index) => (
              <li key={index}>
                <a href={item.link} className="block px-4 py-2 hover:bg-white-300" onClick={handleItemClick}>
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
