export const candidatesDegrees = [
  { key: 'HIGH_SCHOOL', value: 'High School' },
  { key: 'ASSOCIATE_DEGREE', value: 'Associate’s Degree' },
  { key: 'BACHELOR_DEGREE', value: 'Bachelor’s Degree' },
  { key: 'MASTER_DEGREE', value: 'Master’s Degree' },
  { key: 'DOCTORATE', value: 'Doctorate' },
  { key: 'CERTIFICATE', value: 'Certificate' },
  { key: 'DIPLOMA', value: 'Diploma' },
  { key: 'HEALTHCARE_MEDICAL', value: 'Other' },
];
