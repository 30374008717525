import React from 'react';
import { twMerge } from 'tailwind-merge';

const Badge = ({ text = 'text', variant = 'primary', classNames = '', textClassNames = '' }) => {
  const bageColor = {
    primary: 'bg-primary',
    secondary: 'bg-secondary',
    success: 'bg-success',
    black: 'bg-black',
    neutral: 'bg-neutral',
    error: 'bg-error',
    info: 'bg-info',
  }[variant];
  return (
    <div className={twMerge(`w-fit flex py-1 px-4  rounded-md ${bageColor} ${classNames}`)}>
      <p className={twMerge(`"mx-auto text-white"`, textClassNames)}>{text}</p>
    </div>
  );
};

export default Badge;
