import { useDeleteCandidatesFromPlacementMutation } from 'features/placementsSlice';
import { Button, Loader, PercentageBar, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from 'react-toastify';
const CustomTableCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="checkbox"
          className="
              relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[3px] bg-white border border-neutral-200 gradient-border-checkbox outline-none 
              before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] 
              checked:border-primary checked:bg-white checked:before:opacity-[0.16] 
              checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:transform checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-primary checked:after:bg-transparent checked:after:content-[''] 
              hover:cursor-pointer focus:shadow-none focus:before:scale-100 focus:before:opacity-[0.12] 
              focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] 
              checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
              checked:focus:after:absolute checked:focus:after:top-1/2 checked:focus:after:left-1/2 checked:focus:after:transform checked:focus:after:-translate-x-1/2 checked:focus:after:-translate-y-1/2 checked:focus:after:block checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-primary checked:focus:after:bg-transparent 
              dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary"
          style={{ height: '20px', width: '20px' }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
      </div>
    </>
  );
});

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0 ',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400',
      fontSize: '14px',
      padding: '0px',
    },
  },
  cells: {
    style: {
      padding: '0px',
    },
  },
};
const PlacementCandidatesTable = ({
  data,
  handleSelectedRowsChange,
  selectedRows,
  setSelectedRows,
  refetchPlacement,
  placementID,
  isSearchData,
  searchLoading,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);

  const [deleteCandidatesFromPlacement, { isLoading: isDeleteLoading }] = useDeleteCandidatesFromPlacementMutation();
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isDeleteLoading) {
      closeModal();
    }
  }, [isDeleteLoading]);

  // const handleDeleteSelectedCandidates = async () => {
  //   try {
  //     const deleteIDs = candidateToDelete ? [candidateToDelete] : selectedRows.map((row) => row.appID);

  //     await deleteCandidatesFromPlacement({ placementId: placementID, deleteIDs: deleteIDs }).unwrap();
  //     setSelectedRows([]);
  //     refetchPlacement();
  //     toast.success('Candidates deleted successfully');
  //     closeModal();
  //   } catch (err) {
  //     const errorMessage = err?.data?.message || 'An error occurred while deleting candidates.';
  //     toast.error(errorMessage);
  //   }
  // };
  const handleDeleteSelectedCandidates = async () => {
    const deleteIDs = candidateToDelete ? [candidateToDelete] : selectedRows.map((row) => row.appID);
    try {
      await deleteCandidatesFromPlacement({ placementId: placementID, deleteIDs: deleteIDs }).unwrap();
      setSelectedRows([]);
      refetchPlacement();
      toast.success('Candidates deleted successfully');
      closeModal();
    } catch (err) {
      const errorMessage = err?.data?.message || 'An error occurred while deleting candidates.';
      toast.error(errorMessage);
    }
  };

  const columns = [
    {
      name: 'Candidate Name',
      selector: (row) => row.name,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Email Address',
      selector: (row) => row.email,
      sortable: true,
      width: '250px',
    },
    {
      name: 'Phone Number',
      selector: (row) => row.phone,
      sortable: true,
      width: '180px',
    },
    {
      name: 'Location',
      selector: (row) => row.location,
      sortable: true,
      width: '170px',
    },
    {
      name: 'Score',
      selector: (row) => (
        <div>
          <PercentageBar percentage={row.score} />
        </div>
      ),
      sortable: true,
      width: '125px',
    },
    !isSearchData && {
      name: 'Action',
      selector: (row) => (
        <img
          className="cursor-pointer w-4"
          src="/assets/icons/folder_delete.svg"
          alt=""
          onClick={() => {
            setCandidateToDelete(row.appID);
            setIsModalOpen(true);
          }}
        />
      ),
      sortable: true,
      width: '160px',
      center: true,
    },
  ];
  searchLoading && <Loader />;
  return (
    <div className="border border-black-shd1 rounded-md relative">
      <DataTable
        columns={columns}
        data={data}
        onSelectedRowsChange={handleSelectedRowsChange}
        selectableRowsHighlight
        selectableRows
        pagination={data.length > 10}
        customStyles={customStyles}
        selectableRowsComponent={CustomTableCheckbox}
      />
      {!isSearchData && (
        <Button
          classNames={`bg-primary text-white border-none text-sm tracking-wider py-2 px-4 -bottom-10 absolute ${
            selectedRows?.length === 0 || selectedRows === undefined || isSearchData ? 'hidden' : ''
          }`}
          iconUrl="/assets/icons/folder_delete2.svg"
          iconClassNames="filter-white "
          onClick={() => setIsModalOpen(true)}
        >
          Delete
        </Button>
      )}
      <BasicModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="flex flex-col items-center justify-center gap-5 mb-5">
          <img src="/assets/icons/delete-placement-icon.svg" alt="" />
          <p className="text-xl font-semibold text-center text-neutral">Delete Placement</p>
          <p className="text-lg text-center text-neutral mx-5">
            Are you sure you want to delete the selected candidates?
          </p>
        </div>
        <div className="grid grid-cols-2 place-content-center mx-20">
          <div className="flex items-center justify-center px-4 cursor-pointer" onClick={closeModal}>
            <Typography variant="body" className="w-full text-center">
              Cancel
            </Typography>
          </div>
          <div>
            <Button
              type="submit"
              classNames="flex mx-auto bg-primary text-white border-none w-full"
              onClick={handleDeleteSelectedCandidates}
              disabled={isDeleteLoading ? true : false}
              isLoading={isDeleteLoading}
            >
              Delete
            </Button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default PlacementCandidatesTable;
