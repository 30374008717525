import { VerifyOtpForm } from 'modules/auth/components/organisms';
import React from 'react';

const index = () => {
  return (
    <div>
      <VerifyOtpForm />
    </div>
  );
};

export default index;
