import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextInput, TextInputCounter, Typography, Button, Select } from 'modules/shared/components/atoms';
import PhoneInput from 'react-phone-number-input';
import { twMerge } from 'tailwind-merge';
import { ImageUploader } from 'modules/shared/components/molecules';
import { useCreateClientMutation, useGetClientByIdQuery, useUpdateClientMutation } from 'features/clientsSlice';
import { toast } from 'react-toastify';
import { Loader } from 'modules/shared/components/atoms';
import { clientIndustries } from 'modules/clients/data';

const validationSchema = Yup.object({
  client_name: Yup.string().required('Client name is required'),
  client_job_vacancies_count: Yup.number().typeError('Job vacancies count must be a number'),
  client_industry: Yup.string().required('Industry is required'),
  client_location: Yup.object({
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
  }),
  client_point_of_contact_name: Yup.string().required('Point of contact name is required'),
  client_contact_email: Yup.string().email('Invalid email address').required('Email is required'),
  client_contact_phone_number: Yup.string(),
  client_crn: Yup.string().required('CRN is required'),
});

const CreateClientForm = ({ refetch, closeModal, clientId, isEditing }) => {
  const selectRefOne = useRef(null);
  const [logoUrl, setLogoUrl] = useState('');

  const [createClient] = useCreateClientMutation();
  const [updateClient, { isLoading: isUpdatingClient, refetch: refetchUpdatedClient }] = useUpdateClientMutation();
  const shouldFetch = clientId ? clientId : null;
  const {
    data: clientData,
    isLoading: isClientLoading,
    refetch: refetchClient,
  } = useGetClientByIdQuery(clientId, { skip: !shouldFetch });
  const clientDataObject = clientData ? clientData[0] : null;

  useEffect(() => {
    if (clientDataObject) {
      setLogoUrl(clientDataObject.logo_url || '');
    }
  }, [clientDataObject]);

  const initialValues = {
    client_name: clientDataObject ? clientDataObject.client_name : '',
    client_job_vacancies_count: clientDataObject ? clientDataObject.client_job_vacancies_count : '',
    client_industry: clientDataObject ? clientDataObject.client_industry : '',
    client_location: {
      city: clientDataObject ? clientDataObject.client_location.city : '',
      country: clientDataObject ? clientDataObject.client_location.country : '',
    },
    client_point_of_contact_name: clientDataObject ? clientDataObject.client_point_of_contact_name : '',
    client_contact_email: clientDataObject ? clientDataObject.client_contact_email : '',
    client_contact_phone_number: clientDataObject ? clientDataObject.client_contact_phone_number : '',
    logo: clientDataObject?.logo_url || '',
    created_on: clientDataObject?.created_on || '',
    client_crn: clientDataObject ? clientDataObject.client_crn : '',
  };

  const handleFormSubmission = async (values, actions) => {
    const formData = new FormData();
    formData.append('client_name', values.client_name);
    formData.append('client_job_vacancies_count', values.client_job_vacancies_count);
    formData.append('client_industry', values.client_industry);
    formData.append('client_location_city', values.client_location.city);
    formData.append('client_location_country', values.client_location.country);
    formData.append('client_point_of_contact_name', values.client_point_of_contact_name);
    formData.append('client_contact_email', values.client_contact_email);
    formData.append('client_contact_phone_number', values.client_contact_phone_number);
    formData.append('created_on', values.created_on);
    formData.append('client_crn', values.client_crn);
    if (values.logo instanceof File) {
      formData.append('logo', values.logo);
    } else if (isEditing) {
      formData.append('logo', values.logo);
    }

    try {
      if (isEditing) {
        await updateClient({ clientId, data: formData }).unwrap();
        toast.success('Client updated successfully');
        refetchClient();
      } else {
        const response = await createClient(formData).unwrap();
        toast.success(`Client ${response.client_name} created successfully`);
      }
      refetch();
      closeModal();
    } catch (err) {
      toast.error(err.data?.message || 'An error occurred');
    }
  };

  if (isClientLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <div className="w-full p-2 rounded-md">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmission}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
            <ImageUploader
              onImageChange={(file) => {
                setFieldValue('logo', file);
                setLogoUrl(URL.createObjectURL(file));
              }}
              initialImage={logoUrl}
            />
            <div>
              <TextInputCounter
                classNames={`bg-white py-3 border${
                  touched.client_name && errors.client_name ? ' border-error ' : 'border-solid '
                }`}
                label="Client name *"
                name="client_name"
                type="text"
                error={touched.client_name && errors.client_name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.client_name}
              />
              <div classclient_name="counter-error ">
                {touched.client_name && errors.client_name ? (
                  <div className="text-error text-sm block">{errors.client_name}</div>
                ) : null}
              </div>
            </div>
            <div>
              <TextInputCounter
                classNames={`bg-white py-3 border${
                  touched.client_point_of_contact_name && errors.client_point_of_contact_name
                    ? ' border-error '
                    : 'border-solid '
                }`}
                label="Point of contact name *"
                name="client_point_of_contact_name"
                type="text"
                error={touched.client_point_of_contact_name && errors.client_point_of_contact_name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.client_point_of_contact_name}
              />
              <div classclient_point_of_contact_name="counter-error">
                {touched.client_point_of_contact_name && errors.client_point_of_contact_name ? (
                  <div className="text-error text-sm block">{errors.client_point_of_contact_name}</div>
                ) : null}
              </div>
            </div>
            <div>
              <TextInput
                classNames={`bg-white py-3 border${
                  touched.client_job_vacancies_count && errors.client_job_vacancies_count
                    ? ' border-error '
                    : 'border-solid '
                }`}
                label="Number of job vacancies *"
                name="client_job_vacancies_count"
                type="text"
                error={touched.client_job_vacancies_count && errors.client_job_vacancies_count}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.client_job_vacancies_count}
              />
              <div>
                {touched.client_job_vacancies_count && errors.client_job_vacancies_count ? (
                  <div className="text-error text-sm block">{errors.client_job_vacancies_count}</div>
                ) : null}
              </div>
            </div>
            <div>
              <TextInput
                classNames={`bg-white py-3 border${
                  touched.client_crn && errors.client_crn ? ' border-error ' : 'border-solid '
                }`}
                label="Client CRN *"
                name="client_crn"
                type="text"
                error={touched.client_crn && errors.client_crn}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.client_crn}
              />
              <div>
                {touched.client_crn && errors.client_crn ? (
                  <div className="text-error text-sm block">{errors.client_crn}</div>
                ) : null}
              </div>
            </div>
            <div>
              <TextInput
                classNames={`bg-white py-3 border${
                  touched.client_location?.city && errors.client_location?.city ? ' border-error ' : 'border-solid '
                }`}
                name="client_location.city"
                type="text"
                label="City"
                error={touched.client_location?.city && errors.client_location?.city}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.client_location.city}
              />
              {touched.client_location?.city && errors.client_location?.city ? (
                <div className="text-error text-sm block">{errors.client_location.city}</div>
              ) : null}
            </div>
            <div>
              <TextInput
                id="client_location.country"
                name="client_location.country"
                type="text"
                label="Country"
                error={touched.client_location?.country && errors.client_location?.country}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.client_location.country}
              />
              {touched.client_location?.country && errors.client_location?.country ? (
                <div className="text-error text-sm block">{errors.client_location.country}</div>
              ) : null}
            </div>
            <div className="">
              <TextInput
                id="p"
                name="client_contact_email"
                type="email"
                label="Email"
                error={touched.client_contact_email && errors.client_contact_email}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.client_contact_email}
              />
              <Typography className="" variant="errorText">
                {touched.client_contact_email && errors.client_contact_email ? errors.client_contact_email : ''}
              </Typography>
            </div>

            <div className="">
              <div
                className={twMerge(
                  `w-full h-12 border-solid border  focus:outline-none focus:shadow-outline  focus:border-primary appearance-none focus:border-2 leading-5 rounded-sm px-2`,
                  `${
                    touched.client_contact_phone_number && errors.client_contact_phone_number
                      ? 'text-error border-error'
                      : ''
                  }`,
                )}
              >
                <PhoneInput
                  className="h-full"
                  id="phone"
                  name="client_contact_phone_number"
                  type="text"
                  placeholder="Enter phone number"
                  onChange={(e) => {
                    setFieldValue('client_contact_phone_number', e);
                  }}
                  onBlur={handleBlur}
                  value={values.client_contact_phone_number}
                />
              </div>
              <Typography className="" variant="errorText">
                {touched.client_contact_phone_number && errors.client_contact_phone_number
                  ? errors.client_contact_phone_number
                  : ''}
              </Typography>
            </div>
            <div className="">
              <Select
                selectRef={selectRefOne}
                error={errors.client_industry && touched.client_industry}
                setFieldValue={setFieldValue}
                name="client_industry"
                label="Select Client Industry"
                value={values.client_industry}
                onChangeHandler={handleChange}
                options={clientIndustries}
              />
              <Typography variant="errorText">
                {errors.client_industry && touched.client_industry ? errors.client_industry : ''}
              </Typography>
            </div>
            <Button
              type="submit"
              disabled={isSubmitting}
              classNames={'w-full mx-auto md:w-1/2 bg-primary text-white border-0'}
            >
              {isEditing ? 'Update Client' : 'Add Client'}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateClientForm;
