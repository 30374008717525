import React from 'react';
import { twMerge } from 'tailwind-merge';

const Loader = ({ size = 'md' }) => {
  const sizeClasses = {
    sm: 'h-4 w-4 border-b-2',
    md: 'h-8 w-8 border-b-4',
  };

  const spinnerClass = twMerge(`animate-spin rounded-full border-gray-900 ${sizeClasses[size] || sizeClasses.md}`);

  return (
    <div className="flex justify-center items-center">
      <div className={spinnerClass}></div>
    </div>
  );
};

export default Loader;
