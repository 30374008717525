import { apiSlice } from 'app/api/apiSlice';

const clientsApi = apiSlice.injectEndpoints({
  reducerPath: 'clients',
  endpoints(builder) {
    return {
      fetchClients: builder.query({
        query: ({ page, limit = 10 }) => {
          return {
            url: `/clients/list?page_size=${limit}`,
            method: 'GET',
          };
        },
      }),
      createClient: builder.mutation({
        query: (body) => ({
          url: '/clients/client',
          method: 'POST',
          body,
        }),
      }),
      deleteClient: builder.mutation({
        query: (payload) => ({
          url: `/clients/delete`,
          method: 'DELETE',
          body: payload,
        }),
      }),
      getClientById: builder.query({
        query: (clientId) => ({
          url: `/clients/${clientId}`,
          method: 'GET',
        }),
      }),
      updateClient: builder.mutation({
        query: ({ clientId, data }) => ({
          url: `/clients/${clientId}`,
          method: 'PUT',
          body: data,
        }),
      }),
    };
  },
});

export const {
  useFetchClientsQuery,
  useCreateClientMutation,
  useDeleteClientMutation,
  useGetClientByIdQuery,
  useUpdateClientMutation,
} = clientsApi;

export { clientsApi };
