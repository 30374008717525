import { useDeletePlacementMutation } from 'features/placementsSlice';
import { Button, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PlacementCard = ({ JobName, candidatesNumber, placementID, refetchPlacements }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const placements = [];
  placements.push(placementID);

  const [deletePlacement, { isLoading, isError, isSuccess, error }] = useDeletePlacementMutation();

  const handleDeletePlacement = async () => {
    const payload = { placements };
    try {
      await deletePlacement(payload).unwrap();
      refetchPlacements();
      closeModal();
      toast.success('Placement deleted successfully');
    } catch (error) {
      const {
        data: { message },
      } = error;
      toast.error(message);
    }
  };

  const handleConfirmDelete = () => {
    handleDeletePlacement();
  };
  const handleCardClick = () => {
    navigate(`/dashboard/placements/${placementID}`);
  };

  return (
    <div
      className="flex flex-col gap-3 border border-white-300 rounded-lg p-4 cursor-pointer relative"
      onClick={handleCardClick}
    >
      <div className="flex place-content-end absolute w-6 h-6 top-3 right-3" onClick={(e) => e.stopPropagation()}>
        <img
          src="/assets/icons/folder_delete.svg"
          alt="Delete Placement"
          className="w-6 cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        />
      </div>
      <div className="flex flex-col gap-3 my-10">
        <img src="/assets/icons/file-01.svg" alt="" className="w-12" />
        <p className="text-base">{JobName}</p>
        <p>{candidatesNumber} candidates</p>
      </div>
      <BasicModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="flex flex-col items-center justify-center gap-5 mb-5">
          <img src="/assets/icons/delete-placement-icon.svg" alt="" />
          <p className="text-xl font-semibold text-center text-neutral">Delete Placement</p>
          <p className="text-lg text-center text-neutral mx-5">Are you sure you want to delete {JobName}?</p>
        </div>
        <div className="grid grid-cols-2 place-content-center mx-20">
          <div className="flex items-center justify-center px-4 cursor-pointer" onClick={closeModal}>
            <Typography variant="body" className="w-full text-center">
              Cancel
            </Typography>
          </div>
          <div>
            <Button
              type="submit"
              classNames="flex mx-auto bg-primary text-white border-none w-full"
              onClick={handleConfirmDelete}
              disabled={isLoading ? true : false}
              isLoading={isLoading}
            >
              Delete
            </Button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default PlacementCard;
