import { apiSlice } from 'app/api/apiSlice';

const placementsApi = apiSlice.injectEndpoints({
  reducerPath: 'placements',
  endpoints(builder) {
    return {
      fetchPlacements: builder.query({
        query: ({ page_size = 10, page = 1 }) => ({
          url: `/placements/list?page_size=${page_size}&page=${page}`,
          method: 'GET',
        }),
      }),
      createPlacement: builder.mutation({
        query: (body) => ({
          url: '/placements/placement',
          method: 'POST',
          body,
        }),
      }),
      deletePlacement: builder.mutation({
        query: (placementId) => ({
          url: `/placements/delete`,
          method: 'DELETE',
          body: placementId,
        }),
      }),
      getPlacementById: builder.query({
        query: (placementId) => ({
          url: `/placements/${placementId}`,
          method: 'GET',
        }),
      }),
      updatePlacement: builder.mutation({
        query: ({ placementId, data }) => ({
          url: `/placements/${placementId}`,
          method: 'PUT',
          body: data,
        }),
      }),
      matchPlacements: builder.mutation({
        query: (body) => ({
          url: '/placements/match',
          method: 'POST',
          body,
        }),
      }),
      deleteCandidatesFromPlacement: builder.mutation({
        query: ({ placementId, deleteIDs }) => ({
          url: `/placements/applicants?placement_id=${placementId}`,
          method: 'DELETE',
          body: { deleteIDs },
        }),
      }),
    };
  },
});

export const {
  useFetchPlacementsQuery,
  useCreatePlacementMutation,
  useDeletePlacementMutation,
  useGetPlacementByIdQuery,
  useUpdatePlacementMutation,
  useMatchPlacementsMutation,
  useDeleteCandidatesFromPlacementMutation,
} = placementsApi;

export { placementsApi };
