import { Field, Form, Formik } from 'formik';
import { Button, Typography } from 'modules/shared/components/atoms';
import { ImageUploader } from 'modules/shared/components/molecules';
import React from 'react';

const customFieldStyles = {
  backgroundColor: '#f4f4f4',
  borderRadius: '12px',
  padding: '12px 24px',
  width: '100%',
  outline: 'none',
};

const initialValues = {
  fullName: '',
  displayName: '',
  email: '',
  phoneNumber: '',
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const ProfileSettings = () => {
  return (
    <div>
      <article className="w-full p-8 shadow-lg rounded-md mx-10">
        <Typography className="flex flex-col text-center mb-6 tracking-widest text-lg text-neutral-100">
          Your Profile Picture
        </Typography>
        <Formik initialValues={initialValues} onSubmit={(values) => {}}>
          {({ values, handleChange, handleBlur }) => (
            <Form className="flex flex-col gap-4 w-full">
              <ImageUploader
                onImageChange={(file) => {}}
                classNames={'bg-white border-dashed border border-neutral-100 w-32 h-32'}
                iconUrl="/assets/icons/upload_logo.svg"
                iconClassNames="w-8 right-12 top-5"
                text="Upload your logo"
                textClassName="text-neutral-100 text-sm right-6 bottom-8 w-20 tracking-widest"
              />
              <div className="grid gap-6">
                <div className="grid grid-cols-9 items-center">
                  <Typography className="font-semibold tracking-widest text-lg mb-3 col-span-2 col-start-2">
                    Full Name
                  </Typography>
                  <div className="col-span-5 col-start-4 w-full">
                    <Field
                      type="text"
                      id="fullName"
                      name="fullName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                      style={{ ...customFieldStyles }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-9 items-center">
                  <Typography className="font-semibold tracking-widest text-lg mb-3 col-span-2 col-start-2">
                    Display Name
                  </Typography>
                  <div className="col-span-5 col-start-4 w-full">
                    <Field
                      type="text"
                      id="displayName"
                      name="displayName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.displayName}
                      style={{ ...customFieldStyles }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-9 items-center">
                  <Typography className="font-semibold tracking-widest text-lg mb-3 col-span-2 col-start-2">
                    Email
                  </Typography>
                  <div className="col-span-5 col-start-4 w-full">
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      style={{ ...customFieldStyles }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-9 items-center">
                  <Typography className="font-semibold tracking-widest text-lg mb-3 col-span-2 col-start-2">
                    Phone Number
                  </Typography>
                  <div className="col-span-5 col-start-4 w-full">
                    <Field
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      style={{ ...customFieldStyles }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-9 mt-6 items-center">
                  <article className="grid gap-4 w-full p-4 shadow-lg rounded-md col-span-7 col-start-2">
                    <Typography className="font-semibold tracking-widest text-lg">Change Password</Typography>
                    <ol className="mt-2 space-y-1 list-decimal list-inside text-neutral-100">
                      <p>In order to modify your account's password, please follow the steps below:</p>
                      <li>Enter your current and new passwords in the fields below.</li>
                      <li>Click on "Change Password". You will automatically be logged out of your account.</li>
                      <li>Login to your account using your new password.</li>
                    </ol>
                    <div className="grid grid-cols-8 items-center">
                      <Typography className="font-semibold tracking-widest text-base mb-3 col-span-2">
                        Current Password
                      </Typography>
                      <div className="col-span-5 col-start-3 w-full">
                        <Field
                          type="password"
                          id="currentPassword"
                          name="currentPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.currentPassword}
                          style={{ ...customFieldStyles }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-8 items-center">
                      <Typography className="font-semibold tracking-widest text-base mb-3 col-span-2">
                        New Password
                      </Typography>
                      <div className="col-span-5 col-start-3 w-full">
                        <Field
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                          style={{ ...customFieldStyles }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-8 items-center">
                      <Typography className="font-semibold tracking-widest text-base mb-3 col-span-2">
                        Confirm New Password
                      </Typography>
                      <div className="col-span-5 col-start-3 w-full">
                        <Field
                          type="password"
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmNewPassword}
                          style={{ ...customFieldStyles }}
                        />
                      </div>
                    </div>
                    <Button
                      classNames="bg-blue font-semibold mt-4 py-4 tracking-widest text-white border-none w-fit grid place-self-end"
                      type="submit"
                    >
                      Change Password
                    </Button>
                  </article>
                </div>
                <Button
                  classNames="bg-primary font-semibold mt-4 tracking-widest text-white border-none text-xl w-2/4 grid place-self-center"
                  type="submit"
                >
                  Update Profile
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </article>
    </div>
  );
};

export default ProfileSettings;
