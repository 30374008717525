import './style.css';
import React, { useState, useRef, useEffect } from 'react';

const tabs = ['Summary', 'Resume', 'Job', 'Inbox', 'Recommendation', 'Activities', 'Notes', 'History', 'Social'];

const NavigationTabs = ({ activeTab, onTabClick }) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const updateScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    updateScrollButtons();
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', updateScrollButtons);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', updateScrollButtons);
      }
    };
  }, []);

  return (
    <div className="relative mt-10">
      {canScrollLeft && (
        <button
          onClick={scrollLeft}
          className="absolute left-0 top-1/2 transform -translate-y-1/2  bg-primary text-white text-lg p-4 rounded-sm z-10"
        >
          &lt;
        </button>
      )}
      <div ref={scrollContainerRef} className="overflow-x-auto flex gap-5 w-full hide-scrollbar">
        <div className="flex gap-5 w-max">
          {tabs.map((tab) => (
            <div
              onClick={() => onTabClick(tab)}
              key={tab}
              className={`cursor-pointer px-6 py-4 rounded-sm text-lg ${
                activeTab === tab ? 'bg-primary text-white' : 'bg-primary bg-opacity-10'
              }`}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
      {canScrollRight && (
        <button
          onClick={scrollRight}
          className="absolute right-0 top-1/2 transform -translate-y-1/2  bg-primary text-white text-lg p-4 rounded-sm z-10"
        >
          &gt;
        </button>
      )}
    </div>
  );
};

export default NavigationTabs;
