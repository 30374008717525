import { Button, SwitchButton, Typography } from 'modules/shared/components/atoms';
import React from 'react';
import { CandidatesSectionTable } from '../../molecules';

const CandidatesSection = () => {
  return (
    <div className="grid gap-4">
      <Typography variant="mainCardTitle" className="tracking-widest ">
        Candidate
      </Typography>
      <article className="grid gap-4 py-6 shadow-lg rounded-md px-10  ">
        <Typography className="font-semibold text-xl tracking-widest">Candidate profile Enrichment</Typography>
        <div className="flex items-center justify-between">
          <Typography className="text-base tracking-wider">status</Typography>
          <SwitchButton />
        </div>
        <div className="flex items-center justify-between">
          <Typography className="text-base tracking-wider">Automate Enrichment</Typography>
          <SwitchButton />
        </div>
      </article>
      <article className="grid gap-4 py-6 shadow-lg rounded-md px-10  ">
        <Typography className="font-semibold text-lg tracking-widest">Candidate Matches</Typography>
        <div className="flex items-center justify-between">
          <Typography className="text-base tracking-wider">status</Typography>
          <SwitchButton />
        </div>
      </article>
      <article className="grid gap-4 py-6 shadow-lg rounded-md px-10  ">
        <Typography className="font-semibold text-xl tracking-widest">Candidate Scorecards</Typography>
        <div className="flex items-center justify-between">
          <Typography className="text-base tracking-wider">status</Typography>
          <SwitchButton />
        </div>
        <article className="grid gap-8 py-8 shadow-lg rounded-md px-4 items-center text-center">
          <Typography className="font-semibold text-lg tracking-wider">Candidates</Typography>
          <CandidatesSectionTable />
          <Button classNames="w-fit text-lg bg-primary text-white border-0 py-2 px-4 tracking-wider mx-auto">
            Add a candidate
          </Button>
        </article>
      </article>
    </div>
  );
};

export default CandidatesSection;
