import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, Typography } from 'modules/shared/components/atoms';

const validationSchema = Yup.object({
  cardNumber: Yup.string()
    .required('Card number is required')
    .matches(/^\d{16}$/, 'Invalid card number')
    .max(16, 'Card number must be exactly 16 digits'),
  expirationDate: Yup.string()
    .required('Expiration date is required')
    .matches(/^\d{2}\/\d{2}$/, 'Invalid expiration date (MM/YY)')
    .max(5, 'Invalid expiration date'),
  cvv: Yup.string()
    .required('CVV is required')
    .matches(/^\d{3,4}$/, 'Invalid CVV')
    .max(3, 'CVV must be exactly 3 or 4 digits'),
});

const initialValues = {
  cardNumber: '',
  expirationDate: '',
  cvv: '',
  saveCardDetails: false,
};

const PaymentForm = () => {
  const [isCardNumberFocused, setIsCardNumberFocused] = useState(false);
  const [isExpirationDateFocused, setIsExpirationDateFocused] = useState(false);
  const [isCvvFocused, setIsCvvFocused] = useState(false);

  const handleFocus = (field) => {
    switch (field) {
      case 'cardNumber':
        setIsCardNumberFocused(true);
        setIsExpirationDateFocused(false);
        setIsCvvFocused(false);
        break;
      case 'expirationDate':
        setIsCardNumberFocused(false);
        setIsExpirationDateFocused(true);
        setIsCvvFocused(false);
        break;
      case 'cvv':
        setIsCardNumberFocused(false);
        setIsExpirationDateFocused(false);
        setIsCvvFocused(true);
        break;
      default:
        break;
    }
  };
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  const customFieldStyles = {
    border: '2px solid #99999980',
    borderRadius: '12px',
    padding: '8px',
    width: '100%',
    outline: 'none',
  };

  return (
    <article className="flex flex-col gap-4  shadow-lg rounded-md px-14 py-10 ">
      <Typography htmlTag="h3" className="text-xl font-semibold leading-8 border-b border-white-300 pb-4">
        Payment
      </Typography>{' '}
      <article className="flex flex-col gap-4">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form className="flex flex-col   gap-4 w-full">
              <div className="grid grid-cols-8">
                <div className="col-span-4 pr-8">
                  <Typography className="font-semibold tracking-widest text-base mb-3">Card Number</Typography>
                  <Field
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    placeholder="1234  5678  9101  1121"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus('cardNumber')}
                    value={values.cardNumber}
                    maxLength={16}
                    style={{
                      ...customFieldStyles,
                      border: isCardNumberFocused ? '2px solid #077D96' : '2px solid #99999980',
                    }}
                  />
                  <Typography variant="errorText">{touched.cardNumber && errors.cardNumber}</Typography>
                </div>

                <div className="col-span-3 pr-12">
                  <Typography className="font-semibold tracking-widest text-base mb-3">Expiration Date</Typography>
                  <Field
                    type="text"
                    id="expirationDate"
                    name="expirationDate"
                    placeholder="MM/YY"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus('expirationDate')}
                    value={values.expirationDate}
                    maxLength={5}
                    style={{
                      ...customFieldStyles,
                      border: isExpirationDateFocused ? '2px solid #077D96' : '2px solid #99999980',
                    }}
                  />
                  <Typography variant="errorText">{touched.expirationDate && errors.expirationDate}</Typography>
                </div>

                <div className="col-span-1 pr-6">
                  <Typography className="font-semibold tracking-widest text-base mb-3">CVV</Typography>
                  <Field
                    type="text"
                    id="cvv"
                    name="cvv"
                    placeholder="123"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus('cvv')}
                    value={values.cvv}
                    maxLength={3}
                    style={{
                      ...customFieldStyles,
                      border: isCvvFocused ? '2px solid #077D96' : '2px solid #99999980',
                    }}
                  />
                  <Typography variant="errorText">{touched.cvv && errors.cvv}</Typography>
                </div>
              </div>
              <div>
                <Checkbox onChange={handleChange} value={values.saveCardDetails} name="saveCardDetails">
                  {' '}
                  <span className="text-neutral-100">Save Card Details</span>
                </Checkbox>
              </div>

              <div>
                <Button
                  classNames="w-full bg-primary text-white border-none text-lg font-bold"
                  type="submit"
                  disabled={Object.keys(errors).length !== 0}
                >
                  Pay 59.28 USD
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <p className="landing-6 text-neutral-300 -tracking-tight">
          Your personal data will be used to process your order, support your experience throughout this website, and
          for other purposes described in our privacy policy.
        </p>
      </article>
    </article>
  );
};

export default PaymentForm;
