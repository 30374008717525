import { CandidatesSection, EmailSection, ReportSection } from 'modules/adminstration/components/organisms';
import { SearchInput, Typography } from 'modules/shared/components/atoms';
import React from 'react';

const Features = () => {
  return (
    <div className="grid gap-10 mx-12 ">
      <div className="grid grid-cols-2 items-center">
        <Typography className="font-semibold text-2xl tracking-widest">Features</Typography>
        <SearchInput label="Search for your integration" />
      </div>
      <div className="p-3">
        <CandidatesSection />
        <EmailSection />
        <ReportSection />
      </div>
    </div>
  );
};

export default Features;
