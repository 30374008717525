import React from 'react';
import { Typography } from '../../../../shared/components/atoms';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
const AuthLayout = () => {
  const location = useLocation();
  const { pathname } = location;

  const getImageSource = () => {
    const routeName = pathname.split('/').pop();
    const imageSources = {
      login: '/assets/images/login_image.png',
      'forgot-password': '/assets/images/forgot_password.svg',
      'verify-otp': '/assets/images/verify_otp.svg',
    };
    return imageSources[routeName] || '/assets/images/process_description.svg';
  };
  const isLoginOrSignupPage = pathname === '/' || pathname === '/signup' || pathname === 'login';

  return (
    <div className="h-full grid grid-cols-2 items-center place-content-center relative">
      <div>
        <div className="absolute -top-48">
          <img src="/assets/images/primary_shape.png" alt="" className="" />
        </div>
        <div className="h-full flex">
          <div className="w-3/4  z-10 mt-20 ">
            <img className="object-contain" width={585} src={getImageSource()} alt="" />
            <div className="flex flex-col justify-center items-center text-center">
              {isLoginOrSignupPage ? (
                <div className="flex flex-col items-center">
                  <img width={30} height={40} src="/assets/icons/arrow.png" alt="" />
                  <div className="w-[40ch] m-auto">
                    <Typography className="font-poppins text-white font-semibold leading-normal">
                      Save your time & effort while hiring process
                    </Typography>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`z-50  -ml-28 mr-32  mt-32`}>
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
