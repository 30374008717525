import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Typography, UnixTimestampConverter } from 'modules/shared/components/atoms';
import { useFetchJobsQuery, useDeleteJobMutation } from 'features/jobsSlice';
import { toast } from 'react-toastify';
import { BasicModal } from 'modules/shared/components/molecules';
import { JobForm } from '..';
import { useNavigate } from 'react-router-dom';

const convertUnixTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  // const seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} • ${hours}:${minutes}`;
};
const CustomTableCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
      <input
        type="checkbox"
        className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[3px] bg-white border border-neutral-200 gradient-border-checkbox outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-1 checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer focus:shadow-none  focus:before:scale-100 focus:before:opacity-[0.12]   focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent dark:border-neutral-600 dark:checked:border-primary dark:checked:bg-primary"
        style={{ height: '20px', width: '20px' }}
        ref={ref}
        onClick={onClick}
        {...rest}
      />
    </div>
  );
});

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400',
      fontSize: '14px',
      padding: '0px',
    },
    cells: {
      style: {
        padding: '0px !important',
      },
    },
  },
};

const JobsTable = ({ jobs }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [jobId, setJobId] = useState(null);
  const navigate = useNavigate();
  const { data, error, isLoading, refetch } = useFetchJobsQuery({ page: 1, limit: 10 });
  const [deleteJob, { isLoading: deleteJobLoading }] = useDeleteJobMutation();

  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleDeleteSelectedClients = async () => {
    const ids = selectedRows.map((row) => row.jobID);
    const payload = { jobs: ids };
    try {
      await deleteJob(payload).unwrap();
      setToggleCleared(!toggleCleared);
      setSelectedRows([]);
      refetch();
      setIsDeleteModalOpen(false);
      toast.success('Job deleted successfully');
    } catch (error) {
      const {
        data: { message },
      } = error;
      toast.error(message);
    }
  };

  const handleEditSelectedJob = (row) => {
    const { jobID } = row;
    setJobId(jobID);
    openModal(jobID);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const openModal = () => {
    setIsModalOpened(true);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const closeModal = () => {
    setIsModalOpened(false);
    setSelectedRows([]);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    // setSelectedRows([]);
  };
  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((selectedRow) => selectedRow.jobID === row.jobID),
      style: {
        backgroundColor: '#F4F4F4',
      },
    },
  ];

  if (error) {
    return '';
  }

  const columns = [
    {
      name: 'Postion Name',
      selector: (row) => {
        return (
          <div className="cursor-pointer" onClick={() => navigate(`/dashboard/jobs/${row.jobID}`)}>
            <span>{row.name}</span>
          </div>
        );
      },
      sortable: true,
      width: '150px',
      style: { padding: '0px' },
    },
    { name: 'Location', selector: (row) => row.location, sortable: true, width: '120px', style: { padding: '0px' } },
    {
      name: 'Headcount',
      selector: (row) => row.key_responsibilities,
      sortable: true,
      width: '130px',
      style: { padding: '0px' },
    },
    {
      name: 'Experience',
      selector: (row) => row.experience,
      sortable: true,
      width: '120px',
      style: { paddingLeft: '30px' },
    },
    { name: 'Min Salary', selector: (row) => row.min_salary, sortable: true, width: '130px' },
    { name: 'Max Salary', selector: (row) => row.max_salary, sortable: true, width: '130px' },
    { name: 'Position Type', selector: (row) => row.position_type, sortable: true, width: '130px' },
    {
      name: 'Qualifications',
      selector: (row) => row.qualifications,
      sortable: true,
      width: '130px',
      style: { padding: '0px' },
    },
    { name: 'Currency', selector: (row) => row.salary_currency, sortable: true, width: '130px' },
    {
      name: 'Frequency',
      selector: (row) => row.salary_frequence,
      sortable: true,
      width: '130px',
      style: { padding: '0px' },
    },
    { name: 'Skills', selector: (row) => row.skills, sortable: true, width: '130px', style: { padding: '0px' } },
    { name: 'Stage', selector: (row) => row.stage, sortable: true, width: '200px', style: { padding: '0px' } },
    {
      name: 'Created On',
      selector: (row) => <p>{<UnixTimestampConverter timestamp={row.created_on} />}</p>,
      sortable: true,
      width: '135px',
      style: { padding: '0px' },
    },
  ];
  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="relative">
          <DataTable
            columns={columns}
            data={jobs}
            onSelectedRowsChange={handleSelectedRowsChange}
            selectableRowsHighlight
            conditionalRowStyles={conditionalRowStyles}
            selectableRows
            pagination
            customStyles={customStyles}
            selectableRowsComponent={CustomTableCheckbox}
            clearSelectedRows={toggleCleared}
          />
          <Button
            classNames={`bg-primary text-white border-none text-sm tracking-wider py-2 px-4 bottom-2 absolute ${
              selectedRows.length === 0 ? 'hidden' : ''
            }`}
            iconUrl="/assets/icons/folder_delete2.svg"
            iconClassNames="filter-white "
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </Button>
          {selectedRows.length === 1 && (
            <Button
              classNames="bg-primary text-white border-none text-sm tracking-wider py-2 px-4 bottom-2 left-28 absolute"
              onClick={() => handleEditSelectedJob(selectedRows[0])}
            >
              Edit
            </Button>
          )}
        </div>
      )}
      <BasicModal title="Edit Job" isOpen={isModalOpened} onClose={closeModal}>
        {' '}
        <JobForm
          closeModal={closeModal}
          jobId={jobId}
          isEditing={true}
          selectableRows={setSelectedRows}
          refetch={refetch}
        />
      </BasicModal>
      <BasicModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
        <div className="flex flex-col items-center justify-center gap-5 mb-5">
          <img src="/assets/icons/delete-placement-icon.svg" alt="" />
          <p className="text-xl font-semibold text-center text-neutral">Delete Job</p>
          <p className="text-lg text-center text-neutral mx-5">Are you sure you want to delete the selected jobs?</p>
        </div>
        <div className="grid grid-cols-2 place-content-center mx-20">
          <div className="flex items-center justify-center px-4 cursor-pointer" onClick={closeDeleteModal}>
            <Typography variant="body" className="w-full text-center">
              Cancel
            </Typography>
          </div>
          <div>
            <Button
              type="submit"
              classNames="flex mx-auto bg-primary text-white border-none w-full"
              onClick={handleDeleteSelectedClients}
              disabled={deleteJobLoading ? true : false}
              isLoading={deleteJobLoading}
            >
              Delete
            </Button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default JobsTable;
