import { Button, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import { ClientsTable } from 'modules/clients/components/organisms';
import { useFetchClientsQuery } from 'features/clientsSlice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateClientForm from 'modules/clients/components/organisms/CreateNewClientForm';
import { useDispatch, useSelector } from 'react-redux';
import { closeClientModal, openClientModal } from 'features/modalsSlice';
import { TableLoader } from 'modules/shared/components/molecules';
const Clients = () => {
  const dispatch = useDispatch();
  const isClientModalOpen = useSelector((state) => state.modal.isClientModalOpen);
  const {
    data,
    refetch: refetchClients,
    isLoading,
    error,
  } = useFetchClientsQuery({
    page: 1,
    limit: 10,
  });

  const closeModal = () => {
    dispatch(closeClientModal());
  };

  const openModal = () => {
    dispatch(openClientModal());
  };
  if (isLoading) {
    return <TableLoader showButtons={true} />;
  }
  return (
    <div>
      <div className="flex justify-between my-4 border-b border-black-shd1 pb-6">
        <div>
          <Typography className="font-bold">Clients</Typography>
        </div>
        <div className="flex gap-4 items-center">
          <Button
            classNames="bg-primary text-white border-none"
            onClick={openModal}
            iconUrl="/assets/icons/icon_plus.svg"
            iconClassNames="filter-white"
          >
            Create Clients
          </Button>
        </div>
      </div>
      <ClientsTable clientsData={data?.clients} refetch={refetchClients} isLoading={isLoading} isError={error} />
      <BasicModal title="Create Client" isOpen={isClientModalOpen} onClose={closeModal} closeOnOverlayClick={false}>
        <CreateClientForm closeModal={closeModal} refetch={refetchClients} />
      </BasicModal>
    </div>
  );
};

export default Clients;
