import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, Typography } from 'modules/shared/components/atoms';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';
import './EmailSignature.css';

const EmailSignature = () => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Email Signature is required'),
  });

  return (
    <article className="w-full p-10 shadow-sm rounded-md ">
      <Typography className="font-semibold text-xl tracking-widest text-primary mb-14">Email Signature</Typography>
      <Formik initialValues={{ description: '' }} onSubmit={(values) => {}} validationSchema={validationSchema}>
        {({ errors, touched, setFieldValue, values }) => (
          <Form className="email_signature_form">
            <Field name="description">
              {({ field }) => (
                <ReactQuill
                  modules={modules}
                  placeholder="Compose your email signature..."
                  className={twMerge(
                    errors.description && touched.description ? 'border-error ' : 'border-neutral border-spacing-3',
                    'border rounded-md overflow-hidden relative',
                  )}
                  value={values.description}
                  onChange={(val) => {
                    const isEmpty = val.replace(/<\/?[^>]+(>|$)/g, '').trim() === '';
                    setFieldValue('description', isEmpty ? '' : val);
                  }}
                />
              )}
            </Field>
            {errors.description && touched.description && (
              <div className="text-error text-sm mt-2">{errors.description}</div>
            )}
            <div className="flex gap-4 mt-10 justify-end">
              <Button
                type="button"
                onClick={() => setFieldValue('description', '')}
                classNames={
                  'w-20 py-2 px-16 tracking-wider bg-white text-black text-lg font-semibold border-none hover:bg-white hover:text-black'
                }
              >
                Cancel
              </Button>
              <Button
                type="submit"
                classNames={'w-20 py-2 px-16 tracking-wider bg-primary text-lg font-semibold text-white border-none'}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </article>
  );
};

export default EmailSignature;
