import React from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { useFetchFolderByIdQuery as fetchFolderById } from 'features/candidatesFoldersSlice';
import { CompletionPercentage } from 'modules/homepage/components/atoms';
import { NavLink } from 'react-router-dom';
import { UnixTimestampConverter } from 'modules/shared/components/atoms';
import { TableLoader } from 'modules/shared/components/molecules';

const CustomTableCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="checkbox"
          className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[3px] bg-white border border-neutral-200 gradient-border-checkbox outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-1 checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer focus:shadow-none  focus:before:scale-100 focus:before:opacity-[0.12]   focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent dark:border-neutral-600 dark:checked:border-primary dark:checked:bg-primary"
          style={{ height: '20px', width: '20px' }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
      </div>
    </>
  );
});

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0 ',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400',
      fontSize: '14px',
      padding: '0px',
    },
  },
  cells: {
    style: {
      padding: '0px',
    },
  },
};

const FavouriteTable = () => {
  const { folderId } = useParams();
  const { data, error, isLoading } = fetchFolderById(folderId);

  // const columns = [
  //   {
  //     name: 'Name',
  //     selector: (row) => row.client.name,
  //     sortable: true,
  //     width: '170px',
  //     cell: (row) => (
  //       <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
  //         <img
  //           src={row.client.avatar}
  //           alt={`Avatar of ${row.client.name}`}
  //           style={{ width: '30px', height: '30px', borderRadius: '50%' }}
  //         />
  //         {row.client.name}
  //         <div className="flex gap-2">
  //           <CompletionPercentage percentage={row.client.percentage} />
  //         </div>
  //       </div>
  //     ),
  //   },
  //   { name: 'Reference', selector: (row) => row.reference, sortable: true, width: '122px' },
  //   { name: 'Location', selector: (row) => row.location, sortable: true, width: '110px' },
  //   { name: 'Position', selector: (row) => row.position, sortable: true },
  //   { name: 'Company', selector: (row) => row.company, sortable: true },
  //   { name: 'Notice Period', selector: (row) => row.notice_period, sortable: true },
  //   { name: 'Current Salary', selector: (row) => row.current_salary, sortable: true },
  //   { name: 'Expected Salary', selector: (row) => row.expected_salary, sortable: true },
  // ];

  if (isLoading) {
    return <TableLoader numRows={5} />;
  }

  const candidatesData = data ? data.candidates_list : [];

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.first_name,
      sortable: true,
      width: '170px',
      cell: (row) => (
        <div
          className="flex text-center items-center gap-2 pl-0"
          // onClick={() => navigate(`/dashboard/candidates/${row.appID}`)}
        >
          <p className="text-left">
            {' '}
            {row.first_name} {row.last_name}
          </p>
        </div>
      ),
    },
    {
      name: 'Current Position',
      selector: (row) => row.current_position,
      sortable: true,
      width: '130px',
    },
    { name: 'Location', selector: (row) => row.location, sortable: true, width: '110px' },
    { name: 'Current Employer', selector: (row) => row.current_employer, sortable: true, width: '150px' },
    {
      name: 'Experience',
      cell: (row) => (
        <div className="flex flex-wrap text-center items-center gap-2 pl-0">
          {Array.isArray(row.experience) && row.experience.length > 0
            ? row.experience.map((exp, index) => (
                <p key={index} className="text-left">
                  {exp.title}
                </p>
              ))
            : ''}
        </div>
      ),
      sortable: true,
      width: '150px',
    },
    {
      name: 'Notice Period',
      selector: (row) => row.notice_period,
      sortable: true,
      width: '120px',
      cell: (row) => <div className="w-full text-center">{row.notice_period}</div>,
    },
    { name: 'Current Salary', selector: (row) => row.current_salary, sortable: true, width: '120px' },
    { name: 'Expected Salary', selector: (row) => row.expected_salary, sortable: true, width: '150px' },
    { name: 'Resume Summary', selector: (row) => row.resume_summary, sortable: true, width: '150px' },
    { name: 'Email', selector: (row) => row.email, sortable: true, width: '150px' },
    { name: 'Phone', selector: (row) => row.phone, sortable: true, width: '120px' },
    {
      name: 'Created On',
      selector: (row) => <p>{<UnixTimestampConverter timestamp={row.created_on} />}</p>,
      sortable: true,
      width: '135px',
      style: { padding: '0px' },
    },
  ];

  return (
    <div className="">
      <div className="flex text-center items-center gap-3 py-2 my-4 border-b border-black-shd1">
        <NavLink to={'/dashboard/candidates/folders'}>
          <img src="/assets/icons/back_arrow.svg" alt="" />
        </NavLink>
        <p className="font-semibold text-xl tracking-widest">{data.name}</p>
      </div>
      <DataTable
        columns={columns}
        data={candidatesData}
        progressPending={isLoading}
        selectableRowsHighlight
        selectableRows
        pagination
        customStyles={customStyles}
        selectableRowsComponent={CustomTableCheckbox}
      />
    </div>
  );
};
export default FavouriteTable;
