// EmailList.js
import { Button } from 'modules/shared/components/atoms';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';

const CustomTableCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="checkbox"
          className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[3px] bg-white border border-neutral-200 gradient-border-checkbox outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-1 checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer focus:shadow-none  focus:before:scale-100 focus:before:opacity-[0.12]   focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent dark:border-neutral-600 dark:checked:border-primary dark:checked:bg-primary"
          style={{ height: '20px', width: '20px' }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
      </div>
    </>
  );
});

// Inside the EmailList component
const EmailList = ({ emails }) => {
  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };
  const handleDeleteSelectedCandidates = () => {
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    // Handle row click action here (if needed)
    navigate(`/dashboard/inbox/${row.id}`, { state: { email: row } });
  };

  const handleStarIconClick = (row) => {
    row.stared = !row.stared;
    setToggleCleared(!toggleCleared);
  };

  const handleDeleteIconClick = (row) => {
    // Implement the logic to handle the delete icon click for the selected row
  };

  const columns = [
    {
      cell: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={row.stared ? '/assets/icons/favourite_star.svg' : '/assets/icons/star_icon2.svg'}
            alt="star icon"
            style={{ width: '20px', marginRight: '8px' }}
            onClick={() => handleStarIconClick(row)}
          />
          <span onClick={() => handleRowClick(row)} style={{ fontSize: '14px', fontWeight: '700' }}>
            {row.sender}
          </span>
        </div>
      ),
      width: '200px',
    },
    {
      cell: (row) => (
        <div style={{ display: 'flex' }} onClick={() => handleRowClick(row)}>
          <span className="mr-1">{row.title}</span>
          <span className="text-neutral-100 mr-1">-</span>
          <p className="text-neutral-100">{row.describtion}</p>
        </div>
      ),
      width: '700px',
    },
    {
      selector: 'time',
      sortable: true,
      cell: (row) => (
        <div>
          {selectedRows.some((selectedRow) => selectedRow.id === row.id) ? (
            <img
              src="/assets/icons/trash2.svg"
              alt="delete icon"
              className="filter-primary"
              onClick={() => handleDeleteIconClick(row)}
            />
          ) : (
            <div>{row.time}</div>
          )}
        </div>
      ),
    },
  ];

  const customStyles = {
    head: {
      style: {
        height: '50px',
      },
    },
    rows: {
      style: {
        height: '20px',
        border: 'none',
        cursor: 'pointer',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((selectedRow) => selectedRow.id === row.id),
      style: {
        backgroundColor: '#F4F4F4', // Change the background color as per your preference
      },
    },
  ];

  return (
    <div className="relative">
      <DataTable
        columns={columns}
        data={emails}
        onSelectedRowsChange={handleSelectedRowsChange}
        selectableRowsHighlight
        conditionalRowStyles={conditionalRowStyles}
        selectableRows
        customStyles={customStyles}
        selectableRowsComponent={CustomTableCheckbox}
        clearSelectedRows={toggleCleared}
        onRowClicked={handleRowClick}
      />
      <span className="text-lg font-bold absolute top-3 left-14">Select all</span>
      {selectedRows.length > 0 && (
        <Button
          classNames={`bg-primary text-white border-none text-sm tracking-wider py-2 px-4 top-2 right-0 absolute`}
          iconUrl="/assets/icons/folder_delete2.svg"
          iconClassNames="filter-white"
          onClick={handleDeleteSelectedCandidates}
        >
          Delete selected items
        </Button>
      )}
    </div>
  );
};

export default EmailList;
