import { Field, Formik } from 'formik';
import { Avatar } from 'modules/homepage/components/atoms';
import { Button, Typography } from 'modules/shared/components/atoms';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

const customFieldStyles = {
  backgroundColor: '#f4f4f4',
  borderRadius: '12px',
  padding: '12px 24px',
  width: '100%',
  outline: 'none',
};

const validationSchema = Yup.object().shape({
  outgoingEmail: Yup.string().email('Invalid email address').required('Email is required'),
  incomingEmail: Yup.string().email('Invalid email address').required('Email is required'),
});

const EmailSynchronization = () => {
  const handleSubmit = (values, { setSubmitting }) => {
    // Perform asynchronous actions here (e.g., sending data to the server)
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  };

  return (
    <div>
      <article className="w-full p-8 shadow-lg rounded-md">
        <Typography className="font-semibold text-xl tracking-widest text-primary">Email Synchronization</Typography>

        <div className="grid gap-8 px-4 pt-8">
          <div className="grid gap-2">
            <Typography className="text-base right-6 bottom-8 tracking-widest">
              For your email conversations to be available in Manatal, you have to meet the two following requirements:
            </Typography>
            <Link className="text-primary tracking-wider">Learn more about email synchronization</Link>
          </div>

          <div className="flex gap-4 items-center">
            <Avatar classNames="w-6 h-6 font-normal text-sm" avatarUrl={''} alt={'1'} />
            <Typography className="font-semibold text-lg tracking-wider ">
              Manually or automatically Cc or Bcc your outgoing emails to candidates or contacts to the email below
            </Typography>
          </div>

          <Formik
            initialValues={{ outgoingEmail: '', incomingEmail: '' }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit }) => (
              <div className="grid items-center gap-8">
                <div className="flex gap-4 items-center">
                  <Typography className="text-base right-6 bottom-8 w-20 tracking-widest">Email</Typography>
                  <div className="relative w-1/2">
                    <Field
                      type="text"
                      id="outgoingEmail"
                      name="outgoingEmail"
                      placeholder="Company@mail.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.outgoingEmail}
                      style={{
                        ...customFieldStyles,
                        columnSpan: 2,
                        border: errors.outgoingEmail && touched.outgoingEmail ? '1px solid red' : '',
                      }}
                    />
                    {errors.outgoingEmail && touched.outgoingEmail && (
                      <div className="text-error text-sm mt-1">{errors.outgoingEmail}</div>
                    )}
                  </div>
                </div>

                <div className="flex gap-4 items-center">
                  <Avatar classNames="w-6 h-6 font-normal text-sm" avatarUrl={''} alt={'2'} />
                  <Typography className="font-semibold text-lg tracking-wider ">
                    Set up your email client to automatically forward your incoming emails to the email below
                  </Typography>
                  {/*  <Tooltip
                  buttonText={<img src="/assets/icons/circular_tranquility.svg" alt="line" className="my-4 mr-2" />}
                  tooltipContent={'Refer to the documentation of your email client to find out how to automatically forward emails.'}
                />*/}
                </div>

                <div className="flex gap-4 items-center">
                  <Typography className="text-base right-6 bottom-8 w-20 tracking-widest">Email</Typography>
                  <div className="relative w-1/2">
                    <Field
                      type="text"
                      id="incomingEmail"
                      name="incomingEmail"
                      placeholder="Company@mail.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.incomingEmail}
                      style={{
                        ...customFieldStyles,
                        columnSpan: 2,
                        border: errors.incomingEmail && touched.incomingEmail ? '1px solid red' : '',
                      }}
                    />
                    {errors.incomingEmail && touched.incomingEmail && (
                      <div className="text-error text-sm mt-1">{errors.incomingEmail}</div>
                    )}
                  </div>
                </div>

                <div className="grid gap-2">
                  <div className="flex gap-4 items-center">
                    <Avatar classNames="w-6 h-6 font-normal text-sm" avatarUrl={''} alt={'3'} />
                    <Typography className="font-semibold text-lg tracking-wider ">All Done !</Typography>
                    {/* <Tooltip
                      buttonText={<img src="/assets/icons/circular_tranquility.svg" alt="line" className="my-4 mr-2" />}
                      tooltipContent={
                        'Once these two steps are completed, Our system automatically adds emails it receives to candidate or contact profile pages and the Inbox. Forwarded emails that do not contain the email address of a candidate or a contact will be dismissed and won’t be displayed anywhere in the system.  '
                      }/>*/}
                  </div>
                </div>

                <div className="grid w-full justify-end">
                  <Button
                    classNames={'w-20 py-2 px-16 tracking-wider bg-primary text-white border-none'}
                    type="button"
                    onClick={() => handleSubmit()}
                    disabled={!isValid}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </article>
    </div>
  );
};

export default EmailSynchronization;
