import React from 'react';
const Typography = ({
  variant = 'default',
  className = '',
  htmlTag: HtmlTag = 'p',
  children,
  alignText = 'inherit',
}) => {
  const variantClasses = {
    hero: 'text-black text-2xl -tracking-tighter font-semibold',
    default: 'text-black text-2xl',
    subTitle: 'text-base text-white opacity-50',
    inputLabel: 'text-base text-neutral-100 ',
    mainCardTitle: 'text-xl font-semibold text-primary capitalize',
    h4: 'text-base font-bold',
    errorText: 'text-error text-sm',
  }[variant];
  const classes = `text-${alignText} ${variantClasses} ${className}`.trim('');
  return <HtmlTag className={`${classes}`}> {children}</HtmlTag>;
};

export default Typography;
