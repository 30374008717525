import React from 'react';
import { Typography } from 'modules/shared/components/atoms';
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
const PerformanceMetrics = () => {
  return (
    <article className="flex flex-col gap-5 shadow-lg rounded-md p-6">
      <section className="grid grid-cols-1 md:grid-cols-2">
        <article>
          <Typography htmlTag="h3" variant="mainCardTitle">
            Candidate performance
          </Typography>
          <div>
            <BarChart
              slotProps={{
                bar: (props) => {
                  return {
                    ...props,
                    rx: '8',
                  };
                },
              }}
              colors={['#077D96']}
              xAxis={[
                {
                  id: 'barCategories',
                  data: ['Created', 'Owned', 'Added to a job', 'Hired', 'Dropped'],
                  scaleType: 'band',
                },
              ]}
              yAxis={[{ min: 0, max: 50 }]}
              series={[
                {
                  data: [10, 20, 35, 40, 50],
                },
              ]}
              // sx={{
              //   [`& .MuiBarElement-root`]: {
              //     width: '40px !important',
              //   },
              // }}
              width={500}
              height={250}
            />
          </div>
        </article>
        <article className="flex gap-8 flex-col place-self-center ">
          <Typography htmlTag="h3" variant="mainCardTitle">
            Job performance
          </Typography>
          <div>
            <PieChart
              sx={{
                [`& .MuiPieArc-root`]: {
                  strokeWidth: '0',
                },
              }}
              colors={['#077D96', '#4CAF50', '#FF5722', '#FFD704']}
              slotProps={{
                legend: {
                  padding: 32,
                  labelStyle: {
                    fontSize: 12,
                    fill: 'black',
                  },
                  itemMarkHeight: 20,
                  itemMarkWidth: 20,
                  markGap: 15,
                  itemGap: 15,
                },
              }}
              series={[
                {
                  data: [
                    { id: 0, value: 62.5, label: 'active' },
                    { id: 1, value: 12.5, label: 'completed' },
                    { id: 2, value: 12.5, label: 'cancelled' },
                    { id: 3, value: 12.5, label: 'on hold' },
                  ],
                  innerRadius: 0,
                  cx: 100,
                },
              ]}
              width={400}
              height={200}
            />
          </div>
        </article>
      </section>
    </article>
  );
};

export default PerformanceMetrics;
