import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'modules/shared/components/atoms';
import { ActivityItem } from '../../molecules';

const Activities = () => {
  return (
    <article className="flex flex-col gap-5 shadow-lg rounded-md p-6">
      <section className="flex justify-between items-center">
        <div>
          <Typography htmlTag="h3" className="flex gap-2" variant="mainCardTitle">
            My Activities{' '}
            <img
              className="cursor-pointer"
              src="/assets/icons/plus_icon.svg"
              width={20}
              height={20}
              alt="add activity"
            />
          </Typography>
        </div>
        <div>
          <Link to="#" className="text-sm text-primary">
            view all
          </Link>
        </div>
      </section>
      <section className="flex gap-7 flex-col">
        <ActivityItem />
        <ActivityItem />
        <ActivityItem />
        <ActivityItem />
        <ActivityItem />
        <ActivityItem />
      </section>
    </article>
  );
};

export default Activities;
