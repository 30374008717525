import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { candidatesApi } from 'features/candidatesSlice';
import { jobsApi } from 'features/jobsSlice';
import { candidatesFoldersApi } from 'features/candidatesFoldersSlice';
import { clientsApi } from 'features/clientsSlice'; // Import the clientsApi
import { apiSlice } from './api/apiSlice';
import authReducer from '../features/authSlice';
import modalReducer from '../features/modalsSlice';
const persistConfig = {
  key: 'root',
  storage,
};
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    [jobsApi.reducerPath]: jobsApi.reducer,
    [candidatesApi.reducerPath]: candidatesApi.reducer,
    [candidatesFoldersApi.reducerPath]: candidatesFoldersApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: persistedAuthReducer,
    modal: modalReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      jobsApi.middleware,
      candidatesApi.middleware,
      candidatesFoldersApi.middleware,
      clientsApi.middleware,
      apiSlice.middleware,
    );
  },
});
export const persistor = persistStore(store);
setupListeners(store.dispatch);
