import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextInput, Typography } from 'modules/shared/components/atoms';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useVerifyOtpAndEmailMutation } from 'features/authApiSlice';
import OTPInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
  confirmation_code: Yup.string().length(6, 'OTP must be 6 digits').required('OTP is required'),
  password: Yup.string().required('Password is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

const OtpConfirmationForm = () => {
  const location = useLocation();
  const initialValues = {
    confirmation_code: '',
    password: '',
    email: location.state.email,
  };

  const [isFocused, setIsFocused] = useState(false);
  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const navigate = useNavigate();
  const [verifyOtpAndEmail, { isLoading }] = useVerifyOtpAndEmailMutation();

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await verifyOtpAndEmail(values).unwrap();
      toast.success(response.message);
      navigate('/', { state: { email: values.email } });
    } catch (err) {
      const {
        data: { message },
      } = err;
      toast.error(message);
    } finally {
      setSubmitting(false);
    }
  };

  const inputStyle = {
    width: '3rem',
    height: '3rem',
    margin: '0 0.5rem',
    fontSize: '1.5rem',
    borderRadius: '10px',
    border: '1px solid',
    backgroundColor: '#FBFBFB',
    outline: '#077D96',
    textAlign: 'center',
  };

  return (
    <div className="relative shadow-sm flex flex-col rounded-md px-20 py-16 bg-white">
      <article className="px-12 py-20 w-full">
        <div className="flex flex-col gap-8">
          <article>
            <Typography htmlTag="h1" className="text-center" variant="hero">
              OTP Code
            </Typography>
            <Typography variant="inputLabel" alignText="center" htmlTag="p">
              Please enter the code that we sent to your email.{' '}
            </Typography>
          </article>
        </div>
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, handleChange, handleBlur, setFieldValue, errors, touched }) => (
            <Form>
              <div className="flex flex-col gap-4  mt-7 ">
                <div className="mx-auto">
                  <OTPInput
                    value={values.confirmation_code}
                    onChange={(otpValue) => setFieldValue('confirmation_code', otpValue)}
                    numInputs={6}
                    containerStyle="mx-auto flex items-center justify-center"
                    inputClassName="my-custom-input-class"
                    inputStyle={inputStyle}
                    isInputNum={true}
                    onBlur={(e) => {
                      handleInputBlur(e);
                      handleBlur(e);
                    }}
                    renderInput={(inputProps, index) => (
                      <input
                        {...inputProps}
                        key={index}
                        name={`confirmation_code-${index}`}
                        type="text"
                        onFocus={handleInputFocus}
                        onBlur={(e) => {
                          handleInputBlur(e);
                          handleBlur(e);
                        }}
                        maxLength={1}
                        style={{
                          ...inputStyle,
                          borderColor:
                            errors.confirmation_code && touched.confirmation_code
                              ? 'red'
                              : isFocused
                              ? '#077D96'
                              : '#DBDBDB',
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <TextInput
                    label="New Password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password && errors.password}
                    className="w-1/2"
                  />
                </div>
                <div className="mx-auto">
                  <Typography variant="errorText">
                    {touched.password && errors.password ? errors.password : ''}
                  </Typography>
                </div>
                <Button
                  disabled={isLoading}
                  type="submit"
                  classNames="bg-primary text-white border-none font-semibold tracking-wide"
                >
                  Continue
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </article>
    </div>
  );
};

export default OtpConfirmationForm;
