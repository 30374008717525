import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Button, SelectTwo, TextInput, Typography } from 'modules/shared/components/atoms';
import { toast } from 'react-toastify';
import { useFetchJobsQuery } from 'features/jobsSlice';
import { useEffect, useRef, useState } from 'react';
import { useMatchPlacementsMutation } from 'features/placementsSlice';
import { Loader } from 'modules/shared/components/atoms';
const validationSchema = Yup.object({
  jobID: Yup.string().required('Job selection is required'),
  count: Yup.number()
    .typeError('Applicant count must be a number')
    .required('Applicant count is required')
    .min(1, 'At least 1 applicant is required'),
});

const SearchForCandidates = ({
  closeModal,
  setSearchResults,
  onJobChange = () => {},
  passSearchLoaging = () => {},
  isUpdating,
  currentJob,
  currentJobId,
}) => {
  const jobRef = useRef(null);
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedJobId, setSelectedJobId] = useState('');
  const [searchLoaging, setSearchLoaging] = useState(false);

  const { data: jobData, isLoading: isJobLoading, refetch: refetchJob } = useFetchJobsQuery({ page: 1, limit: 20 });
  const [matchPlacements, { isLoading, isError, isSuccess, error }] = useMatchPlacementsMutation();

  const onSubmit = async (values) => {
    try {
      const response = await matchPlacements(values).unwrap();
      setSearchResults(response);
      closeModal();
      toast.success('Searched successfully');
    } catch (error) {
      let errorMessage = error?.data?.message || 'An error occurred during the search.';
      toast.error(errorMessage);
    }
  };
  useEffect(() => {
    if (selectedJob) {
      onJobChange(selectedJob, selectedJobId);
    }
    if (isLoading) {
      setSearchLoaging(true);
      passSearchLoaging(searchLoaging);
    }
  }, [selectedJob, onJobChange, isLoading, setSearchLoaging, passSearchLoaging]);
  const handleJobSelection = (name, option) => {
    setSelectedJob(option.value);
    setSelectedJobId(option.label);
  };

  const initialValues = {
    jobID: currentJobId ? currentJobId : '',
    count: '',
  };

  if (isJobLoading) {
    return <Loader />;
  } else {
    const jobList = jobData ? jobData.jobs : [];

    return (
      <div className="w-full p-2 rounded-md">
        <Formik
          onSubmit={onSubmit}
          validateOnChange
          validateOnBlur
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, errors, setFieldValue, handleChange, handleBlur, touched, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
              {isUpdating ? (
                <div className="p-3 border border-x-neutral-200 rounded-sm my-10 mt-">
                  <Typography variant="inputLabel">{currentJob}</Typography>
                </div>
              ) : (
                <div>
                  <SelectTwo
                    selectRef={jobRef}
                    name="jobID"
                    label="Select job"
                    error={touched.jobID && errors.jobID}
                    value={jobList.find((job) => job.jobID === values.job) || null}
                    setFieldValue={(name, option) => {
                      setFieldValue('jobID', option.label);
                      handleJobSelection(name, option);
                    }}
                    options={jobList.map((job) => ({
                      value: job.name,
                      label: job.jobID,
                    }))}
                  />
                  {touched.jobID && errors.jobID ? (
                    <Typography variant="errorText" className="text-error">
                      {errors.jobID}
                    </Typography>
                  ) : null}
                </div>
              )}
              <Field name="count">
                {({ field }) => (
                  <div>
                    <TextInput
                      classNames={`bg-white py-3 border ${touched.count && errors.count ? 'border-error' : ''}`}
                      label="The number of applicants you wish to display"
                      name="count"
                      type="number"
                      error={touched.count && errors.count}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.count}
                    />
                    {touched.count && errors.count ? (
                      <Typography variant="errorText" className="text-error">
                        {errors.count}
                      </Typography>
                    ) : null}
                  </div>
                )}
              </Field>
              <div className="flex place-content-end">
                <div className="flex items-center px-4 cursor-pointer" onClick={closeModal}>
                  <Typography variant="body">Cancel</Typography>
                </div>
                <div>
                  <Button
                    type="submit"
                    classNames="flex mx-auto bg-primary text-white border-none"
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};

export default SearchForCandidates;
