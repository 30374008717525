import { EmailList } from '../../molecules';

const InboxHome = () => {
  // Define the route-specific component for /dashboard/inbox
  const emails = [
    {
      id: 1,
      stared: true,
      sender: 'Leslie Alexander',
      title: 'Hiya',
      describtion: 'Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
      time: '10:41 PM',
    },
    {
      id: 2,
      stared: false,
      sender: 'Theresa Webb',
      title: 'Build prototypes without code',
      describtion: 'Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim.',
      time: '12:01 PM',
    },
    {
      id: 3,
      stared: true,
      sender: 'Annette Black',
      title: 'Your account with us',
      describtion:
        'Non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
      time: '12:01 AM',
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center"></div>
      <div className=" border-neutral-200">
        <EmailList emails={emails} />
      </div>
    </div>
  );
};

export default InboxHome;
