import React from 'react';
import {
  CertificateCard,
  EducationCard,
  RecentHistoryCard,
  SkillsCard,
  SourceInfoCard,
  WorkExperienceCard,
} from '../../molecules';

const Summary = ({ candidateData }) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
        <div className="col-span-2">
          <WorkExperienceCard experienceDAta={candidateData.experience} />
          <EducationCard educationDAta={candidateData.education} />
          <CertificateCard certificateData={candidateData.certs} />
          <RecentHistoryCard />
        </div>
        <div className="">
          <SkillsCard skillsData={candidateData.skills} />
          <SourceInfoCard />
        </div>
      </div>
    </div>
  );
};

export default Summary;
