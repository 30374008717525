import React, { useEffect, useState } from 'react';
import { NoteCard } from '../../molecules';
import { BasicModal } from 'modules/shared/components/molecules';

const notesData = [
  {
    id: 1,
    title: 'Test1',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content: 'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
  {
    id: 2,
    title: 'Test2',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content: 'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
  {
    id: 3,
    title: 'Note1',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content: 'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
  {
    id: 4,
    title: 'Note1',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content: 'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
  {
    id: 5,
    title: 'Note1',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content: 'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
  {
    id: 6,
    title: 'Note1',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content: 'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
  {
    id: 7,
    title: 'Note1',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content: 'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
  {
    id: 8,
    title: 'Note1',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content:
      'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
  {
    id: 9,
    title: 'Note1',
    author: 'Jasmine Doe',
    date: '28 Mar, 2024',
    content: 'Lorem ipsum dolor sit amet consectetur. Viverra ut varius diam aliquet vel in nisl ullamcorper vitae.',
    noteAvatar: '/assets/icons/avatar.png',
  },
];

function Notes() {
  const [notes, setNotes] = useState(notesData);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [editContent, setEditContent] = useState('');
  const openPopup = (id) => {
    setCurrentPopup(id === currentPopup ? null : id);
  };

  const openNoteModal = (note) => {
    setSelectedNote(note);
    setIsModalOpened(true);
  };

  const openModal = (content) => {
    setModalContent(content);
    if (content.type === 'edit') {
      setEditContent(content.content);
    }
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
    setModalContent(null);
    setSelectedNote(null);
    setEditContent('');
  };

  const confirmDelete = (noteId) => {
    setNotes(notes.filter((note) => note.id !== noteId));
    closeModal();
  };

  const handleSave = (noteId) => {
    setNotes(notes.map((note) => (note.id === noteId ? { ...note, content: editContent } : note)));
    closeModal();
  };

  useEffect(() => {
    const handleClickOutside = () => {
      if (currentPopup !== null) {
        setCurrentPopup(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [currentPopup]);

  return (
    <div>
      <h1 className="text-3xl font-bold my-8">Notes</h1>
      <div className="flex flex-wrap justify-between">
        {notes.map((note) => (
          <NoteCard
            key={note.id}
            note={note}
            openPopup={openPopup}
            currentPopup={currentPopup}
            onClick={() => openNoteModal(note)}
            onEdit={openModal}
            onDelete={openModal}
          />
        ))}
      </div>
      {isModalOpened && selectedNote && (
        <BasicModal title={selectedNote.title} isOpen={isModalOpened} onClose={closeModal} closeOnOverlayClick={false}>
          <div>
            <div className="flex gap-1 text-neutral-300 mb-8">
              <img src={selectedNote.noteAvatar} alt="" className="w-5 h-5 rounded-full" />
              <p>{selectedNote.author}</p>
              <p>Date: {selectedNote.date}</p>
            </div>
            <p className="text-neutral-100">{selectedNote.content}</p>
          </div>
        </BasicModal>
      )}
      {isModalOpened && modalContent && modalContent.type === 'edit' && (
        <BasicModal
          isOpen={isModalOpened}
          onClose={closeModal}
          onConfirm={() => handleSave(modalContent.noteId)}
          closeOnOverlayClick={false}
        >
          <div className="p-4 bg-white-400">
            <textarea
              className="w-full h-40 p-2 resize-none outline-none bg-white-400"
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
            ></textarea>
            <div className="flex justify-end mt-4 ">
              <button
                onClick={closeModal}
                className="mr-4 px-4 py-2 text-neutral-100 border border-neutral-300 rounded-sm uppercase"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSave(modalContent.noteId)}
                className="px-4 py-2 bg-primary text-white rounded-sm uppercase"
              >
                Save
              </button>
            </div>
          </div>
        </BasicModal>
      )}
      {isModalOpened && modalContent && modalContent.type === 'delete' && (
        <BasicModal
          isOpen={isModalOpened}
          onClose={closeModal}
          onConfirm={() => confirmDelete(modalContent.noteId)}
          closeOnOverlayClick={false}
        >
          <div className="p-4 bg-white-400 text-center">
            <div className="flex justify-center mb-4">
              <img src="/assets/icons/DeleteOutlineRed.png" alt="Delete Icon" className="w-24 h-24 filter-errorDD" />
            </div>
            <p>{modalContent.content}</p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={closeModal}
                className="mr-4 px-4 py-2 text-neutral-100 border border-neutral-300 rounded-sm uppercase"
              >
                CANCEL
              </button>
              <button
                onClick={() => confirmDelete(modalContent.noteId)}
                className="px-6 py-2 bg-error bg-opacity-80 text-white rounded-sm"
              >
                DELETE
              </button>
            </div>
          </div>
        </BasicModal>
      )}
    </div>
  );
}

export default Notes;
