import { Avatar } from 'modules/homepage/components/atoms';
import React from 'react';
import DataTable from 'react-data-table-component';

const members = [
  {
    id: 1,
    name: 'Jasmine Doe',
    role: 'Internal Optimization Manager',
    email: 'Gretchen.Feeney@gmail.com',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 2,
    name: 'Jasmine Doe',
    role: 'Senior Product Designer',
    email: 'Gretchen.Feeney@gmail.com',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 3,
    name: 'Jasmine Doe',
    role: 'Internal Optimization Manager',
    email: 'Gretchen.Feeney@gmail.com',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 4,
    name: 'Jasmine Doe',
    role: 'Internal Optimization Manager',
    email: 'Gretchen.Feeney@gmail.com',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 5,
    name: 'Jasmine Doe',
    role: 'Senior Product Designer',
    email: 'Gretchen.Feeney@gmail.com',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 6,
    name: 'Jasmine Doe',
    role: 'Senior Product Designer',
    email: 'Gretchen.Feeney@gmail.com',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 7,
    name: 'Mohamed Doe',
    role: 'Senior Product Designer',
    email: 'Gretchen.Feeney@gmail.com',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 8,
    name: 'Ahmed Doe',
    role: 'Internal Optimization Manager',
    email: 'Gretchen.Feeney@gmail.com',
    avatar: '/assets/icons/avatar.png',
  },
];

const columns = [
  {
    name: 'Members Names',
    selector: (row) => row.name,
    cell: (row) => (
      <div className="flex items-center gap-2">
        <img src={row.avatar} alt="" className="h-10 w-10 rounded-full" />
        {row.name}
      </div>
    ),
    sortable: true,
  },
  {
    name: 'Role',
    selector: (row) => row.role,
    sortable: true,
  },
  {
    name: 'Email Address',
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: 'Actions',
    cell: (row) => (
      <div className="flex space-x-4">
        <button>
          <img src="/assets/icons/user-plus.png" alt="" />
        </button>
        <button className="text-red-500 hover:text-red-700">
          <img src="/assets/icons/DeleteOutline.png" alt="" />
        </button>
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
];

const customStyles = {
  rows: {
    style: {
      fontSize: '14px',
      minHeight: '56px',
      color: '#6B6B6B',
      letterSpacing: '.5px',
      '&:hover': {
        backgroundColor: '#DAECEF',
      },
    },
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 500,
      borderBottomColor: '#EDEDED',
      borderBottomWidth: '1px',
    },
  },

  tableWrapper: {
    style: {
      border: '2px solid rgb(229 231 235)',
      borderRadius: '1rem',
      overflow: 'hidden',
    },
  },
};

const JobTeamTable = () => <DataTable columns={columns} data={members} customStyles={customStyles} />;

export default JobTeamTable;
