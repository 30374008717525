import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'modules/shared/components/atoms';
import { JobTrackingItem } from '../../molecules';
const ApplicationTracking = () => {
  return (
    <article className="flex flex-col gap-5 shadow-lg rounded-md p-6">
      <section className="flex justify-between items-center">
        <div>
          <Typography htmlTag="h3" className="flex gap-2" variant="mainCardTitle">
            Applications Tracking
          </Typography>
        </div>
        <div>
          <Link to="#" className="text-sm text-primary">
            view all
          </Link>
        </div>
      </section>
      <section className="flex gap-4 flex-col">
        <div className="p-2 border-b border-b-black-shd1">
          <JobTrackingItem
            avatarUrl="/assets/icons/avatar.png"
            position="Andro Michel"
            category="CO-chair@ Bill"
            date="2023-09-29"
            datePostedAge="6 days ago"
            alt="Waleed"
            percentage={20}
          />
        </div>
        <div className="p-2 border-b border-b-black-shd1">
          <JobTrackingItem
            position="Andro Michel"
            category="CO-chair@ Bill"
            date="2023-09-29"
            datePostedAge="6 days ago"
            alt="Waleed"
            percentage={50}
          />
        </div>
        <div className="p-2 border-b border-b-black-shd1">
          <JobTrackingItem
            avatarUrl="/assets/icons/avatar.png"
            position="Andro Michel"
            category="CO-chair@ Bill"
            date="2023-09-29"
            datePostedAge="6 days ago"
            alt="Waleed"
            percentage={80}
          />
        </div>
        <div className="p-2 border-b border-b-black-shd1">
          <JobTrackingItem
            position="Andro Michel"
            category="CO-chair@ Bill"
            date="2023-09-29"
            datePostedAge="6 days ago"
            alt="Waleed"
            percentage={90}
          />
        </div>
        <div className="p-2 ">
          <JobTrackingItem
            avatarUrl="/assets/icons/avatar.png"
            position="Andro Michel"
            category="CO-chair@ Bill"
            date="2023-09-29"
            datePostedAge="6 days ago"
            alt="Waleed"
            percentage={80}
          />
        </div>
      </section>
    </article>
  );
};

export default ApplicationTracking;
