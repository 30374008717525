import React from 'react';
import { Typography } from '../../atoms';
const BasicCard = ({ image, title, onClick, name }) => {
  return (
    <div
      onClick={() => {
        onClick(name);
      }}
      className="group mx-auto flex flex-col gap-6 justify-center p-4  rounded-md border-[.2px] cursor-pointer hover:bg-primary hover:text-white transition-colors"
    >
      <img width={70} src={image} alt="Card Image" className="mx-auto group-hover:filter-white transition-colors" />
      <Typography variant="h4" alignText="center" htmlTag="p">
        {title}
      </Typography>
    </div>
  );
};

export default BasicCard;
