// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-slider {
  -webkit-appearance: none;
  height: 7px;
  width: 256px;
  background: rgba(122, 180, 253, 0.3);
  border-radius: 5px;
  background-image: linear-gradient(#077d96, #077d96);
  background-repeat: no-repeat;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #077d96;
  cursor: pointer;
}

/* Additional styles for Mozilla Firefox */
.range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #077d96;
  border: 0px solid #fff;
  border-radius: 50%;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/modules/shared/components/atoms/RangeSlider/style.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,kBAAkB;EAClB,mDAAmD;EACnD,4BAA4B;AAC9B;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB;;AAEA,0CAA0C;AAC1C;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".range-slider {\n  -webkit-appearance: none;\n  height: 7px;\n  width: 256px;\n  background: rgba(122, 180, 253, 0.3);\n  border-radius: 5px;\n  background-image: linear-gradient(#077d96, #077d96);\n  background-repeat: no-repeat;\n}\n\n.range-slider::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  height: 20px;\n  width: 20px;\n  border-radius: 50%;\n  background: #077d96;\n  cursor: pointer;\n}\n\n/* Additional styles for Mozilla Firefox */\n.range-slider::-moz-range-thumb {\n  width: 20px;\n  height: 20px;\n  background-color: #077d96;\n  border: 0px solid #fff;\n  border-radius: 50%;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
