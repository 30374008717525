import { DropReasons, Industries, JobTemplates, JobsTags } from 'modules/adminstration/components/organisms';
import { SwitchButton, Typography } from 'modules/shared/components/atoms';
import React from 'react';
import { Field, Formik } from 'formik';

const CustomizationJobs = () => {
  const initialValues = {
    jobsWithoutCandidates: true,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <article className="shadow-lg mx-10 p-4">
            <Typography className="font-semibold text-xl tracking-widest">Jobs</Typography>
            <div className="p-3 mr-20 grid gap-10">
              <div className="grid gap-4">
                <Typography variant="mainCardTitle" className="tracking-wider">
                  Jobs Without Candidates
                </Typography>
                <div className="flex justify-between">
                  <Typography className="text-base tracking-wider ">Status</Typography>
                  <Field
                    name="jobsWithoutCandidates"
                    render={({ field }) => (
                      <SwitchButton
                        name={field.name}
                        checkboxValue={formik.values[field.name]}
                        onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                      />
                    )}
                  />
                </div>
              </div>
              <JobTemplates />
              <DropReasons />
              <JobsTags />
              <Industries />
            </div>
          </article>
        </form>
      )}
    </Formik>
  );
};

export default CustomizationJobs;
