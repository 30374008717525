import React from 'react';
import { Notification } from '../../molecules';
import { Typography } from 'modules/shared/components/atoms';
import { Link } from 'react-router-dom';
const Notifications = () => {
  return (
    <article className="flex flex-col gap-5 shadow-lg rounded-md p-6">
      <section className="flex justify-between items-center">
        <div>
          <Typography htmlTag="h3" className="flex gap-2" variant="mainCardTitle">
            Notifications
          </Typography>
        </div>
        <div>
          <Link to="#" className="text-sm text-primary">
            view all
          </Link>
        </div>
      </section>
      <section className="flex gap-4 flex-col">
        <Notification
          avatarUrl="/assets/icons/avatar.png"
          name="Ahmed"
          content="updated profile"
          notificationTime="6 days a go"
        />
        <Notification name="mohamed" content="Mohamed updated profile" notificationTime="12 days a go" />
        <Notification
          avatarUrl="/assets/icons/avatar.png"
          name="Ahmed"
          content="updated profile"
          notificationTime="6 days a go"
        />
        <Notification name="mohamed" content="Mohamed updated profile" notificationTime="12 days a go" />
        <Notification
          avatarUrl="/assets/icons/avatar.png"
          name="Ahmed"
          content="updated profile"
          notificationTime="6 days a go"
        />
      </section>
    </article>
  );
};

export default Notifications;
