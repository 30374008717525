import { Field, Formik } from 'formik';
import { SwitchButton, Typography } from 'modules/shared/components/atoms';
import React, { useEffect } from 'react';
import { notificationsEditData } from './data';

const NotificationSwitch = ({ name, form }) => {
  const handleCheckboxChange = (newValue) => {
    form.setFieldValue(name, newValue);
  };

  useEffect(() => {
    // Perform any action when the checkbox value changes
  }, [form.values[name]]);

  return (
    <div className="col-span-1">
      <SwitchButton name={name} checkboxValue={form.values[name]} onCheckboxChange={handleCheckboxChange} />
    </div>
  );
};

const Notifications = () => {
  const initialValues = {};
  notificationsEditData.forEach((item) => {
    initialValues[item.name] = false;
  });

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <article className="shadow-sm mr-48 ml-5 px-20 pt-10 pb-1 rounded-md">
            <Typography className="font-semibold text-xl tracking-widest">
              Edit your notifications that you received in Email
            </Typography>
            <div className="my-10">
              {notificationsEditData.map((item) => (
                <div key={item.id} className="grid mb-10">
                  <Typography className="font-semibold text-primary text-lg tracking-widest">{item.name}</Typography>
                  <div className="grid grid-cols-3">
                    <div className="col-span-2">
                      <Typography className="text-base tracking-widest pr-10 ">{item.description}</Typography>
                    </div>
                    <div className=" place-self-end">
                      <Field
                        name={item.name}
                        render={({ field }) => (
                          <SwitchButton
                            name={field.name}
                            checkboxValue={formik.values[field.name]}
                            onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </article>
        </form>
      )}
    </Formik>
  );
};

export default Notifications;
