// import React from 'react';
// import {
//   Activities,
//   JobOverviewList,
//   ApplicationTracking,
//   Notifications,
//   PerformanceMetrics,
//   EmptyPerformanceMetrics,
// } from 'modules/homepage/components/organisms';
// import { EmptyStatsCardPlaceholder } from 'modules/homepage/components/molecules';
// import { openJobModal, openClientModal, openCandidateModal } from 'features/modalsSlice';
// import { useDispatch } from 'react-redux';
// import { useFetchJobsQuery } from 'features/jobsSlice';
// import { Loader } from 'modules/shared/components/atoms';

// const activities = [];
// const notifications = [];
// const jobOverviewList = [1.2];
// const applicationTrackingItems = [];
// const performanceMetrics = [];

// const MainDashboard = () => {
//   const dispatch = useDispatch();
//   const {
//     data: jobsData,
//     error,
//     isLoading: isLoadingJobs,
//     refetch: refetchJobs,
//   } = useFetchJobsQuery({
//     page: 1,
//     limit: 10,
//   });
//   const jobOverviewList = jobsData?.jobs.slice(0, 5) || [];
//   return (
//     <div className="h-60rem grid gap-8">
//       <div className="grid grid-flow-row grid-cols-2 gap-12">
//         {activities.length > 0 ? (
//           <Activities />
//         ) : (
//           <div>
//             <EmptyStatsCardPlaceholder
//               title="My Activities"
//               describtion="You don't have anything planned yet."
//               // actionText="create activities"
//               actionUrl="#"
//             />
//           </div>
//         )}
//         {notifications.length > 0 ? (
//           <Notifications />
//         ) : (
//           <EmptyStatsCardPlaceholder
//             title="Notifications"
//             describtion="You don't have any notifications."
//             overview="Your notifications will appear here "
//           />
//         )}
//         {jobOverviewList.length > 0 ? (
//           <div>
//             {isLoadingJobs ? (
//               <Loader />
//             ) : (
//               <JobOverviewList jobs={jobOverviewList} refetchJobs={refetchJobs} isLoading={isLoadingJobs} />
//             )}
//           </div>
//         ) : (
//           <div>
//             <EmptyStatsCardPlaceholder
//               title="Job Overview"
//               describtion="You don't have anything jobs yet."
//               actionText="Create job"
//               actionUrl="/dashboard/jobs"
//               onClick={() => {
//                 dispatch(openJobModal());
//               }}
//             />
//           </div>
//         )}
//         {applicationTrackingItems.length > 0 ? (
//           <ApplicationTracking />
//         ) : (
//           <div>
//             <EmptyStatsCardPlaceholder
//               title="Applications Tracking"
//               describtion="No applicants are currently being tracked in your system."
//               // actionText="Create candidate"
//               actionUrl="#"
//             />
//           </div>
//         )}
//       </div>
//       <div>{performanceMetrics.length > 0 ? <PerformanceMetrics /> : <EmptyPerformanceMetrics />}</div>
//     </div>
//   );
// };

// export default MainDashboard;
import React from 'react';
import {
  Activities,
  JobOverviewList,
  ApplicationTracking,
  Notifications,
  PerformanceMetrics,
  EmptyPerformanceMetrics,
} from 'modules/homepage/components/organisms';
import { EmptyStatsCardPlaceholder } from 'modules/homepage/components/molecules';
import { openJobModal, openClientModal, openCandidateModal } from 'features/modalsSlice';
import { useDispatch } from 'react-redux';
import { useFetchJobsQuery } from 'features/jobsSlice';
import { Loader } from 'modules/shared/components/atoms';

const activities = [];
const notifications = [];
const jobOverviewList = [1.2];
const applicationTrackingItems = [];
const performanceMetrics = [];

const MainDashboard = () => {
  const dispatch = useDispatch();
  const {
    data: jobsData,
    error,
    isLoading: isLoadingJobs,
    refetch: refetchJobs,
  } = useFetchJobsQuery({
    page: 1,
    limit: 10,
  });

  const jobOverviewList = jobsData?.jobs.slice(0, 5) || [];

  return (
    <div className="h-60rem grid gap-8">
      <div className="grid grid-flow-row grid-cols-2 gap-12">
        {/* Activities */}
        {activities.length > 0 ? (
          <Activities />
        ) : (
          <EmptyStatsCardPlaceholder
            title="My Activities"
            describtion="You don't have anything planned yet."
            actionUrl="#"
          />
        )}

        {/* Notifications */}
        {notifications.length > 0 ? (
          <Notifications />
        ) : (
          <EmptyStatsCardPlaceholder
            title="Notifications"
            describtion="You don't have any notifications."
            overview="Your notifications will appear here."
          />
        )}

        {/* Job Overview */}
        <div>
          {isLoadingJobs ? (
            <Loader />
          ) : jobOverviewList.length > 0 ? (
            <JobOverviewList jobs={jobOverviewList} refetchJobs={refetchJobs} isLoading={isLoadingJobs} />
          ) : (
            <EmptyStatsCardPlaceholder
              title="Job Overview"
              describtion="You don't have any jobs yet."
              actionText="Create job"
              actionUrl="/dashboard/jobs"
              onClick={() => {
                dispatch(openJobModal());
              }}
            />
          )}
        </div>

        {/* Application Tracking */}
        {applicationTrackingItems.length > 0 ? (
          <ApplicationTracking />
        ) : (
          <EmptyStatsCardPlaceholder
            title="Applications Tracking"
            describtion="No applicants are currently being tracked in your system."
            actionUrl="#"
          />
        )}
      </div>

      {/* Performance Metrics */}
      <div>{performanceMetrics.length > 0 ? <PerformanceMetrics /> : <EmptyPerformanceMetrics />}</div>
    </div>
  );
};

export default MainDashboard;
