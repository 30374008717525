import React, { useState } from 'react';
import { CreateFirstPlacement } from '../components/molecules';
import { useFetchPlacementsQuery } from 'features/placementsSlice';
import {
  CreatePlacementFolder,
  PlacementCandidates,
  PlacementList,
  SearchForCandidates,
} from '../components/organisms';
import { Button, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import { TableLoader } from 'modules/shared/components/molecules';
import { ToastContainer } from 'react-toastify';
const Placement = () => {
  const [isModalOpended, setIsModalOpended] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [placementFolderData, setPlacementFolderData] = useState([]);
  const [step, setStep] = useState('CREATE_FOLDER');

  const {
    data: placements,
    error,
    isLoading,
    refetch: refetchPlacements,
  } = useFetchPlacementsQuery({
    page_size: 10,
    page: 1,
  });

  const placementdata = placements?.placements;

  const openModal = () => {
    setIsModalOpended(true);
  };

  const closeModal = () => {
    setIsModalOpended(false);
    setStep('CREATE_FOLDER');
  };

  const handleSearchForCandidatesResults = (results) => {
    setSearchResults(results);
  };
  const handleJobChange = (job, jobId) => {
    setSelectedJob(job);
    setSelectedJobId(jobId);
  };
  const handleSearchLoading = (isLoading) => {
    setSearchLoading(isLoading);
  };
  const handlePlacementSuccess = () => {
    setSearchResults(null);
  };

  const SearchForCandidatesForm = (values) => {
    setStep('SEARCH_CANDIDATES');
    setPlacementFolderData(values);
  };
  if (isLoading) {
    return <TableLoader numRows={7} showButtons={true} />;
  }
  if (error) {
    return (
      <div className="p-6">
        <Typography>Failed to load placement data. Please try again later.</Typography>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-6 flex justify-between">
        <Typography variant="hero">Placements</Typography>
        <Button
          classNames="bg-primary text-white  border-none px-3 py-3"
          onClick={openModal}
          iconClassNames="filter-white "
        >
          Create candidates
        </Button>
      </div>

      {searchResults ? (
        <div>
          <div className=""></div>
          <PlacementCandidates
            data={searchResults}
            selectedJob={selectedJob}
            selectedJobId={selectedJobId}
            refetchPlacements={refetchPlacements}
            removeSearchData={handlePlacementSuccess}
            placementFolderData={placementFolderData}
          />
        </div>
      ) : placementdata.length > 0 ? (
        <div>
          <PlacementList
            placementsData={placementdata}
            isLoading={searchLoading}
            refetchPlacements={refetchPlacements}
          />
        </div>
      ) : (
        <CreateFirstPlacement />
      )}

      <BasicModal
        title={step === 'CREATE_FOLDER' ? 'Create placement folder' : 'Search for matching candidates'}
        isOpen={isModalOpended}
        onClose={closeModal}
        closeOnOverlayClick={false}
      >
        <div className="relative">
          <div
            className={`transition-opacity duration-500 ease-in-out ${
              step === 'CREATE_FOLDER' ? 'opacity-100' : 'opacity-0 pointer-events-none absolute inset-0'
            }`}
          >
            {step === 'CREATE_FOLDER' && (
              <CreatePlacementFolder handleFormSubmit={SearchForCandidatesForm} closeModal={closeModal} />
            )}
          </div>

          <div
            className={`transition-opacity duration-500 ease-in-out ${
              step === 'SEARCH_CANDIDATES' ? 'opacity-100' : 'opacity-0 pointer-events-none absolute inset-0'
            }`}
          >
            {step === 'SEARCH_CANDIDATES' && (
              <SearchForCandidates
                closeModal={closeModal}
                setSearchResults={handleSearchForCandidatesResults}
                onJobChange={handleJobChange}
                passSearchLoaging={handleSearchLoading}
              />
            )}
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default Placement;
