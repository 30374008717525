import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextInput, Typography } from 'modules/shared/components/atoms';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useForgotPasswordMutation as forgotPasswordMutation } from 'features/authApiSlice';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

const initialValues = {
  email: '',
};

const FoegetPasswordForm = () => {
  const navigate = useNavigate();
  const [generateOtp] = forgotPasswordMutation();
  const handleSubmit = (values) => {
    try {
      const { email } = values;
      generateOtp(values);
      navigate('/verify-otp', { state: { email } });
      toast.success('OTP sent successfully');
    } catch (err) {
      const {
        data: { message },
      } = err;
      toast.error(message);
    }
  };

  return (
    <div className="shadow-sm flex flex-col rounded-md px-20 py-16 bg-white">
      <article className="px-12 py-20 w-full">
        <div className="flex flex-col gap-8">
          <article>
            <Typography htmlTag="h1" className="text-center" variant="hero">
              Forgot password!
            </Typography>
            <Typography variant="inputLabel" alignText="center" htmlTag="p">
              No worries, we will send you some instructions to reset it.
            </Typography>
          </article>
        </div>
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, handleChange, handleBlur, errors, touched }) => {
            return (
              <Form>
                <div className="flex flex-col gap-8 mt-7 ">
                  <div>
                    <TextInput
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      label="Email"
                      name="email"
                      type="email"
                      error={touched.email && errors.email}
                    />
                    <Typography variant="errorText">{errors.email && touched.email ? errors.email : ''}</Typography>
                  </div>
                  <Button type="submit" classNames="bg-primary text-white border-none font-semibold tracking-wide">
                    Continue
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </article>
    </div>
  );
};

export default FoegetPasswordForm;
