import React from 'react';
import { CategorizedCandidatesFolder, TableHeader } from 'modules/candidates/components/molecules';
import { useFetchFoldersQuery as candidatesFolders } from 'features/candidatesFoldersSlice';

const CategorizedCandidatesFolders = ({ candidatesData }) => {
  const {
    data,
    error,
    isLoading,
    refetch: refetchFolders,
  } = candidatesFolders({
    page: 1,
    limit: 20,
  });
  const foldersData = data ? data.folders : [];
  return (
    <div className="flex flex-col gap-3">
      <div className="">
        <TableHeader candidatesData={candidatesData} refetchFolders={refetchFolders} />
      </div>
      <div className="flex  flex-col gap-6 ">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          foldersData?.map((item, index) => (
            <div className="" key={index}>
              <CategorizedCandidatesFolder
                title={item.name}
                isFavorite={item?.is_favorite}
                numberOfCandidates={item.candidates_ids.length}
                owner={item.name}
                folderId={item.folderID}
                refetchFolders={refetchFolders}
                candidatesData={candidatesData}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CategorizedCandidatesFolders;
