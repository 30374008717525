import React, { useRef, useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

const ImageUploader = ({
  onImageChange,
  classNames,
  iconUrl = '/assets/images/Group.png',
  iconClassNames = '',
  text = '',
  textClassName = '',
  initialImage,
}) => {
  const inputRef = useRef(null);
  const [candidateProfileImage, setCandidateProfileImage] = useState('');
  useEffect(() => {
    setCandidateProfileImage(initialImage);
  }, [initialImage]);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setCandidateProfileImage(file);
    onImageChange(file);
  };

  return (
    <div
      onClick={handleImageClick}
      className={twMerge(`w-24 h-24 bg-white-100 cursor-pointer rounded-full mx-auto mb-4 relative `, classNames)}
    >
      {candidateProfileImage ? (
        <img
          src={initialImage ? candidateProfileImage : URL.createObjectURL(candidateProfileImage)}
          alt=""
          className="w-full h-full  rounded-full object-fit z-50 "
        />
      ) : (
        <div className="">
          <img className={twMerge(`absolute right-9 top-10`, iconClassNames)} src={iconUrl} alt=".png, .jpg, .jpeg " />
          <p className={twMerge(`text-center text-base absolute`, textClassName)}>{text}</p>
        </div>
      )}
      <input type="file" name="image" accept="image/*" ref={inputRef} onChange={handleImageChange} className="hidden" />
    </div>
  );
};

export default ImageUploader;
