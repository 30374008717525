import { useState } from 'react';
import './style.css';

const MAX = 100;

export default function RangeSlider({ onChange, defaultvalue = 0 }) {
  const [value, setValue] = useState(defaultvalue);
  const getBackgroundSize = () => {
    return { backgroundSize: `${(value * 100) / MAX}% 100%` };
  };

  const handleInputChange = (e) => {
    const newValue = Number(e.target.value);
    setValue(newValue);
    if (onChange) {
      onChange(newValue); // Notify the parent component of the value change
    }
  };

  return (
    <div>
      <input
        className="range-slider"
        type="range"
        min="0"
        max={MAX}
        onChange={handleInputChange}
        style={getBackgroundSize()}
        value={value}
      />
    </div>
  );
}
