import React from 'react';
import { PackageDetails } from '../../molecules';

const SummarySection = ({ jobData }) => {
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  return (
    <div className=" grid grid-cols-3 md:flex-row gap-16">
      <div className="p-6 rounded-md shadow-xs col-span-2">
        <h2 className="text-xl font-medium mb-4">Job Description</h2>
        <p className="mb-6 text-neutral-100">{stripHtmlTags(jobData.description)}</p>
        <ul className="list-disc text-neutral-100 mb-6 px-6 "></ul>
        <h3 className="text-xl font-medium mb-4">Qualifications</h3>
        <p className="list-disc text-neutral-100 mb-6  ">{jobData.qualifications}</p>
      </div>
      <div className="">
        <PackageDetails jobData={jobData} />
      </div>
    </div>
  );
};

export default SummarySection;
