import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useFetchClientsQuery, useDeleteClientMutation } from 'features/clientsSlice';
import { Button, Typography } from 'modules/shared/components/atoms';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BasicModal } from 'modules/shared/components/molecules';
import { CreateNewClientForm } from '..';

const CustomTableCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="checkbox"
          className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[3px] bg-white border border-neutral-200 gradient-border-checkbox outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-1 checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer focus:shadow-none  focus:before:scale-100 focus:before:opacity-[0.12]   focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent dark:border-neutral-600 dark:checked:border-primary dark:checked:bg-primary"
          style={{ height: '20px', width: '20px' }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
      </div>
    </>
  );
});

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0 ',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400',
      fontSize: '16px',
      padding: '0px',
    },
  },
  cells: {
    style: {
      padding: '0px',
      textAlign: 'center',
    },
  },
};

const columns = [
  {
    name: 'Client Name',
    selector: (row) => row.logo_url,
    // width: '40px',
    cell: (row) => (
      <div className="flex justify-center items-center text-center gap-2">
        {row.logo_url && <img src={row.logo_url} alt="" className="w-8 h-8 rounded-full" />}
        {row.client_name}
      </div>
    ),
  },
  // { name: 'Client Name', selector: (row) => row.client_name, sortable: true },
  {
    name: 'Job count',
    selector: (row) => row.client_job_vacancies_count,
    sortable: true,
    width: '140px',
    cell: (row) => (
      <div className="flex text-center items-center gap-2 pl-10">
        <p> {row.client_job_vacancies_count}</p>
      </div>
    ),
  },
  { name: 'Client Industry', selector: (row) => row.client_industry, sortable: true },
  { name: 'Phone Number', selector: (row) => row.client_contact_phone_number, sortable: true },
  {
    name: 'Location',
    selector: (row) => row.client_location,
    sortable: true,
    width: '140px',
    cell: (row) => (
      <div className="flex text-center items-center gap-2">
        <p>{row.client_location.city},</p>
        <p>{row.client_location.country}</p>
      </div>
    ),
  },
  { name: 'Email', selector: (row) => row.client_contact_email, sortable: true },
  { name: 'POC', selector: (row) => row.client_point_of_contact_name, sortable: true },
];

const ClientTable = ({ clientsData, refetch, isLoading, isError }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [clientId, setClientId] = useState(null);

  const handleSelectedRowsChange = async (state) => {
    setSelectedRows(state.selectedRows);
  };
  const openModal = () => {
    setIsModalOpened(true);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const closeModal = () => {
    setIsModalOpened(false);
    setSelectedRows([]);
  };
  const handleEditSelectedClient = (row) => {
    setClientId(row.clientID);
    openModal();
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const [deleteClient, { isLoading: isDeleteClientLoading }] = useDeleteClientMutation();
  const handleDeleteSelectedClients = async () => {
    try {
      const ids = selectedRows.map((row) => row.clientID);
      const payload = { clients: ids };
      await deleteClient(payload).unwrap();
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
      setIsDeleteModalOpen(false);
      refetch();
      toast.success('Clients deleted successfully');
    } catch (err) {
      const {
        data: { message },
      } = err;
      toast.error(message);
    }
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((selectedRow) => selectedRow.clientID === row.clientID),

      style: {
        backgroundColor: '#F4F4F4',
      },
    },
  ];

  if (isError) return <p>Error loading clients!</p>;
  if (isLoading) return <p>Loading...</p>;
  if (clientsData) {
    return (
      <div className="relative">
        <DataTable
          columns={columns}
          data={clientsData}
          onSelectedRowsChange={handleSelectedRowsChange}
          selectableRowsHighlight
          conditionalRowStyles={conditionalRowStyles}
          selectableRows
          pagination
          customStyles={customStyles}
          selectableRowsComponent={CustomTableCheckbox}
          clearSelectedRows={toggleCleared}
        />
        {
          <Button
            classNames={`bg-primary text-white border-none text-sm tracking-wider py-2 px-4 bottom-2 absolute ${
              selectedRows.length === 0 ? 'hidden' : ''
            }`}
            iconUrl="/assets/icons/folder_delete2.svg"
            iconClassNames="filter-white "
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </Button>
        }
        {selectedRows.length === 1 && (
          <Button
            classNames="bg-primary text-white border-none text-sm tracking-wider py-2 px-4 bottom-2 left-28 absolute"
            onClick={() => handleEditSelectedClient(selectedRows[0])}
          >
            Edit
          </Button>
        )}
        <BasicModal title="Edit Client" isOpen={isModalOpened} onClose={closeModal}>
          <CreateNewClientForm closeModal={closeModal} clientId={clientId} isEditing={true} refetch={refetch} />
        </BasicModal>
        <BasicModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
          <div className="flex flex-col items-center justify-center gap-5 mb-5">
            <img src="/assets/icons/delete-placement-icon.svg" alt="" />
            <p className="text-xl font-semibold text-center text-neutral">Delete Client</p>
            <p className="text-lg text-center text-neutral mx-5">
              Are you sure you want to delete the selected Clients?
            </p>
          </div>
          <div className="grid grid-cols-2 place-content-center mx-20">
            <div className="flex items-center justify-center px-4 cursor-pointer" onClick={closeDeleteModal}>
              <Typography variant="body" className="w-full text-center">
                Cancel
              </Typography>
            </div>
            <div>
              <Button
                type="submit"
                classNames="flex mx-auto bg-primary text-white border-none w-full"
                onClick={handleDeleteSelectedClients}
                disabled={isDeleteClientLoading ? true : false}
                isLoading={isDeleteClientLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        </BasicModal>
      </div>
    );
  }
};

export default ClientTable;
