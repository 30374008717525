import React from 'react';
import { twMerge } from 'tailwind-merge';
const hexToRgba = (hex, opacity) => {
  const cleanHex = hex.replace(/^#/, '');
  const red = parseInt(cleanHex.substring(0, 2), 16);
  const green = parseInt(cleanHex.substring(2, 4), 16);
  const blue = parseInt(cleanHex.substring(4, 6), 16);
  const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;

  return rgba;
};
const Tag = ({ colorClass, text }) => {
  const rgbaColor = hexToRgba(colorClass, 0.1);
  return (
    <div
      style={{ backgroundColor: rgbaColor, color: colorClass }}
      className={twMerge(`tag flex rounded-[4px] items-center px-2 py-0.5  text-[${colorClass}] bg-opacity-10 w-fit `)}
    >
      <span style={{ color: colorClass }} className={` tracking-wider mr-2 text-base`}>
        {text}
      </span>
      <span
        style={{ backgroundColor: colorClass }}
        className={twMerge(`bg-[${colorClass}]  text-[${colorClass}] rounded-full w-2 h-2`)}
      ></span>
    </div>
  );
};

export default Tag;
