import { useGetTemplateByIdQuery, useGetTemplatesQuery } from 'features/jobsSlice';
import { closeJobModal } from 'features/modalsSlice';
import { Button, SelectTwo } from 'modules/shared/components/atoms';
import { BasicCard, BasicModal } from 'modules/shared/components/molecules';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { JobForm, UploadResumeJob } from '..';

const jobCreationMethods = {
  form: 'form',
  template: 'template',
  jsonOrCsv: 'json_csv',
};

const JobsModal = ({ isJobModalOpen, refetchJobs }) => {
  const dispatch = useDispatch();
  const jobTemplateRef = useRef(null);

  const [selectedjobCreattionMethod, setSelectedJobCreationMethod] = useState('');
  const [isTemplateConfirmed, setIsTemplateConfirmed] = useState(false);
  const isFormOrTemplateSelected =
    jobCreationMethods[selectedjobCreattionMethod] === jobCreationMethods?.form ||
    jobCreationMethods[selectedjobCreattionMethod] === jobCreationMethods?.template;
  const [selectedJobTemplate, setSelectedJobTemplate] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [jobId, setJobId] = useState(null);

  const { data: templatesData, refetch: refetchTemplates } = useGetTemplatesQuery(null, { skip: !isJobModalOpen });
  const { data: templateData } = useGetTemplateByIdQuery(selectedTemplateId, {
    skip: !selectedTemplateId,
  });

  useEffect(() => {
    if (templateData && templateData.length > 0) {
    }
  }, [templateData]);

  useEffect(() => {
    if (isJobModalOpen) {
      refetchTemplates();
    }
  }, [isJobModalOpen, refetchTemplates]);

  const closeModal = () => {
    dispatch(closeJobModal());
    setSelectedJobCreationMethod('');
    setJobId(null);
    setIsTemplateConfirmed(false); // Reset template confirmation
  };

  const onSelectJobMethodHandler = (value) => {
    setSelectedJobCreationMethod(value);
  };
  const handleTemplateSelection = (name, option) => {
    setSelectedJobTemplate(option);
    setSelectedTemplateId(option.label);
  };

  return (
    <div>
      <BasicModal
        title={jobId ? 'Edit Job' : 'Create Job'}
        isOpen={isJobModalOpen}
        onClose={closeModal}
        closeOnOverlayClick={false}
      >
        {!selectedjobCreattionMethod ? (
          <article className="flex flex-wrap gap-10 justify-center items-center">
            <div className="w-2/5">
              <BasicCard
                name={jobCreationMethods.form}
                onClick={onSelectJobMethodHandler}
                image="/assets/icons/complete_form.svg"
                title="Complete a form"
              />
            </div>
            <div className="w-2/5">
              <BasicCard
                onClick={onSelectJobMethodHandler}
                image="/assets/icons/template.svg"
                title="Use a template"
                name={jobCreationMethods.template}
              />
            </div>
            <div className="w-2/5">
              <BasicCard
                onClick={onSelectJobMethodHandler}
                image="/assets/icons/csv_json.svg"
                title="Import a JSON /CSV file"
                name={jobCreationMethods.jsonOrCsv}
              />
            </div>
          </article>
        ) : (
          ''
        )}

        <div>
          {isFormOrTemplateSelected ? (
            <div className={`flex justify-center gap-7 mb-6 ${jobId ? 'hidden' : ''}`}>
              <Button
                classNames={selectedjobCreattionMethod === 'form' ? 'bg-primary text-white' : ''}
                iconClassNames={selectedjobCreattionMethod === 'form' ? 'filter-white' : ''}
                iconUrl="/assets/icons/create_form.svg"
                onClick={() => {
                  setSelectedJobCreationMethod(jobCreationMethods.form);
                }}
              >
                Complete a form
              </Button>
              <Button
                onClick={() => {
                  if (selectedjobCreattionMethod === jobCreationMethods.template && isTemplateConfirmed) {
                    setIsTemplateConfirmed(false);
                  } else {
                    setSelectedJobCreationMethod(jobCreationMethods.template);
                    setIsTemplateConfirmed(false);
                  }
                }}
                classNames={selectedjobCreattionMethod === jobCreationMethods.template ? 'bg-primary text-white' : ''}
                iconClassNames={selectedjobCreattionMethod === jobCreationMethods.template ? 'filter-white' : ''}
                iconUrl="/assets/icons/create_template.svg"
              >
                Use a template
              </Button>
            </div>
          ) : (
            ''
          )}
          {selectedjobCreattionMethod === jobCreationMethods.template && !isTemplateConfirmed ? (
            <div className="flex gap-6 flex-col">
              <div>
                {templatesData?.templates?.length > 0 ? (
                  <SelectTwo
                    selectRef={jobTemplateRef}
                    name="job_templage"
                    label="Select template"
                    value={selectedJobTemplate}
                    setFieldValue={handleTemplateSelection}
                    options={templatesData?.templates.map((template) => ({
                      value: template.name,
                      label: template.templateID,
                    }))}
                  />
                ) : (
                  <p className="text-center">There are no templates available.</p>
                )}
              </div>
              <div className="flex justify-center">
                <Button
                  onClick={() => {
                    setSelectedJobCreationMethod(jobCreationMethods.form);
                    setIsTemplateConfirmed(true);
                    setSelectedJobTemplate(null);
                  }}
                  disabled={!selectedJobTemplate}
                  classNames="w-full md:w-1/2 bg-primary text-white"
                  type="button"
                >
                  continue
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
          {selectedjobCreattionMethod === jobCreationMethods.form || isTemplateConfirmed ? (
            <div>
              <JobForm
                refetch={refetchJobs}
                closeModal={closeModal}
                jobId={jobId}
                templateObjectData={isTemplateConfirmed ? templateData : null}
              />
            </div>
          ) : (
            ''
          )}
          {selectedjobCreattionMethod === jobCreationMethods.jsonOrCsv ? (
            <UploadResumeJob refetch={refetchJobs} closeModal={closeModal} />
          ) : (
            ''
          )}
        </div>
      </BasicModal>
    </div>
  );
};

export default JobsModal;
