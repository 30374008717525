import { useFormik } from 'formik';
import { SwitchButton, TextInputCounter, Typography } from 'modules/shared/components/atoms';
import React from 'react';
import ReactQuill from 'react-quill';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';
import './EmailSection.css';

const EmailSection = () => {
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    userEmail: Yup.string().email('Invalid email address').required('Your Email is required'),
    // Add more validation rules as needed
    describtion: Yup.string().required('Email body is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      userEmail: '',
      describtion: '',
      // Add more initial values as needed
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
    },
  });

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid gap-4 mt-3">
        <Typography variant="mainCardTitle" className="tracking-widest">
          Email
        </Typography>

        <article className="grid gap-4 py-6 shadow-lg rounded-md px-10">
          <div className="flex items-center justify-between">
            <Typography className="text-base tracking-wider">status</Typography>
            <SwitchButton />
          </div>
        </article>

        <article className="grid gap-4 py-6 shadow-lg rounded-md px-10">
          <div>
            <Typography className="font-semibold text-lg tracking-widest">Email action</Typography>
            <Typography className="text-base tracking-wider">
              Set your custom email address to contact with Re Hirez.ai directly
            </Typography>
          </div>
          <div className="flex items-center justify-between">
            <Typography className="text-base tracking-wider w-3/12">Your Email Address</Typography>
            <TextInputCounter
              classNames={`bg-white py-3 border${touched.userEmail && errors.userEmail ? '-error' : ''}`}
              label="Your Email Address"
              name="userEmail"
              type="email"
              error={touched.userEmail && errors.userEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.userEmail}
            />
          </div>
        </article>

        <article className="grid gap-4 py-6 shadow-lg rounded-md px-10">
          <Typography className="font-semibold text-lg tracking-widest">Mass Emailing</Typography>
          <div className="flex items-center justify-between">
            <Typography className="text-base tracking-wider">status</Typography>
            <SwitchButton />
          </div>
        </article>

        <article className="grid gap-6 py-6 shadow-lg rounded-md px-10">
          <div>
            <Typography className="font-semibold text-lg tracking-widest">Candidate Email Sharing</Typography>
          </div>
          <div className="flex items-center justify-between">
            <Typography className="font-semibold text-base tracking-widest w-4/12">Email subject</Typography>
            <TextInputCounter
              classNames={`bg-white py-3 border${touched.email && errors.email ? '-error' : ''}`}
              label="Email subject"
              name="emailSubject"
              type="text"
              error={touched.email && errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.emailSubject}
            />
          </div>
          <div className="email-sharing flex justify-between">
            <Typography className="font-semibold text-base tracking-widest w-4/12">Email body</Typography>
            <ReactQuill
              modules={modules}
              className={twMerge(
                errors.describtion && touched.describtion ? 'border-error ' : 'border-neutral border-spacing-3',
                'border rounded-md overflow-hidden relative w-full outline-none',
              )}
              value={values.describtion}
              onChange={(val) => setFieldValue('describtion', val)}
            />
            {touched.describtion && errors.describtion && <div className="text-red-500">{errors.describtion}</div>}
          </div>
        </article>
      </div>
    </form>
  );
};

export default EmailSection;
