import { Button } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import React, { useState } from 'react';
import { CreatePlacementFolder, SearchForCandidates } from '../../organisms';

const CreateFirstPlacement = ({ candidatesData }) => {
  const [isModalOpended, setIsModalOpended] = useState(false);
  const [step, setStep] = useState('CREATE_FOLDER');
  const closeModal = () => {
    setIsModalOpended(false);
    setStep('CREATE_FOLDER');
  };
  const openModal = () => {
    setIsModalOpended(true);
  };

  const SearchForCandidatesForm = (values) => {
    setStep('SEARCH_CANDIDATES');
  };
  return (
    <div className="flex justify-center align-center flex-col ">
      <div className="flex justify-center">
        <img className="  " src="/assets/icons/NoDocuments.svg" alt="empty folder" />
      </div>
      <p className="flex justify-center text-neutral font-bold font-poppins text-xl tracking-wide">Candidate Resume</p>
      <p className="flex justify-center text-center pt-3 text-gray font-poppins text-lg ">
        There are no resumes available at the moment.
        <br />
        Please start the search.
      </p>
      <div className="flex place-content-center mt-10">
        <Button
          classNames="bg-primary text-white  border-none px-3 py-3"
          // iconUrl="/assets/icons/icon_plus.svg"
          iconClassNames="filter-white mr-1.5"
          onClick={openModal}
        >
          Create candidates
        </Button>
      </div>
      <BasicModal
        title={step === 'CREATE_FOLDER' ? 'Create placement folder' : 'Search for matching candidates'}
        isOpen={isModalOpended}
        onClose={closeModal}
        closeOnOverlayClick={false}
      >
        <div className="relative">
          <div
            className={`transition-opacity duration-500 ease-in-out ${
              step === 'CREATE_FOLDER' ? 'opacity-100' : 'opacity-0 pointer-events-none absolute inset-0'
            }`}
          >
            {step === 'CREATE_FOLDER' && (
              <CreatePlacementFolder handleFormSubmit={SearchForCandidatesForm} closeModal={closeModal} />
            )}
          </div>

          <div
            className={`transition-opacity duration-500 ease-in-out ${
              step === 'SEARCH_CANDIDATES' ? 'opacity-100' : 'opacity-0 pointer-events-none absolute inset-0'
            }`}
          >
            {step === 'SEARCH_CANDIDATES' && <SearchForCandidates closeModal={closeModal} />}
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default CreateFirstPlacement;
