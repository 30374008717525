import React, { useState, useEffect } from 'react';
import { Typography } from '../../atoms';

const Modal = ({ isOpen, onClose, children, title, closeOnOverlayClick = true }) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  useEffect(() => {
    setModalOpen(isOpen);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const closeModal = () => {
    setModalOpen(false);
    onClose();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleOverlayClick = () => {
    if (closeOnOverlayClick) {
      closeModal();
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-10">
          {/* Overlay covering the entire page */}
          <div
            className="fixed top-0 left-0 w-full h-full bg-black opacity-0"
            style={{ opacity: 0.5 }}
            onClick={handleOverlayClick}
          />

          {/* Modal content */}
          <div
            className="bg-white p-4 sm:p-6 md:p-8 rounded-md w-full sm:w-4/5 md:w-3/5 lg:w-2/5 relative opacity-0 max-h-[80vh] overflow-y-auto"
            style={{ opacity: 1 }}
            onClick={stopPropagation}
          >
            <div className="flex justify-between items-center mb-4 tracking-wider text-base sm:text-lg md:text-xl">
              <Typography variant="h4" alignText="center" htmlTag="p" className="text-base sm:text-lg md:text-xl">
                {title}
              </Typography>
              <button className="bg-secondary-shd3 p-2 sm:p-3 rounded-full" onClick={closeModal}>
                <img src="/assets/icons/close-icon.svg" alt="close modal" className="w-4 h-4 sm:w-5 sm:h-5" />
              </button>
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
