import { Tag } from 'modules/adminstration/components/molecules';
import { AddNewTag } from 'modules/adminstration/components/organisms';
import { Button, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import React, { useState } from 'react';

const initialTagsData = [
  {
    id: 1,
    name: 'available',
    color: '#00D609',
  },
  {
    id: 2,
    name: 'blacklist',
    color: '#E92C2C',
  },
  {
    id: 3,
    name: 'foreigner',
    color: '#077D96',
  },
  {
    id: 4,
    name: 'important',
    color: '#694C86',
  },
  {
    id: 5,
    name: 'openToRelocation',
    color: '#000000',
  },
  {
    id: 6,
    name: 'recentlyPlaced',
    color: '#8CD4ED',
  },
  {
    id: 7,
    name: 'remote',
    color: '#FFD704',
  },
  {
    id: 8,
    name: 'vip',
    color: '#FFEA00',
  },
];
const CustomizationCandidates = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [tagsData, setTagsData] = useState(initialTagsData);
  const [newColor, setNewColor] = useState('');

  const closeModal = () => {
    setIsModalOpened(false);
    setSelectedTag(null); // Reset selected tag when closing the modal
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  const handleEditClick = (tag) => {
    setSelectedTag(tag);
    openModal();
  };

  const handleAddTag = (selectedColor, tagText) => {
    setNewColor(selectedColor);
    setTagsData([...tagsData, { id: tagsData.length + 1, color: selectedColor, name: tagText }]);
  };
  const handleUpdateTag = (updatedTag) => {
    // Handle updating the tagsData state with updatedTag
    const updatedTags = tagsData.map((tag) =>
      tag.id === updatedTag.id ? { ...tag, name: updatedTag.name, color: updatedTag.color } : tag,
    );
    setTagsData(updatedTags);
  };
  return (
    <div className="grid gap-12 mx-14">
      <Typography className="font-semibold text-2xl tracking-widest">Candidates</Typography>
      <article className="grid gap-5  mr-36">
        <div className="flex justify-between">
          <Typography variant="mainCardTitle">Tags</Typography>
          <Button onClick={openModal} classNames="bg-primary px-4 py-2 tracking-widest text-white border-none">
            add a new tag
          </Button>
        </div>
        <article className="w-full py-8 shadow-lg rounded-md px-10 grid gap-6">
          {tagsData.map((tag) => (
            <div key={tag.id} className="flex justify-between">
              <Tag colorClass={tag.color} text={tag.name} />
              <div className="flex gap-4">
                <img
                  src="/assets/icons/folder_edit2.svg"
                  className="cursor-pointer"
                  alt=""
                  onClick={() => handleEditClick(tag)}
                />
                <img src="/assets/icons/folder_delete2.svg" alt="" />
              </div>
            </div>
          ))}
        </article>
      </article>
      <BasicModal
        title={selectedTag ? 'Edit Tag' : 'Add a Tag'}
        isOpen={isModalOpened}
        onClose={closeModal}
        closeOnOverlayClick={false}
      >
        <AddNewTag
          closeModal={closeModal}
          onAddTag={handleAddTag}
          selectedTag={selectedTag}
          updateTag={handleUpdateTag}
        />{' '}
      </BasicModal>
    </div>
  );
};

export default CustomizationCandidates;
