import { ComposeModal } from 'modules/Inbox/components/atoms';
import { EmailList } from 'modules/Inbox/components/molecules';
import { MenuNavLink } from 'modules/homepage/components/atoms';
import { Button, Typography } from 'modules/shared/components/atoms';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const emailsData = [
  {
    id: 1,
    sender: 'john.doe@example.com',
    subject: 'Meeting Tomorrow',
    body: "Hi, let's discuss the agenda for tomorrow's meeting.",
    unread: true,
  },
  {
    id: 2,
    sender: 'alice.smith@example.com',
    subject: 'Project Update',
    body: 'Please review the latest project update and provide your feedback.',
    unread: false,
  },
  {
    id: 3,
    sender: 'bob.jones@example.com',
    subject: 'Important Announcement',
    body: 'We have an important announcement regarding upcoming changes.',
    unread: true,
  },
  {
    id: 4,
    sender: 'susan.jenkins@example.com',
    subject: 'Weekly Newsletter',
    body: 'Check out our weekly newsletter for the latest updates and news.',
    unread: false,
  },
  {
    id: 5,
    sender: 'chris.miller@example.com',
    subject: 'Task Assignment',
    body: 'You have been assigned a new task. Please review and get started.',
    unread: true,
  },
  // Add more emails as needed
];

const Inbox = () => {
  const [isComposeModalOpen, setComposeModalOpen] = useState(false);

  const handleComposeClick = () => {
    setComposeModalOpen(true);
  };

  const handleCloseComposeModal = () => {
    setComposeModalOpen(false);
  };

  const handleCompose = (content) => {
    // Implement the logic to send the composed email (e.g., API call)
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-8">
        <Typography className="text-2xl font-bold">Inbox</Typography>
        <Button
          classNames="bg-primary text-white  border-none font-semibold tracking-wider"
          iconUrl="/assets/icons/icon_plus.svg"
          iconClassNames="filter-white mr-2"
          onClick={handleComposeClick}
        >
          Compose
        </Button>
      </div>
      <div className="flex justify-between items-center shadow-lg rounded-md px-5 py-2">
        <NavLink to="" end>
          {({ isActive }) => {
            return (
              <span className={twMerge(isActive ? 'bg-white-100' : '', 'flex gap-3  py-2 px-3 rounded-r-lg')}>
                <img
                  src="/assets/icons/inbox_icon.svg"
                  alt="folder icon"
                  className={twMerge(isActive ? 'filter-primary' : '')}
                />
                <span className={twMerge(isActive ? 'text-primary' : '', 'tracking-wider')}>Inbox</span>
                <span className={twMerge(isActive ? 'text-primary' : '')}>3</span>
              </span>
            );
          }}
        </NavLink>
        <NavLink to="starred" end>
          {({ isActive }) => {
            return (
              <span className={twMerge(isActive ? 'bg-yello bg-opacity-10' : '', 'flex gap-3 py-2 px-3 rounded-lg')}>
                <img
                  src="/assets/icons/star_icon.svg"
                  alt="folder icon"
                  className={twMerge(isActive ? '' : 'filter-black', 'w-5')}
                />
                <span className={twMerge(isActive ? 'text-yello' : '', 'tracking-wider')}>Starred</span>
              </span>
            );
          }}
        </NavLink>
        <NavLink to="sent" end>
          {({ isActive }) => {
            return (
              <span className={twMerge(isActive ? 'bg-white-100' : '', 'flex gap-3 py-1.5 px-5  rounded-lg')}>
                <img
                  src="/assets/icons/send_icon.svg"
                  alt="folder icon"
                  className={twMerge(isActive ? 'filter-primary' : '')}
                />
                <span className={twMerge(isActive ? 'text-primary' : '', 'tracking-wider')}>Sent</span>
              </span>
            );
          }}
        </NavLink>
        <NavLink to="drafts" end>
          {({ isActive }) => {
            return (
              <span className={twMerge(isActive ? 'bg-white-100' : '', 'flex gap-3 py-2 px-3  rounded-lg')}>
                <img src="/assets/icons/draft.svg" alt="folder icon" />
                <span>Drafts</span>
                <span>1</span>
              </span>
            );
          }}
        </NavLink>
        <NavLink to="trash" end>
          {({ isActive }) => {
            return (
              <span className={twMerge(isActive ? 'bg-error bg-opacity-10' : '', 'flex gap-3 py-2 px-3 rounded-lg')}>
                <span className={twMerge(isActive ? 'text-error' : '', 'tracking-wider')}>Trash</span>
                <img
                  src="/assets/icons/trash2.svg"
                  alt="folder icon"
                  className={twMerge(isActive ? '' : 'filter-black')}
                />
              </span>
            );
          }}
        </NavLink>
      </div>
      <div className=" mt-5 ">
        <Outlet />
      </div>
      <ComposeModal isOpen={isComposeModalOpen} onClose={handleCloseComposeModal} onCompose={handleCompose} />
    </div>
  );
};

export default Inbox;
