import { apiSlice } from 'app/api/apiSlice';
const candidatesFoldersApi = apiSlice.injectEndpoints({
  reducerPath: 'candidatesFolders',
  endpoints(builder) {
    return {
      fetchFolders: builder.query({
        query: ({ page, limit }) => {
          return {
            url: '/folders/list',
            method: 'GET',
            params: {
              _page: page,
              _limit: limit,
            },
          };
        },
      }),
      fetchFolderById: builder.query({
        query: (folderId) => ({
          url: `/folders/${folderId}`,
          method: 'GET',
        }),
      }),
      createFolder: builder.mutation({
        query: (newFolder) => ({
          url: '/folders/folder',
          method: 'POST',
          body: newFolder,
        }),
      }),
      deleteFolder: builder.mutation({
        query: (folderId) => ({
          url: `/folders/delete`,
          method: 'DELETE',
          body: {
            folders: [folderId],
          },
        }),
      }),
      updateFolder: builder.mutation({
        query: ({ folderId, data }) => ({
          url: `/folders/${folderId}`,
          method: 'PUT',
          body: data,
        }),
      }),
    };
  },
});

export const {
  useFetchFoldersQuery,
  useFetchFolderByIdQuery,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useUpdateFolderMutation,
} = candidatesFoldersApi;

export { candidatesFoldersApi };
