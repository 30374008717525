import DataTable from 'react-data-table-component';
import { Avatar } from 'modules/homepage/components/atoms';
import { Button } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import { AddTemplateModal } from '../../molecules';
import { useState } from 'react';

const generateData = () => {
  const newData = [];
  for (let i = 1; i <= 20; i++) {
    newData.push({
      id: i,
      name: 'Application acknowledgement',
      subject: 'Your Application Status for the Position of {{job_position_name}}',
      creator: {
        name: 'Amgad Mohamed',
        avatar: '/assets/icons/avatar.png',
      },
      created_date: '2023-12-8',
    });
  }
  return newData;
};
const data = generateData();

const handleDelete = (rowId) => {};

const handleHemisphere = (rowId) => {};

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0',
      borderBottom: 'none ',
    },
  },
  headCells: {
    style: {
      fontWeight: '600',
      fontSize: '18px',
      letterSpacing: '0.5px',
      color: '#212121',
      borderBottom: 'none !important',
    },
  },
  rows: {
    style: {
      fontSize: '13px',
      letterSpacing: '.5px',
      borderBottom: 'none !important',
    },
  },
  pagination: {
    style: {
      fontSize: '13px',
      letterSpacing: '.5px',
      borderTop: 'none !important',
    },
  },
};

const TemplatesTable = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // State variable to hold selected row data
  const closeModal = () => {
    setIsModalOpened(false);
    setSelectedRow(null);
  };

  const openModal = (row) => {
    setSelectedRow(row);
    setIsModalOpened(true);
  };

  const handleAdd = (newData) => {};

  const handleUpdate = (rowId, updatedData) => {};

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      width: '245px',
    },
    {
      name: 'Subject',
      selector: (row) => row.subject,
      width: '250px',
    },
    {
      name: 'Creator',
      selector: (row) => row.creator,
      cell: (row) => (
        <div className="flex items-center gap-2">
          <img
            src={row.creator.avatar}
            alt={`Avatar of ${row.creator.name}`}
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          />
          <p className="text-primary">{row.creator.name}</p>
        </div>
      ),
      sortable: true,
      width: '200px',
    },
    {
      name: 'Created Date',
      selector: (row) => row.created_date,
      cell: (row) => (
        <div className="flex ">
          <span className="mr-10">{row.created_date}</span>
        </div>
      ),
      width: '170px',
    },
    {
      name: '',
      cell: (row) => (
        <div className="flex items-center gap-4">
          <img
            src="/assets/icons/folder_edit2.svg"
            className="cursor-pointer"
            onClick={() => {
              const rowToEdit = data.find((rowData) => rowData.id === row.id);
              openModal(rowToEdit);
              setSelectedRow(rowToEdit);
            }}
            alt=""
          />
          <img
            src="/assets/icons/folder_delete2.svg"
            className="cursor-pointer"
            onClick={() => handleDelete(row.id)}
            alt=""
          />
          <img
            src="/assets/icons/hemisphere-icon.svg"
            className="cursor-pointer"
            onClick={() => handleHemisphere(row.id)}
            alt=""
          />
        </div>
      ),
      allowOverflow: true,
    },
  ];

  if (data) {
    return (
      <div className="relative shadow-lg px-9 rounded-md ">
        <DataTable
          columns={columns}
          data={data}
          selectableRowsHighlight
          customStyles={customStyles}
          pagination={true}
        />
        {
          <Button
            classNames={`bg-primary text-white border-none  tracking-wider py-2 px-4 bottom-2 absolute `}
            iconUrl="/assets/icons/icon_plus.svg"
            iconClassNames="filter-white "
            onClick={() => openModal(selectedRow)}
          >
            Add Template
          </Button>
        }
        <BasicModal
          title={selectedRow ? 'Update Template' : 'Add Template'}
          isOpen={isModalOpened}
          onClose={closeModal}
          closeOnOverlayClick={false}
        >
          <AddTemplateModal rowData={selectedRow} onAdd={handleAdd} onUpdate={handleUpdate} onClose={closeModal} />
        </BasicModal>
      </div>
    );
  }
};

export default TemplatesTable;
