// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDataGrid-columnHeader {
    --tw-bg-opacity: 1;
    background-color: rgb(233 240 254 / var(--tw-bg-opacity))
}
.MuiDataGrid-row.Mui-selected {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(244 244 244 / var(--tw-bg-opacity)) !important
}
`, "",{"version":3,"sources":["webpack://./src/modules/jobs/pages/Jobs/styles.css"],"names":[],"mappings":"AACE;IAAA,kBAAwB;IAAxB;AAAwB;AAGxB;IAAA,6BAA8B;IAA9B;AAA8B","sourcesContent":[".MuiDataGrid-columnHeader {\n  @apply bg-secondary-shd3;\n}\n.MuiDataGrid-row.Mui-selected {\n  @apply bg-white-100 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
