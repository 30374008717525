import { Button, TextInput, Typography } from 'modules/shared/components/atoms';
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  referralCode: Yup.string().required('Referral code is required'),
});
const initialValues = {
  referralCode: '', // Initial values for your form fields
};

const PaymentDetails = () => {
  const handleSubmit = (values, { setSubmitting }) => {
    // Your form submission logic goes here
    // values.referralCode contains the entered referral code value
    setSubmitting(false);
  };
  return (
    <article className="flex flex-col gap-4 shadow-lg rounded-md px-14 py-10">
      <Typography htmlTag="h3" variant="mainCardTitle" className="tracking-widest">
        Payment details{' '}
      </Typography>
      <article className="grid grid-cols-5">
        <article className="col-span-2">
          <article>
            <Typography className="font-semibold tracking-wider text-base">Summary</Typography>
            <div className="flex items-center gap-2 mx-3">
              <span className={`rounded-full w-1.5 h-1.5 bg-black`}></span>
              <Typography className="font-semibold tracking-wider text-base">
                {' '}
                You are subscribing to the Enterprise plan
              </Typography>
            </div>
            <div className="flex items-center gap-2 mx-3">
              <span className={`rounded-full w-1.5 h-1.5 bg-black`}></span>
              <Typography className="font-semibold tracking-wider text-base">You selected yearly billing</Typography>
            </div>
            <div className="flex items-center gap-2 mx-3">
              <span className={`rounded-full w-1.5 h-1.5 bg-black`}></span>
              <Typography className="font-semibold tracking-wider text-base">You are subscribing to 1 seat</Typography>
            </div>
          </article>
        </article>
        <article className="col-span-3">
          <article>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <article className="grid grid-cols-5 gap-5">
                    <div className="col-span-4 pl-4">
                      <TextInput
                        type="text"
                        id="code"
                        label={'Enter the referral code here'}
                        name="referralCode"
                        classNames={'border-neutral-300'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.referralCode}
                        className={`${
                          touched.referralCode && errors.referralCode ? 'border-red-500' : 'border-gray-300'
                        }`}
                      />
                      <Typography variant="errorText">
                        {touched.referralCode && errors.referralCode ? errors.referralCode : ''}
                      </Typography>
                    </div>{' '}
                    <div className="">
                      <Button
                        type="submit"
                        disabled={!values.referralCode}
                        classNames={`border-none ${
                          !values.referralCode
                            ? 'disabled:bg-neutral-300  cursor-not-allowed'
                            : 'bg-primary text-white hover:bg-primary'
                        }`}
                      >
                        Apply
                      </Button>
                    </div>
                  </article>
                </Form>
              )}
            </Formik>
          </article>
          <article className="flex justify-between pl-4 mt-7">
            <Typography className="font-semibold tracking-wider text-base">Total </Typography>
            <Typography className="font-semibold tracking-wider text-base">$420 USD/Year</Typography>
          </article>
        </article>
      </article>
    </article>
  );
};

export default PaymentDetails;
