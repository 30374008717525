import React, { useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, DropFileInput, Select } from 'modules/shared/components/atoms';
import { useUploadResumeMutation as uploadResumeApi } from 'features/candidatesSlice';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  resumeFiles: Yup.array().min(1, 'Please upload at least one file'),
  // job_template: Yup.object(),
});

const initialValues = {
  resumeFiles: [],
  // job_template: '',
};

const UploadResume = ({ refetch, closeModal }) => {
  const jobTemplateRef = useRef(null);
  const [uploadResume, { isLoading }] = uploadResumeApi();
  const onFileChange = (files, setFieldValue) => {
    setFieldValue('resumeFiles', files);
  };

  const handleSubmit = async (values) => {
    try {
      const payload = new FormData();
      values.resumeFiles.forEach((file) => {
        payload.append('file', file);
      });
      // payload.append('job_template', values.job_template.key);

      await uploadResume(payload).unwrap();
      toast.success('Resume uploaded successfully');
      closeModal();
      refetch();
    } catch (err) {
      if (err?.status === 400) {
        const errorMessage = err?.data?.message || 'Error uploading resume.';
        toast.error(errorMessage);
      } else {
        const errorMessage = err?.data?.message || 'An error occurred. Please try again.';
        toast.error(errorMessage);
      }
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ setFieldValue, isSubmitting }) => (
        <Form className="grid gap-7 max-w-2xl mx-auto">
          <div>
            <Field name={'job_template'}>
              {({ field }) => (
                <Select
                  {...field}
                  selectRef={jobTemplateRef}
                  setFieldValue={setFieldValue}
                  label="Select jobs (optional)"
                  options={[
                    { key: 1, value: 'Job 1' },
                    { key: 2, value: 'Job 2' },
                    { key: 3, value: 'Job 3' },
                    { key: 4, value: 'Job 4' },
                  ]}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="resumeFiles">
              {({ field }) => (
                <>
                  <DropFileInput
                    {...field}
                    fileTypes=".pdf, .doc, .docx, .rtf"
                    onFileChange={(files) => onFileChange(files, setFieldValue)}
                  />
                  <ErrorMessage name="resumeFiles" component="div" className="text-error" />
                </>
              )}
            </Field>
          </div>
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
            classNames="flex mx-auto bg-primary text-white border-none w-4/6"
          >
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UploadResume;
