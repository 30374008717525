// features/modalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isJobModalOpen: false,
  isClientModalOpen: false,
  isCandidateModalOpen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openJobModal(state) {
      state.isJobModalOpen = true;
    },
    closeJobModal(state) {
      state.isJobModalOpen = false;
    },
    openClientModal(state) {
      state.isClientModalOpen = true;
    },
    closeClientModal(state) {
      state.isClientModalOpen = false;
    },
    openCandidateModal(state) {
      state.isCandidateModalOpen = true;
    },
    closeCandidateModal(state) {
      state.isCandidateModalOpen = false;
    },
  },
});

export const {
  openJobModal,
  closeJobModal,
  openClientModal,
  closeClientModal,
  openCandidateModal,
  closeCandidateModal,
} = modalSlice.actions;

export default modalSlice.reducer;
