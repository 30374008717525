import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CandidateCategoryCard, EmptyFolderPlaceholder } from 'modules/candidates/components/molecules';
import { SearchInput } from 'modules/shared/components/atoms';
import { CategorizedCandidatesFolders } from 'modules/candidates/components/organisms';
import { useFetchFoldersQuery } from 'features/candidatesFoldersSlice';
import { useFetchCandidatesQuery } from 'features/candidatesSlice';
import { TableLoader } from 'modules/shared/components/molecules';
const Folders = () => {
  const navigate = useNavigate();

  const { data: foldersData, error, isLoading, refetch: refetchFolders } = useFetchFoldersQuery({ page: 1, limit: 20 });
  const {
    data: candidatesData,
    error: candidateError,
    isLoading: candidateLoading,
    refetch: refetchCandidates,
  } = useFetchCandidatesQuery({ page: 1, limit: 10 });

  const handleCardClick = (folderId, candidates) => {
    navigate(`/dashboard/candidates/folders/${folderId}`, { state: { candidates } });
  };

  if (isLoading || candidateLoading)
    return (
      <div>
        <div className="animate-pulse">
          <div className="h-6 bg-gray-400 rounded w-40 my-4"></div>
        </div>
        <div className="grid grid-cols-5 gap-4  items-start rounded-md p-2 ">
          {[...new Array(7)].map((_, index) => (
            <div className="rounded-md p-2 shadow-sm shadow-gray gap-1 animate-pulse">
              <div className="absolute top-6 right-4 w-6 h-6 rounded-full bg-gray-400 "></div>
              <div className="mx-auto my-4 w-12 h-12 rounded-full bg-gray-400 "></div>
              <div className="w-full h-1 bg-gray-400 "></div>
              <div className="h-4 w-3/4 mt-2 bg-gray-400 "></div>
              <div className="flex justify-between w-full mt-2">
                <div className="h-4 w-1/2 rounded-md bg-gray-400 "></div>
              </div>
            </div>
          ))}
        </div>
        <TableLoader numRows={7} showButtons={true} />
      </div>
    );
  const hasFolders = foldersData?.folders?.length > 0;
  const favoriteFolders = foldersData?.folders.filter((item) => item.is_favorite) || [];
  return (
    <div className="flex flex-col gap-2">
      {foldersData?.folders.length > 0 && (
        <div className="">
          <p className="font-semibold text-xl tracking-wider">Favourites</p>
          {/* <SearchInput placeholder="Search" name="search" label="Search folder" classNames="px-6" /> */}
        </div>
      )}
      <div className="flex flex-wrap gap-8">
        {favoriteFolders.map((item) => (
          <div key={item.folderID} className="mt-4">
            <CandidateCategoryCard
              title={item.name}
              numberOfCandidates={item.candidates_ids.length}
              folderId={item.folderID}
              onClick={() => handleCardClick(item.folderID, item.candidates_list)}
              refetchFolders={refetchFolders}
              candidatesData={candidatesData}
            />
          </div>
        ))}
        {favoriteFolders.length < 1 && (
          <p className="flex items-center my-4  text-base text-center tracking-wider">
            Currently, you don’t have any favorite folders.
          </p>
        )}

        {!hasFolders ? (
          <div className="flex justify-center w-full p-6">
            <EmptyFolderPlaceholder candidatesData={candidatesData} refetchFolders={refetchFolders} />
          </div>
        ) : (
          ''
        )}
      </div>

      {foldersData?.folders.length > 0 && <CategorizedCandidatesFolders candidatesData={candidatesData} />}
    </div>
  );
};

export default Folders;
