import { Typography } from 'modules/shared/components/atoms';
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { twMerge } from 'tailwind-merge';
import './ComposeModal.css';

const ComposeModal = ({ isOpen, onClose }) => {
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const [isContentMinimized, setContentMinimized] = useState(true);
  const [recipients, setRecipients] = useState('');
  const [subject, setSubject] = useState('');
  const [richText, setRichText] = useState('');

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  useEffect(() => {
    setModalOpen(isOpen);
    setContentMinimized(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const closeModal = () => {
    setModalOpen(false);
    onClose();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    closeModal();
  };

  const handleMinimizeClick = () => {
    setContentMinimized(!isContentMinimized);
  };

  return (
    <>
      {isModalOpen && (
        <div className={`grid place-items-end ${isContentMinimized ? 'absolute bottom-0 right-10 w-full' : ''}`}>
          <div className="shadow-lg w-1/2 bg-white rounded-t-lg">
            <div className="bg-primary flex justify-between py-2 px-5 rounded-t-sm">
              <Typography className="text-white font-semibold text-base">New Message</Typography>
              <div className="flex items-center gap-4 text-white">
                <span className="cursor-pointer h-full flex items-center" onClick={handleMinimizeClick}>
                  <img src="/assets/icons/minimize.svg" alt="" className="cursor-pointer" />
                </span>
                <span className="cursor-pointer h-full flex items-center" onClick={closeModal}>
                  <img src="/assets/icons/close-icon2.svg" alt="" className="cursor-pointer filter-white" />
                </span>
              </div>
            </div>
            {!isContentMinimized && (
              <div className="p-4 rounded-md relative" onClick={stopPropagation}>
                <form onSubmit={handleFormSubmit} className="grid gap-4">
                  <div>
                    <input
                      type="text"
                      id="from"
                      className="mt-1 p-1 border-b border-white-300 outline-none w-full"
                      placeholder="From"
                      value={recipients}
                      onChange={(e) => setRecipients(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      id="recipients"
                      className="mt-1 p-1 border-b border-white-300 outline-none w-full"
                      placeholder="Recipients"
                      value={recipients}
                      onChange={(e) => setRecipients(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      id="subject"
                      placeholder="Subject"
                      className="mt-1 p-1 border-b border-white-300 outline-none w-full"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="compose-modal-quill">
                    <ReactQuill
                      modules={modules}
                      className={twMerge(' rounded-md overflow-hidden relative w-full h-40 outline-none')}
                      value={richText}
                      onChange={setRichText}
                    />
                  </div>
                  <button type="submit" className="bg-primary text-white p-2 rounded-md">
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ComposeModal;
