import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Outlet } from 'react-router-dom';
import { Button, Typography } from 'modules/shared/components/atoms';
import { useFetchCandidatesQuery } from 'features/candidatesSlice';
import { BasicCard, BasicModal } from 'modules/shared/components/molecules';
import {
  UploadResume,
  CreateNewCandidateForm,
  JsonCsvFileUploader,
  CandidatesTable,
} from 'modules/candidates/components/organisms';
import { useDispatch, useSelector } from 'react-redux';
import { closeCandidateModal, openCandidateModal } from 'features/modalsSlice';
import { ToastContainer } from 'react-toastify';
import './styles.css';

const jobCreationMethods = {
  form: 'form',
  UploadResume: 'upload_resume',
  jsonOrCsv: 'json_csv',
  linkedin: 'linkedin',
  update: 'update',
};

const TabNavigation = () => {
  return (
    <div className="flex gap-4 items-center">
      <NavLink
        to="/dashboard/candidates"
        end
        className={({ isActive }) =>
          `flex gap-2 cursor-pointer ${
            isActive
              ? "after:absolute relative after:content[''] after:h-[2px] after:w-full after:-bottom-3 after:left-0 after:right-0 after:bg-primary"
              : ''
          }`
        }
      >
        <img src="/assets/icons/folder_icon.svg" alt="folder icon" />
        <span>Candidates</span>
      </NavLink>
      <NavLink
        to="/dashboard/candidates/folders"
        className={({ isActive }) =>
          `flex gap-2 cursor-pointer ${
            isActive
              ? "after:absolute relative after:content[''] after:h-[2px] after:w-full after:-bottom-3 after:left-0 after:right-0 after:bg-primary"
              : ''
          }`
        }
      >
        <img src="/assets/icons/folder_icon.svg" alt="folder icon" />
        <span>Folders</span>
      </NavLink>
    </div>
  );
};

const Candidates = () => {
  const dispatch = useDispatch();
  const isCandidateModalOpen = useSelector((state) => state.modal.isCandidateModalOpen);

  const {
    data,
    error,
    isLoading,
    refetch: refetchCandidates,
  } = useFetchCandidatesQuery({
    page: 1,
    limit: 10,
  });

  const [title, setTitle] = useState('Create a candidate');
  const [selectedjobCreattionMethod, setSelectedJobCreationMethod] = useState('');
  useEffect(() => {
    const renderTitle = () => {
      switch (selectedjobCreattionMethod) {
        case 'form':
          setTitle('complete form');
          break;
        case 'upload_resume':
          setTitle('Upload a resume');
          break;
        case 'json_csv':
          setTitle('Import a Json/CSV file');
          break;
        case 'linkedin':
          setTitle('Export from LinkedIn');
          break;
        default:
          return '';
      }
    };

    renderTitle();
  }, [selectedjobCreattionMethod]);

  const handleChangeTitle = (value) => {
    setTitle(value);
  };
  const onSelectJobMethodHandler = (value) => {
    setSelectedJobCreationMethod(value);
  };

  const [isModalOpended, setIsModalOpended] = useState(false);
  const closeModal = () => {
    dispatch(closeCandidateModal());
    setSelectedJobCreationMethod('');
    setTitle('Create a candidate');
  };
  const openModal = () => {
    dispatch(openCandidateModal());
  };
  if (error) {
    return '';
  }

  return (
    <div>
      <div className="flex justify-between mt-3 border-b-black-shd1 pb-6">
        <div>
          <Typography className="font-bold">Candidates</Typography>
        </div>
        <div className="flex gap-4 items-center">
          <Button
            classNames="bg-primary text-white  border-none"
            onClick={openModal}
            iconUrl="/assets/icons/icon_plus.svg"
            iconClassNames="filter-white "
          >
            Create Candidates
          </Button>
        </div>
      </div>
      <div className="flex justify-between mb-6 border-b border-b-black-shd1 pb-6">
        <TabNavigation />
      </div>
      <Outlet />
      <BasicModal title={title} isOpen={isCandidateModalOpen} onClose={closeModal} closeOnOverlayClick={false}>
        {!selectedjobCreattionMethod ? (
          <article className="flex flex-wrap gap-10 justify-center items-center">
            <div className="w-[40%]">
              <BasicCard
                name={jobCreationMethods.form}
                onClick={onSelectJobMethodHandler}
                image="/assets/icons/complete_form.svg"
                title="Complete a form"
              />
            </div>
            <div className="w-[40%]">
              <BasicCard
                onChange={handleChangeTitle}
                onClick={onSelectJobMethodHandler}
                image="/assets/icons/upload_resume.svg"
                title="Upload a resume"
                name={jobCreationMethods.UploadResume}
              />
            </div>
            <div className="w-[40%]">
              <BasicCard
                onClick={onSelectJobMethodHandler}
                image="/assets/icons/linkedin_logo.svg"
                title="Export from LinkedIn"
                name={jobCreationMethods.linkedin}
              />
            </div>
            <div className="w-[40%]">
              <BasicCard
                onClick={onSelectJobMethodHandler}
                image="/assets/icons/csv_json.svg"
                title="Import a JSON /CSV file"
                name={jobCreationMethods.jsonOrCsv}
              />
            </div>
          </article>
        ) : (
          ''
        )}
        {selectedjobCreattionMethod === jobCreationMethods.form ? (
          <CreateNewCandidateForm closeModal={closeModal} refetch={refetchCandidates} />
        ) : (
          ''
        )}
        {selectedjobCreattionMethod === jobCreationMethods.UploadResume ? (
          <UploadResume refetch={refetchCandidates} closeModal={closeModal} />
        ) : (
          ''
        )}
        {selectedjobCreattionMethod === jobCreationMethods.jsonOrCsv ? (
          <JsonCsvFileUploader refetch={refetchCandidates} closeModal={closeModal} />
        ) : (
          ''
        )}
        {selectedjobCreattionMethod === jobCreationMethods.update ? (
          <CreateNewCandidateForm closeModal={closeModal} refetch={refetchCandidates} isEditing={true} />
        ) : (
          ''
        )}
      </BasicModal>
    </div>
  );
};

export default Candidates;
