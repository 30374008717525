import React from 'react';
import { JobSummary } from '../../molecules';
import { Link } from 'react-router-dom';
import { Loader, Typography } from 'modules/shared/components/atoms';

const convertUnixTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-indexed
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};
const calculateDaysAgo = (timestamp) => {
  const currentDate = new Date();
  const postDate = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const differenceInTime = currentDate - postDate;
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

const JobOverviewList = ({ jobs, refetchJobs, isLoading }) => {
  return (
    <article className="flex flex-col gap-5 shadow-lg rounded-md p-6">
      <section className="flex justify-between items-center">
        <div>
          <Typography htmlTag="h3" className="flex gap-2" variant="mainCardTitle">
            Job Overview
          </Typography>
        </div>
        <div>
          <Link to="/dashboard/jobs" className="text-sm text-primary">
            view all
          </Link>
        </div>
      </section>
      <section className="flex gap-4 flex-col">
        {isLoading ? (
          <Loader />
        ) : (
          jobs.map((job) => (
            <div className="p-2 border-b border-b-black-shd1" key={job.id}>
              <JobSummary
                avatarUrl={job.avatarUrl?.image}
                position={job.name}
                category={job?.category}
                date={convertUnixTimestamp(job.created_on)}
                datePostedAge={`Posted ${calculateDaysAgo(job.created_on)} days ago`}
                alt={job.name}
                refetchJobs={refetchJobs}
                jobId={job.jobID}
              />
            </div>
          ))
        )}
      </section>
    </article>
  );
};

export default JobOverviewList;
