import { Button, Typography } from 'modules/shared/components/atoms';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EmptyStatsCardPlaceholder = ({ title, overview, describtion, actionText, actionUrl, onClick }) => {
  const navigate = useNavigate();

  const showActionButton = actionText && actionUrl;
  return (
    <div className="flex flex-col shadow-lg rounded-md p-6">
      <Typography htmlTag="h3" className="flex mb-4 tracking-widest" variant="mainCardTitle">
        {title}
      </Typography>
      <div className="flex flex-grow flex-col gap-4 items-center justify-center">
        <div>
          <Typography className="font-semibold tracking-wider text-base">{describtion}</Typography>
          <Typography className=" font-normal text-base text-center">{overview}</Typography>
        </div>
        {showActionButton && (
          <Button
            type="button"
            classNames={'border-primary text-primary hover:bg-primary hover:text-white font-semibold tracking-wide'}
            iconClassNames={'filter-none'}
            iconUrl={'/assets/icons/plus_icon.svg'}
            onClick={() => {
              if (onClick) {
                onClick();
                navigate(actionUrl);
              }
            }}
          >
            {actionText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyStatsCardPlaceholder;
