import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, TextInput, Typography } from 'modules/shared/components/atoms';

const validationSchema = Yup.object({
  placement_name: Yup.string().required('Folder Name is required'),
  placement_max_number: Yup.number().nullable().typeError('Maximum Allowed Candidates must be a number'),
});

const CreatePlacementFolder = ({ handleFormSubmit, closeModal, isUpdate, placementData }) => {
  return (
    <Formik
      initialValues={{
        placement_name: placementData ? placementData.placement_name : '',
        placement_max_number: placementData ? placementData.placement_max_number : null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleFormSubmit(values);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
          <div className={isUpdate ? 'hidden' : ''}>
            <TextInput
              label="Folder Name"
              name="placement_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.placement_name}
              error={touched.placement_name && errors.placement_name}
              classNames="bg-white py-3 border"
            />
            {touched.placement_name && errors.placement_name && (
              <Typography variant="errorText" className="text-error">
                {errors.placement_name}
              </Typography>
            )}
          </div>

          <div>
            <TextInput
              label="Maximum Allowed candidates (optional)"
              name="placement_max_number"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.placement_max_number}
              error={touched.placement_max_number && errors.placement_max_number}
              classNames="bg-white py-3 border"
            />
            {touched.placement_max_number && errors.placement_max_number && (
              <Typography variant="errorText" className="text-error">
                {errors.placement_max_number}
              </Typography>
            )}
          </div>

          <div className="flex place-content-end">
            <div className={`flex items-center px-4 cursor-pointer `} onClick={closeModal}>
              <Typography variant="body">Cancel</Typography>
            </div>
            <Button type="submit" classNames="bg-primary text-white border-none">
              Next
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CreatePlacementFolder;
