export const jobCategories = [
  { key: 'ACCOUNTING_FINANCE', value: 'Accounting & Finance' },
  { key: 'ADMINISTRATION_OFFICE_SUPPORT', value: 'Administration & Office Support' },
  { key: 'ADVERTISING_MARKETING_PUBLIC_RELATIONS', value: 'Advertising Marketing & Public Relations' },
  { key: 'ARCHITECTURE_ENGINEERING', value: 'Architecture & Engineering' },
  { key: 'ARTS_DESIGN_CREATIVE', value: 'Arts Design & Creative' },
  { key: 'CUSTOMER_SERVICE', value: 'Customer Service' },
  { key: 'EDUCATION_TRAINING', value: 'Education & Training' },
  { key: 'HEALTHCARE_MEDICAL', value: 'Healthcare & Medical' },
  { key: 'HOSPITALITY_TOURISM', value: 'Hospitality & Tourism' },
  { key: 'HUMAN_RESOURCES', value: 'Human Resources' },
  { key: 'INFORMATION_TECHNOLOGY', value: 'Information Technology' },
  { key: 'LEGAL', value: 'Legal' },
  { key: 'MANUFACTURING_PRODUCTION', value: 'Manufacturing & Production' },
  { key: 'MEDIA_COMMUNICATIONS', value: 'Media & Communications' },
  { key: 'RETAIL_SALES', value: 'Retail & Sales' },
  { key: 'SCIENCE_RESEARCH', value: 'Science & Research' },
  { key: 'SKILLED_TRADES', value: 'Skilled Trades' },
  { key: 'TRANSPORT_LOGISTICS', value: 'Transport & Logistics' },
  { key: 'CONSTRUCTION_REAL_ESTATE', value: 'Construction & Real Estate' },
  { key: 'GOVERNMENT_PUBLIC_ADMINISTRATION', value: 'Government & Public Administration' },
  { key: 'SECURITY_PROTECTIVE_SERVICES', value: 'Security & Protective Services' },
  { key: 'AGRICULTURE_FARMING', value: 'Agriculture & Farming' },
  { key: 'ENERGY_UTILITIES', value: 'Energy & Utilities' },
  { key: 'SOCIAL_SERVICES_NON_PROFIT', value: 'Social Services & Non-Profit' },
  { key: 'TRANSPORTATION_AVIATION', value: 'Transportation & Aviation' },
  { key: 'FASHION_BEAUTY', value: 'Fashion & Beauty' },
  { key: 'FOOD_BEVERAGE', value: 'Food & Beverage' },
  { key: 'TELECOMMUNICATIONS', value: 'Telecommunications' },
  { key: 'ENVIRONMENTAL_SERVICES', value: 'Environmental Services' },
  { key: 'ENTERTAINMENT_SPORTS', value: 'Entertainment & Sports' },
  { key: 'AGRIBUSINESS', value: 'Agribusiness' },
  { key: 'AUTOMOTIVE', value: 'Automotive' },
  { key: 'AEROSPACE', value: 'Aerospace' },
  { key: 'BIOTECHNOLOGY_PHARMACEUTICALS', value: 'Biotechnology & Pharmaceuticals' },
  { key: 'CULTURAL_HERITAGE', value: 'Cultural & Heritage' },
  { key: 'MINING_RESOURCES', value: 'Mining & Resources' },
  { key: 'MILITARY_DEFENSE', value: 'Military & Defense' },
  { key: 'TELEVISION_FILM', value: 'Television & Film' },
  { key: 'PETS_ANIMAL_CARE', value: 'Pets & Animal Care' },
  { key: 'FREELANCE_GIG_ECONOMY', value: 'Freelance & Gig Economy' },
  { key: 'SUPPLY_CHAIN_PROCUREMENT', value: 'Supply Chain & Procurement' },
  { key: 'ETHICS_COMPLIANCE', value: 'Ethics & Compliance' },
  { key: 'PERSONAL_CARE_SERVICES', value: 'Personal Care & Services' },
  { key: 'INSURANCE', value: 'Insurance' },
  { key: 'RETAIL_E_COMMERCE', value: 'Retail & E-commerce' },
  { key: 'EVENT_PLANNING_COORDINATION', value: 'Event Planning & Coordination' },
  { key: 'SCIENTIFIC_RESEARCH_DEVELOPMENT', value: 'Scientific Research & Development' },
  { key: 'HUMANITARIAN_DISASTER_RELIEF', value: 'Humanitarian & Disaster Relief' },
  { key: 'URBAN_PLANNING_DEVELOPMENT', value: 'Urban Planning & Development' },
  { key: 'RENEWABLE_ENERGY_SUSTAINABILITY', value: 'Renewable Energy & Sustainability' },
  { key: 'CONSULTING', value: 'Consulting' },
  { key: 'TRANSPORTATION_INFRASTRUCTURE', value: 'Transportation & Infrastructure' },
  { key: 'PUBLISHING_WRITING', value: 'Publishing & Writing' },
  { key: 'EDUCATION_TECHNOLOGY', value: 'Education Technology' },
  { key: 'HEALTHCARE_ADMINISTRATION', value: 'Healthcare Administration' },
  { key: 'HOSPITALITY_MANAGEMENT', value: 'Hospitality Management' },
  { key: 'INDUSTRIAL_DESIGN', value: 'Industrial Design' },
  { key: 'REAL_ESTATE_DEVELOPMENT', value: 'Real Estate Development' },
  { key: 'WASTE_MANAGEMENT_RECYCLING', value: 'Waste Management & Recycling' },
  { key: 'OTHER', value: 'Other' },
];
