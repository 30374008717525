import React, { useState } from 'react';
import { Button, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import { AddNewTag } from '..';

const initialTagsData = [
  {
    id: 1,
    name: 'Above budget',
  },
  {
    id: 2,
    name: 'Accepted another offer',
  },
  {
    id: 3,
    name: 'Cultural fit ',
  },
  {
    id: 4,
    name: 'Did not attend the interview',
  },
  {
    id: 5,
    name: 'Not available',
  },
  {
    id: 6,
    name: 'Not qualified',
  },
  {
    id: 7,
    name: 'Other',
  },
  {
    id: 8,
    name: 'Overqualified',
  },
  {
    id: 9,
    name: 'Reference check failed',
  },
  {
    id: 10,
    name: 'Rejected the offer',
  },
  {
    id: 11,
    name: 'Technical test failed',
  },
  {
    id: 12,
    name: 'Unresponsive',
  },
];
const DropReasons = () => {
  const [tagsData, setTagsData] = useState(initialTagsData);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const closeModal = () => {
    setIsModalOpened(false);
    setSelectedTag(null);
  };
  const openModal = () => {
    setIsModalOpened(true);
  };

  // const handleEditClick = (tag) => {
  //   setSelectedTag(tag);
  //   openModal();
  // };

  return (
    <div className="py-3 grid gap-4">
      <div className="flex justify-between">
        <Typography variant="mainCardTitle">Drop Reasons</Typography>
        <Button onClick={openModal} classNames="bg-primary px-4 py-2 tracking-widest text-white border-none">
          add a new tag
        </Button>
      </div>
      <article className="grid gap-3 shadow-lg px-10 py-8">
        {tagsData.map((tag) => (
          <div key={tag.id} className="flex justify-between bg-white-100 px-4 py-2.5 rounded-[4px]">
            <span className="text-neutral-100 text-base tracking-widest">{tag.name}</span>
            <div className="flex gap-4">
              <img src="/assets/icons/folder_edit2.svg" className="cursor-pointer filter-black opacity-70" alt="" />
              <img src="/assets/icons/folder_delete2.svg" className="cursor-pointer filter-black opacity-70" alt="" />
            </div>
          </div>
        ))}
      </article>
      <BasicModal
        title={selectedTag ? 'Edit Tag' : 'Add a Tag'}
        isOpen={isModalOpened}
        onClose={closeModal}
        closeOnOverlayClick={false}
      >
        <AddNewTag closeModal={closeModal} selectedTag={selectedTag} />{' '}
      </BasicModal>
    </div>
  );
};

export default DropReasons;
