import { Button } from 'modules/shared/components/atoms';
import React from 'react';

const JobCard = ({ imageUrl, title, type, desc }) => {
  return (
    <div className="">
      <div className="shadow-sm bg-white rounded-md">
        <div className="flex flex-col">
          <img className="" src={imageUrl} alt="img" />
        </div>
        <div className="px-5">
          <div className="tracking-wider py-3 border-b border-gray">
            <p className="text-base font-semibold">{title}</p>
            <span className="text-sm text-gray">{type}</span>
            <p className="text-sm text-gray mt-4">{desc}</p>
          </div>
          <Button
            classNames="bg-white text-primary hover:bg-white hover:text-primary  border-none text-sm mx-auto"
            iconClassNames="filter-white "
          >
            Enable
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
