import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

const TextInputCounter = ({
  name,
  label,
  error,
  value,
  onChange,
  onBlur,
  classNames,
  initialValue = '',
  MaximumLength = 250,
}) => {
  const [count, setCount] = useState(initialValue.length);
  // const [initialValue, setInitialValue] = useState(value);
  const errorClassNames = error ? 'border-error' : '';
  const [isFocused, setIsFocused] = useState(false);
  const defaultButtonClass = twMerge(
    `peer relative w-full h-full border-neutral-100 bg-transparent text-left outline-0 focus:outline-0 transition-all border text-sm px-3 rounded-[7px]`,
    error ? errorClassNames : '',

    classNames,
  );
  const isListOpened = twMerge(
    `peer w-full h-full bg-transparent text-left outline outline-0 focus:outline-0 transition-all border-2 border-primary text-sm px-3  rounded-[7px] ${
      error ? 'border-text-error' : ''
    } border-primary border-t-transparent`,
    value ? 'border' : '',
  );
  const defaultLabelClass = twMerge(
    `flex w-full h-full select-none pointer-events-none absolute left-0 transition-all -top-1.5 before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all  after:content[' '] after:block after:flex-grow  after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 after:rounded-tr-md after:pointer-events-none after:transition-all  text-sm  leading-[4.5]`,
    error ? 'border-error ' : '',
  );

  const clicked = twMerge(
    `flex w-full h-full select-none pointer-events-none absolute left-0  transition-all -top-1.5 before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all  after:content[' '] after:block after:flex-grow  after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 after:rounded-tr-md after:pointer-events-none after:transition-all  text-[11px] focus:before:border-t-[2px] focus:after:border-t-[2px] focus:after:border-r-[2px] focus:before:border-l-[2px] focus:before:border-primary 
before:border-t-2 after:border-t-2 after:border-r-2 before:border-l-2 before:border-primary leading-tight before:border-neutral-100 after:border-primary`,
    value ? 'before:border-primary after:border-t after:border-r before:border-l before:border-t border-primary' : '',
    error ? 'border-error' : '',
  );
  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="relative w-full min-w-[200px] h-11 font-poppins">
      <input
        name={name}
        value={value}
        type="text"
        className={isFocused || value ? isListOpened : defaultButtonClass}
        onChange={(e) => {
          onChange(e);
          setCount(e.target.value.length);
        }}
        onFocus={handleInputFocus}
        onBlur={(e) => {
          handleInputBlur(e);
          onBlur(e);
        }}
      />
      <label className={isFocused || value ? clicked : defaultLabelClass}>{label}</label>
      <span className="absolute h-full top-0 flex items-center right-4 ">
        {count}/{MaximumLength}
      </span>
    </div>
  );
};

export default TextInputCounter;
