const calculateCompletionStatus = (percentage) => {
  if (percentage >= 0 && percentage < 50) {
    return 'warning';
  } else if (percentage >= 50 && percentage < 90) {
    return 'info';
  } else if (percentage >= 90) {
    return 'success';
  }
  return '';
};

export default calculateCompletionStatus;
