import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, TextInputCounter, Typography } from 'modules/shared/components/atoms';
import {
  useCreateFolderMutation,
  useFetchFolderByIdQuery,
  useUpdateFolderMutation,
  useFetchFoldersQuery,
} from 'features/candidatesFoldersSlice';
import { CustomSelect } from 'modules/shared/components/molecules';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// Define validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string().required('Folder name is required'),
  candidates_ids: Yup.array().min(1, 'At least one option is required').required('Option is required'),
  description: Yup.string(),
  is_favorite: Yup.boolean(),
});

// Define initial form values

const AddNewFolder = ({ candidatesData, closeModal, isEditing, folderId }) => {
  const [createFolderMutation, { isLoading: isCreating }] = useCreateFolderMutation();
  const shouldFetch = folderId ? folderId : null;
  const {
    data: folderData,
    isLoading: isFolderLoading,
    refetch: refetchFolder,
  } = useFetchFolderByIdQuery(folderId, {
    skip: !shouldFetch,
  });
  const [updateFolder, { isLoading: isUpdating }] = useUpdateFolderMutation();
  const { refetch: getFoldersList } = useFetchFoldersQuery({ page: 1, limit: 20 });

  const onSubmit = async (values) => {
    try {
      if (isEditing) {
        await updateFolder({ folderId, data: values }).unwrap();
        await getFoldersList();
        await refetchFolder();
        toast.success('Folder updated successfully');
      } else {
        await createFolderMutation(values).unwrap();
        await getFoldersList();
        toast.success('Folder created successfully');
      }
      closeModal();
    } catch (error) {
      const errorMessage = error?.data?.message || 'An error occurred. Please try again.';
      toast.error(errorMessage);
    }
  };

  if (isFolderLoading) {
    <div>Loading...</div>;
  } else {
    const folderCandidatesList = folderData ? folderData.candidates_list : [];
    const initialValues = {
      name: folderData ? folderData.name : '',
      candidates_ids: folderCandidatesList ? folderCandidatesList.map((candidate) => candidate.appID) : [],
      description: folderData ? folderData.description : '',
      is_favorite: folderData ? folderData.is_favorite : false,
    };

    const options = candidatesData
      ? candidatesData.applicants.map((candidate) => ({
          label: `${candidate.first_name} ${candidate.last_name}`,
          value: candidate.appID,
        }))
      : '';
    const selectedOptions = folderCandidatesList.map((candidate) => ({
      label: `${candidate.first_name} ${candidate.last_name}`,
      value: candidate.appID,
    }));
    return (
      <div className="w-full p-2 rounded-md ">
        <Formik
          onSubmit={onSubmit}
          validateOnChange
          validateOnBlur
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, errors, setFieldValue, handleChange, handleBlur, touched, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
              <Field name="name">
                {({ field }) => (
                  <div>
                    <TextInputCounter
                      classNames={`bg-white py-3 border ${touched.name && errors.name ? ' border-error ' : ''}`}
                      label="Folder name"
                      name="name"
                      type="text"
                      error={touched.name && errors.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <Typography variant="errorText">
                      {touched.name && errors.name ? <div className="text-error">{errors.name}</div> : null}
                    </Typography>
                  </div>
                )}
              </Field>

              <div>
                <CustomSelect
                  error={errors.candidates_ids && touched.candidates_ids}
                  option={options}
                  placeholder={'Add candidates'}
                  label="Select candidates *"
                  value={selectedOptions}
                  onBlur={handleBlur}
                  selectDefaultValue={selectedOptions}
                  onChange={(selectedOptions) =>
                    setFieldValue(
                      'candidates_ids',
                      selectedOptions.map((option) => option.value),
                    )
                  }
                />
                <Typography variant="errorText">
                  {errors.candidates_ids && touched.candidates_ids ? errors.candidates_ids : ''}
                </Typography>
              </div>
              <div className="">
                <TextInputCounter
                  name="description"
                  type="text"
                  label="Description (optional)"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
              </div>
              <div className="flex text-center my-2">
                <Field type="checkbox" name="is_favorite">
                  {({ field }) => (
                    <label className="flex items-center cursor-pointer">
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={() => setFieldValue('is_favorite', !field.value)}
                      />
                      <span className="text-base ml-2">
                        <span className="text-primary">Favorite?</span>
                      </span>
                    </label>
                  )}
                </Field>
              </div>
              <Button
                isLoading={isCreating || isUpdating}
                disabled={isCreating || isUpdating}
                type="submit"
                classNames="flex mx-auto bg-primary text-white border-none w-4/6"
              >
                {isEditing ? 'Update' : 'Add'}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};

export default AddNewFolder;
