import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

const SearchInput = ({
  type = 'text',
  name = '',
  label = '',
  error = false,
  centerdLabel = false,
  classNames = '',
}) => {
  const [inputValue, setInputValue] = useState('');
  const inputClassNames = error
    ? 'focus:border-error border-2 border-error'
    : 'border-2 border-transparent focus:border-primary';
  const labelClassNames = error ? 'text-error' : 'text-neutral-100';
  const isCenteredLabel = centerdLabel ? 'text-center' : '';
  return (
    <div className="relative">
      <input
        type={type}
        id={name}
        name={name}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder=""
        className={twMerge(
          `block w-full bg-white-100  focus:outline-none focus:shadow-outline  rounded-md py-3 px-6  appearance-none focus:border-2  ${inputClassNames} ${classNames}`,
        )}
      />
      {!inputValue && (
        <label
          htmlFor={name}
          className={` flex absolute top-3 left-8 tracking-widest  pointer-events-none transition duration-200 ease-in-outbg-white px-2 ${labelClassNames} ${isCenteredLabel}`}
        >
          <img className="mr-3 " src="/assets/icons/search_icon.svg" alt="" />
          {label}
        </label>
      )}
    </div>
  );
};

export default SearchInput;
