import React from 'react';
import DataTable from 'react-data-table-component';

const JobCandidatesDetails = () => {
  const candidates = [
    {
      name: 'Jasmine Doe',
      contacted: 'YES',
      interviewScheduled: 'YES',
      hrInterview: 'Completed',
      technicalInterview: 'Completed',
    },
    {
      name: 'Jasmine Doe',
      contacted: 'NO',
      interviewScheduled: 'NO',
      hrInterview: 'Cancelled',
      technicalInterview: 'Cancelled',
    },
    {
      name: 'Jasmine Doe',
      contacted: 'YES',
      interviewScheduled: 'YES',
      hrInterview: 'Waiting',
      technicalInterview: 'Waiting',
    },
    {
      name: 'Jasmine Doe',
      contacted: 'NO',
      interviewScheduled: 'NO',
      hrInterview: 'Cancelled',
      technicalInterview: 'Cancelled',
    },
    {
      name: 'Jasmine Doe',
      contacted: 'YES',
      interviewScheduled: 'YES',
      hrInterview: 'Completed',
      technicalInterview: 'Waiting',
    },
    {
      name: 'Jasmine Doe',
      contacted: 'YES',
      interviewScheduled: 'YES',
      hrInterview: 'Waiting',
      technicalInterview: 'Waiting',
    },
    {
      name: 'Jasmine Doe',
      contacted: 'NO',
      interviewScheduled: 'NO',
      hrInterview: 'Cancelled',
      technicalInterview: 'Cancelled',
    },
    {
      name: 'Jasmine Doe',
      contacted: 'NO',
      interviewScheduled: 'NO',
      hrInterview: 'Cancelled',
      technicalInterview: 'Cancelled',
    },
  ];

  const customStyle = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    headRow: {
      style: {
        backgroundColor: '',
        borderBottomColor: '#EDEDED',
        borderBottomWidth: '1px',
      },
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '0.4px',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        minHeight: '56px ',
        color: '#6B6B6B',
        letterSpacing: '0.5px',
        '&:hover': {
          backgroundColor: '#DAECEF !important', // Background color for focused row
        },
      },
    },
    tableWrapper: {
      style: {
        border: '2px solid rgb(229 231 235)',
        borderRadius: '1rem',
        overflow: 'hidden',
      },
    },
  };

  const renderStatus = (status) => {
    let color;
    switch (status) {
      case 'Completed':
        color = 'bg-[#F0F9FE] text-[#64BFF2] rounded-sm w-32 text-center';
        break;
      case 'Cancelled':
        color = 'bg-error bg-opacity-10 text-error rounded-sm w-32 text-center';
        break;
      case 'Waiting':
        color = 'bg-[#FEFDED]  text-[#C7C36B] rounded-sm w-32 text-center';
        break;
      default:
        color = '';
    }
    return <span className={`px-2 py-1 rounded ${color}`}>{status}</span>;
  };

  const columns = [
    {
      name: 'Selected Candidates',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Contacted',
      selector: (row) => row.contacted,
      sortable: true,
    },
    {
      name: 'Interview Scheduled',
      selector: (row) => row.interviewScheduled,
      sortable: true,
    },
    {
      name: 'HR Interviews',
      selector: (row) => renderStatus(row.hrInterview),
      sortable: true,
      cell: (row) => renderStatus(row.hrInterview),
    },
    {
      name: 'Technical Interviews',
      selector: (row) => renderStatus(row.technicalInterview),
      sortable: true,
      cell: (row) => renderStatus(row.technicalInterview),
    },
  ];

  return (
    <div className="overflow-x-auto">
      <DataTable columns={columns} data={candidates} highlightOnHover customStyles={customStyle} />
    </div>
  );
};

export default JobCandidatesDetails;
