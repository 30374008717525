import React from 'react';
import { SignupForm } from '../../components/organisms';

const Signup = () => {
  return (
    <div>
      <SignupForm />
    </div>
  );
};

export default Signup;
