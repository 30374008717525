import React from 'react';
import DataTable from 'react-data-table-component';
import { Avatar } from 'modules/homepage/components/atoms';
import { Badge, Typography } from 'modules/shared/components/atoms';

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400px',
      fontSize: ' 14px',
    },
  },
};

const data = [
  {
    id: 1,
    displayName: 'Sample - Software Engineer',
    fullName: 'Software Engineer',
    emailAddress: 'john.doe@example.com',
    role: 'Admin',
    status: 'Active',
    createdDate: '2023-01-01',
    lastActiveDate: '2023-11-02 (9 days ago)',
  },
  {
    id: 2,
    displayName: 'Sample - Software Engineer',
    fullName: 'Software Engineer',
    emailAddress: 'john.doe@example.com',
    role: 'Admin',
    status: 'Active',
    createdDate: '2023-01-01',
    lastActiveDate: '2023-11-02 (9 days ago)',
  },
  {
    id: 3,
    displayName: 'Sample - Software Engineer',
    fullName: 'Software Engineer',
    emailAddress: 'john.doe@example.com',
    role: 'Admin',
    status: 'Active',
    createdDate: '2023-01-01',
    lastActiveDate: '2023-11-02 (9 days ago)',
  },
  {
    id: 4,
    displayName: 'Sample - Software Engineer',
    fullName: 'Software Engineer',
    emailAddress: 'john.doe@example.com',
    role: 'Admin',
    status: 'Active',
    createdDate: '2023-01-01',
    lastActiveDate: '2023-11-02 (9 days ago)',
  },
];

const JobTemplates = () => {
  const handleDelete = (id) => {};
  const columns = [
    {
      name: 'Template name',
      selector: 'displayName',
      sortable: true,
      //   width: '150px',
    },
    {
      name: 'Position name',
      selector: 'fullName',
      sortable: true,
    },
    {
      name: 'Client',
      selector: 'emailAddress',
      sortable: true,
    },
    {
      name: 'Created at',
      selector: 'lastActiveDate',
      sortable: true,
    },
    {
      button: true,
      cell: (row) => (
        <img
          src="/assets/icons/red_trash.svg"
          className="cursor-pointer"
          alt="delete"
          onClick={() => handleDelete(row.id)}
        />
      ),
    },
  ];

  return (
    <div className="grid gap-3">
      <Typography variant="mainCardTitle">Job templates</Typography>
      <DataTable columns={columns} data={data} customStyles={customStyles} />
    </div>
  );
};

export default JobTemplates;
