import { useDeleteFolderMutation, useFetchFoldersQuery } from 'features/candidatesFoldersSlice';
import { BasicModal } from 'modules/shared/components/molecules';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { AddNewFolder } from 'modules/candidates/components/organisms';
import Loader from 'modules/shared/components/atoms/Loader';
import { twMerge } from 'tailwind-merge';

const CategorizedCandidatesFolder = ({
  title,
  numberOfCandidates,
  owner,
  avater,
  isFavorite,
  folderId,
  candidatesData,
}) => {
  const iconPath = isFavorite ? '/assets/icons/favourite_star.svg' : '/assets/icons/star_icon2.svg';
  const [isModalOpended, setIsModalOpended] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [deleteFolder, { isLoading, isSuccess, isError, error }] = useDeleteFolderMutation();
  const { refetch: getFoldersList } = useFetchFoldersQuery({ page: 1, limit: 20 });
  const handleDelete = async (e) => {
    const currentFolderId = e.currentTarget.dataset.folderId;
    setCurrentFolderId(currentFolderId);
    try {
      await deleteFolder(folderId).unwrap();
      getFoldersList();
      toast.success('Folder deleted successfully');
      setCurrentFolderId(null);
    } catch (error) {
      const {
        data: { message },
      } = error;
      toast.error(message);
      setCurrentFolderId(null);
    }
  };

  const closeModal = () => {
    setIsModalOpended(false);
  };
  const openModal = () => {
    setIsModalOpended(true);
  };
  const isCurrentFolderId = currentFolderId === folderId;

  return (
    <>
      <div className="grid grid-cols-6  relative ">
        <div
          className={twMerge(
            'flex justify-start ml-10 col-start-1 col-span-3  h-8 gap-6',
            isCurrentFolderId ? 'opacity-50 pointer-events-none' : '',
          )}
        >
          <img src={iconPath} alt="icon" />
          <div className=" text-sm tracking-widest">
            <p>{title}</p>
            <p className="text-gray">{numberOfCandidates} candidates</p>
          </div>
        </div>
        <div className="col-span-3 col-start-5  grid grid-cols-3 items-center h-8 ">
          <div className="flex gap-4 col-span-2 col-start- items-center">
            {avater ? (
              <img className="w-8 h-8 rounded-full" src={avater || '/assets/icons/folder_placeholder.png'} alt="" />
            ) : (
              <span className="w-8 h-8 rounded-full bg-primary text-white pt-1 text-center">{owner[0]}</span>
            )}
            <span>{owner}</span>
          </div>
          <div className="flex gap-8 left-20 col-span-1">
            <img src="/assets/icons/folder_edit2.svg" alt="" className="cursor-pointer" onClick={openModal} />
            {isCurrentFolderId ? <Loader size="sm" /> : ''}
            {!isCurrentFolderId ? (
              <img
                className="cursor-pointer w-4"
                src="/assets/icons/folder_delete.svg"
                data-folder-id={folderId}
                alt=""
                onClick={handleDelete}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <BasicModal
          isOpen={isModalOpended}
          onClose={closeModal}
          title={`Edit ${title} folder`}
          closeOnOverlayClick={false}
        >
          <AddNewFolder candidatesData={candidatesData} closeModal={closeModal} isEditing={true} folderId={folderId} />
        </BasicModal>
      </div>
    </>
  );
};

export default CategorizedCandidatesFolder;
