// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quill {
}
.ql-editor {
  postion: relative;
  min-height: 300px;
}

.ql-toolbar.ql-snow {
  postion: absolute;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/modules/shared/components/atoms/RichText/style.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,SAAS;AACX","sourcesContent":[".quill {\n}\n.ql-editor {\n  postion: relative;\n  min-height: 300px;\n}\n\n.ql-toolbar.ql-snow {\n  postion: absolute;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
