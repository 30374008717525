import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, Select, TextInput, TextInputCounter, Typography } from 'modules/shared/components/atoms';
import PhoneInput from 'react-phone-number-input';
import { twMerge } from 'tailwind-merge';
import { ImageUploader } from 'modules/shared/components/molecules';
import {
  useCreateCandidateMutation,
  useGetCandidateByIdQuery,
  useUpdateCandidateMutation,
} from 'features/candidatesSlice';
import { toast } from 'react-toastify';
import './style.css';
import { jobCategories } from 'modules/jobs/data';
import { candidatesDegrees } from 'modules/candidates/data';
import { Loader } from 'modules/shared/components/atoms';
const positionTypeOptions = [
  { value: 'Full-Time', key: 'Full Time' },
  { value: 'Part-Time', key: 'Part Time' },
  { value: 'Contract', key: 'Contract' },
  { value: 'Temporary', key: 'Temporary' },
];
const validationSchema = Yup.object({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  location: Yup.string().required('Location is required'),
  notice_period: Yup.number()
    .typeError('Notice period must be a number')
    .positive('Notice period must be positive')
    .integer('Notice period must be a whole number')
    .required('Notice period is required'),
  resume_summary: Yup.string(),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().nullable(),
  current_salary: Yup.number().positive('Current salary must be positive'),
  expected_salary: Yup.number().positive('Expected salary must be positive'),
  experience: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Title is required'),
      position_type: Yup.string().required('Position type is required'),
      company_name: Yup.string().required('Company name is required'),
      company_location: Yup.string().required('Company location is required'),
      start_date: Yup.string()
        .required('Start date is required')
        .matches(/^\d{4}-(0[1-9]|1[0-2])$/, 'Start date must be in YYYY-MM format')
        .test('is-not-future-date', 'Start date cannot be in the future', (value) => {
          const today = new Date();
          const start = new Date(value + '-01');
          return start <= today; // Ensure start date is not in the future
        }),
      end_date: Yup.string()
        .nullable()
        .matches(/^\d{4}-(0[1-9]|1[0-2])$/, 'End date must be in YYYY-MM format')
        .when('start_date', (start_date, schema) => {
          return start_date
            ? schema.test('is-after-start', 'End date must be after start date', function (end_date) {
                if (!end_date) return true; // If end_date is null, it's valid
                const start = new Date(start_date + '-01');
                const end = new Date(end_date + '-01');
                return end > start;
              })
            : schema;
        }),
    }),
  ),
  linkedin_url: Yup.string()
    .nullable()
    .url('Must be a valid LinkedIn URL')
    .matches(/^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/, 'Must be a valid LinkedIn URL'),
  social_links: Yup.array().of(
    Yup.string()
      .url('Must be a valid URL')
      .matches(
        /^(https?:\/\/)?(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
        'Must be a valid link',
      ),
  ),
  certs: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
      issuer: Yup.string(),
      issue_date: Yup.string()
        .nullable()
        .matches(/^\d{4}-(0[1-9]|1[0-2])$/, 'Issue date must be in YYYY-MM format')
        .test('is-not-future-date', 'Issue date cannot be in the future', (value) => {
          if (!value) return true;
          const today = new Date();
          const issueDate = new Date(value + '-01');
          return issueDate <= today;
        }),
      credential: Yup.string(),
    }),
  ),
  education: Yup.array().of(
    Yup.object().shape({
      institute: Yup.string().required('Institute is required'),
      degree: Yup.string().required('Degree is required'),
      start_date: Yup.string()
        .required('Start date is required')
        .matches(/^\d{4}-(0[1-9]|1[0-2])$/, 'Start date must be in YYYY-MM format')
        .test('is-not-future-date', 'Start date cannot be in the future', (value) => {
          const today = new Date();
          const start = new Date(value + '-01');
          return start <= today; // Ensure start date is not in the future
        }),
      end_date: Yup.string()
        .nullable()
        .matches(/^\d{4}-(0[1-9]|1[0-2])$/, 'End date must be in YYYY-MM format')
        .when('start_date', (start_date, schema) => {
          return start_date
            ? schema.test('is-after-start', 'End date must be after start date', function (end_date) {
                if (!end_date) return true; // If end_date is null, it's valid
                const start = new Date(start_date + '-01');
                const end = new Date(end_date + '-01');
                return end > start;
              })
            : schema;
        }),
    }),
  ),
  skills: Yup.array().of(Yup.string()).required('At least one skill is required'),
  category: Yup.string().required('Candidate category is required'),
});

const CreateNewCandidateForm = ({ closeModal, refetch, isEditing = false, candidateId }) => {
  const [createCandidate, { isLoading: isCreating }] = useCreateCandidateMutation();
  const [updateCandidate, { isLoading: isUpdating }] = useUpdateCandidateMutation();
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [newSkill, setNewSkill] = useState('');
  const positionTypeRef = useRef(null);
  const categoryRef = useRef(null);
  const degreeRef = useRef(null);

  const shouldFetch = candidateId ? candidateId : null;
  const {
    data: candidateData,
    isLoading: isCandidateLoading,
    refetch: refetchCandidate,
  } = useGetCandidateByIdQuery(candidateId, {
    skip: !shouldFetch,
  });

  const handleFormSubmission = async (values) => {
    try {
      if (isEditing) {
        await updateCandidate({ candidateId, data: values }).unwrap();
        toast.success('Candidate updated successfully');
        refetch();
        refetchCandidate();
      } else {
        await createCandidate(values).unwrap();
        toast.success('Candidate created successfully');
      }
      refetch();
      closeModal();
    } catch (err) {
      const {
        data: { message },
      } = err;
      toast.error(message);
    }
  };
  if (isCandidateLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const initialValues = {
    image: null,
    first_name: candidateData ? candidateData[0]?.first_name : '',
    last_name: candidateData ? candidateData[0]?.last_name : '',
    location: candidateData ? candidateData[0]?.location : '',
    notice_period: candidateData ? candidateData[0]?.notice_period : '',
    resume_summary: candidateData ? candidateData[0]?.resume_summary : '',
    email: candidateData ? candidateData[0]?.email : '',
    phone: candidateData ? candidateData[0]?.phone : '',
    current_salary: candidateData ? candidateData[0]?.current_salary : '',
    expected_salary: candidateData ? candidateData[0]?.expected_salary : '',
    experience: candidateData
      ? candidateData[0]?.experience || [
          { title: '', position_type: '', company_name: '', company_location: '', start_date: '', end_date: '' },
        ]
      : [{ title: '', position_type: '', company_name: '', company_location: '', start_date: '', end_date: null }],
    linkedin_url: candidateData ? candidateData[0]?.linkedin_url : '',
    social_links: candidateData ? candidateData[0]?.social_links || [] : [],
    certs: candidateData
      ? candidateData[0]?.certs || [{ title: '', issuer: '', issue_date: '', credential: '' }]
      : [{ title: '', issuer: '', issue_date: null, credential: '' }],
    education: candidateData
      ? candidateData[0]?.education || [{ institute: '', degree: '', start_date: '', end_date: '' }]
      : [{ institute: '', degree: '', start_date: '', end_date: null }],
    skills: candidateData ? candidateData[0]?.skills || [] : [],
    category: candidateData ? candidateData[0]?.category : '',
    created_on: candidateData ? candidateData[0]?.created_on : '',
  };

  return (
    <div className="w-full p-2 rounded-md">
      <Formik
        onSubmit={handleFormSubmission}
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, errors, setFieldValue, handleChange, handleBlur, touched, handleSubmit }) => {
          const handleAddSkill = () => {
            if (newSkill && !values.skills.includes(newSkill)) {
              setFieldValue('skills', [...values.skills, newSkill]);
              setNewSkill(''); // Clear input after adding
            }
          };

          const handleRemoveSkill = (index) => {
            const updatedSkills = values.skills.filter((_, i) => i !== index);
            setFieldValue('skills', updatedSkills);
          };

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
              <ImageUploader onImageChange={(file) => setFieldValue('image', file)} />
              {/* First Name */}
              <div>
                <TextInputCounter
                  classNames={`bg-white py-3${touched.first_name && errors.first_name ? ' border-error' : ''}`}
                  label="First name"
                  name="first_name"
                  type="text"
                  error={touched.first_name && errors.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                />
                {touched.first_name && errors.first_name && (
                  <div className="text-error text-sm block">{errors.first_name}</div>
                )}
              </div>
              {/* Last Name */}
              <div>
                <TextInput
                  classNames={`bg-white py-3${touched.last_name && errors.last_name ? ' border-error' : ''}`}
                  label="Last name"
                  name="last_name"
                  type="text"
                  error={touched.last_name && errors.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                />
                {touched.last_name && errors.last_name && (
                  <div className="text-error text-sm block">{errors.last_name}</div>
                )}
              </div>
              {/* Phone */}
              <div>
                <div className="">
                  <div
                    className={twMerge(
                      `w-full h-12 border-solid border  focus:outline-none focus:shadow-outline  focus:border-primary appearance-none focus:border-2 leading-5 rounded-sm px-2`,
                      `${touched.phone && errors.phone ? 'text-error border-error' : ''}`,
                    )}
                  >
                    <PhoneInput
                      className="h-full"
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="Enter phone number"
                      onChange={(e) => {
                        const phoneNumber = e ? e : '';
                        setFieldValue('phone', phoneNumber);
                      }}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                  </div>
                  <Typography className="" variant="errorText">
                    {touched.phone && errors.phone ? errors.phone : ''}
                  </Typography>
                </div>
              </div>
              {/* Email */}
              <div>
                <TextInput
                  classNames={`bg-white py-3${touched.email && errors.email ? ' border-error' : ''}`}
                  label="Email"
                  name="email"
                  type="email"
                  error={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {touched.email && errors.email && <div className="text-error text-sm">{errors.email}</div>}
              </div>
              {/* Location */}
              <div>
                <TextInput
                  classNames={`bg-white py-3${touched.location && errors.location ? ' border-error' : ''}`}
                  label="Location"
                  name="location"
                  type="text"
                  error={touched.location && errors.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.location}
                />
                {touched.location && errors.location && (
                  <div className="text-error text-sm block">{errors.location}</div>
                )}
              </div>
              {/* LinkedIn URL */}
              <div>
                <TextInput
                  classNames={`bg-white py-3${touched.linkedin_url && errors.linkedin_url ? ' border-error' : ''}`}
                  label="LinkedIn URL"
                  name="linkedin_url"
                  type="text"
                  error={touched.linkedin_url && errors.linkedin_url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.linkedin_url}
                />
                {touched.linkedin_url && errors.linkedin_url && (
                  <div className="text-error text-sm block">{errors.linkedin_url}</div>
                )}
                {/* Social Links Section */}
                {values.social_links.map((link, index) => (
                  <div key={index} className="relative mt-4">
                    <TextInput
                      classNames="bg-white py-3 pl-10"
                      label={`Social Link ${index + 1}`}
                      name={`social_links[${index}]`}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.social_links[index]}
                      error={touched.social_links && errors.social_links && errors.social_links[index]}
                    />
                    {touched.social_links && errors.social_links && errors.social_links[index] && (
                      <div className="text-error text-sm block">{errors.social_links[index]}</div>
                    )}
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => {
                        const newSocialLinks = values.social_links.filter((_, i) => i !== index);
                        setFieldValue('social_links', newSocialLinks);
                      }}
                    >
                      <img src="/assets/icons/folder_delete2.svg" alt="Remove link icon" className="w-5 h-5" />
                    </div>
                  </div>
                ))}
                {/* Add Social Link Button */}
                <span
                  onClick={() => setFieldValue('social_links', [...values.social_links, ''])}
                  className="flex items-center gap-2 text-primary mt-1 text-md cursor-pointer w-fit"
                >
                  <img src="/assets/icons/icon_plus_primary.svg" alt="" className="w-4" />
                  <p>Add Social Link</p>
                </span>
              </div>
              {/* Resume Summary */}
              <div>
                <TextInput
                  classNames={`bg-white py-3${touched.resume_summary && errors.resume_summary ? ' border-error' : ''}`}
                  label="Resume summary"
                  name="resume_summary"
                  type="text"
                  error={touched.resume_summary && errors.resume_summary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.resume_summary}
                />{' '}
                {errors.resume_summary && touched.resume_summary && (
                  <div className="text-error text-sm">{errors.resume_summary}</div>
                )}
              </div>
              {/* Education */}
              <div className="">
                {values.education.map((edu, index) => {
                  let showDeleteIcon = index > 0;
                  return (
                    <div
                      key={index}
                      className={`relative  flex flex-col gap-4 bg-white-100 p-4 rounded-md ${
                        showDeleteIcon ? 'mt-4 pt-10' : ''
                      }`}
                    >
                      <div>
                        <TextInput
                          classNames="bg-white-100 py-3"
                          label="Faculty Name"
                          name={`education[${index}].institute`}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.education[index].institute}
                          error={touched.education && errors.education && errors.education[index]?.institute}
                        />
                        {touched.education &&
                          touched.education[index]?.institute &&
                          errors.education &&
                          errors.education[index]?.institute && (
                            <div className="text-error text-sm">{errors.education[index]?.institute}</div>
                          )}
                      </div>
                      <div>
                        <div>
                          <div>
                            <Select
                              selectRef={degreeRef}
                              setFieldValue={setFieldValue}
                              error={touched.education && errors.education && errors.education[index]?.degree}
                              name={`education[${index}].degree`}
                              label="Degree"
                              value={values.education[index].degree}
                              options={candidatesDegrees}
                            />
                          </div>
                          {touched.education &&
                            touched.education[index]?.degree &&
                            errors.education &&
                            errors.education[index]?.degree && (
                              <div className="text-error text-sm">{errors.education[index]?.degree}</div>
                            )}
                        </div>
                        {/* <TextInput
                          classNames="bg-white-100 py-3"
                          label="Degree"
                          name={`education[${index}].degree`}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.education[index].degree}
                          error={touched.education && errors.education && errors.education[index]?.degree}
                        />*/}
                        {touched.education &&
                          touched.education[index]?.degree &&
                          errors.education &&
                          errors.education[index]?.degree && (
                            <div className="text-error text-sm">{errors.education[index]?.degree}</div>
                          )}
                      </div>
                      <div>
                        <TextInput
                          classNames="bg-white-100 py-3 text-transparent"
                          label="Start Date"
                          name={`education[${index}].start_date`}
                          type="month"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.education[index].start_date}
                          error={touched.education && errors.education && errors.education[index]?.start_date}
                        />
                        {touched.education &&
                          touched.education[index]?.start_date &&
                          errors.education &&
                          errors.education[index]?.start_date && (
                            <div className="text-error text-sm">{errors.education[index]?.start_date}</div>
                          )}
                      </div>
                      <div className="">
                        <TextInput
                          classNames="bg-white-100 py-3 text-transparent"
                          label="End Date"
                          name={`education[${index}].end_date`}
                          type="month"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.education[index].end_date}
                          error={touched.education && errors.education && errors.education[index]?.end_date}
                        />
                        {touched.education &&
                          touched.education[index]?.end_date &&
                          errors.education &&
                          errors.education[index]?.end_date && (
                            <div className="text-error text-sm">{errors.education[index]?.end_date}</div>
                          )}
                      </div>

                      {showDeleteIcon && (
                        <div
                          className="absolute top-2 right-0 pr-3 flex items-center cursor-pointer"
                          onClick={() => {
                            const newEducation = values.education.filter((_, i) => i !== index);
                            setFieldValue('education', newEducation);
                          }}
                        >
                          <img src="/assets/icons/folder_delete2.svg" alt="Remove education icon" className="w-5 h-5" />
                        </div>
                      )}
                    </div>
                  );
                })}

                <span
                  onClick={() =>
                    setFieldValue('education', [
                      ...values.education,
                      { institute: '', degree: '', start_date: '', end_date: '' },
                    ])
                  }
                  className="flex items-center gap-2 text-primary mt-1 text-md cursor-pointer w-fit"
                >
                  <img src="/assets/icons/icon_plus_primary.svg" alt="" className="w-4" />
                  <p>Add Education</p>
                </span>
              </div>

              {/* Skills Section */}
              <div className="">
                <div className="relative items-center mt-2 px-4">
                  <TextInput
                    classNames="bg-white py-4"
                    name="new_skill"
                    type="text"
                    label={'Skills'}
                    onBlur={handleBlur}
                    placeholder="Enter skill"
                    value={newSkill}
                    onChange={(e) => setNewSkill(e.target.value)}
                  />
                  <span
                    onClick={handleAddSkill}
                    className="absolute top-2 right-6  cursor-pointer bg-primary text-white px-2 py-0.5 rounded-sm"
                  >
                    Save
                  </span>
                </div>
                <ul className="mt-4 flex gap-4">
                  {values.skills.map((skill, index) => (
                    <li key={index} className="flex items-center gap-3 bg-primary bg-opacity-10 p-2 rounded-sm">
                      <span className="text-primary">{skill}</span>
                      <span className="cursor-pointer" onClick={() => handleRemoveSkill(index)}>
                        <img src="/assets/icons/close-icon.svg" className="filter-black mr-1" alt="" />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              {/* certs */}
              <div className="">
                {values.certs.map((cert, index) => {
                  let showDeleteIcon = index > 0;
                  return (
                    <div
                      key={index}
                      className={`relative flex flex-col gap-4  bg-white-100 p-4 rounded-md ${
                        showDeleteIcon ? 'mt-4 pt-10' : ''
                      }`}
                    >
                      <TextInput
                        classNames="bg-white-100 py-3"
                        label="Certificate Name"
                        name={`certs[${index}].title`}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.certs[index].title}
                        error={touched.certs && errors.certs && errors.certs[index]?.title}
                      />
                      <TextInput
                        classNames="bg-white-100 py-3"
                        label="Issuing Organization"
                        name={`certs[${index}].issuer`}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.certs[index].issuer}
                      />

                      <div className="">
                        <TextInput
                          classNames="custom-date-input bg-white-100 py-3 text-transparent"
                          label="Certificate Issue Date "
                          name={`certs[${index}].issue_date`}
                          type="month"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.certs[index].issue_date}
                        />
                      </div>
                      {touched.certs &&
                        touched.certs[index]?.issue_date &&
                        errors.certs &&
                        errors.certs[index]?.issue_date && (
                          <div className="text-error text-sm">{errors.certs[index]?.issue_date}</div>
                        )}
                      <TextInput
                        classNames="bg-white-100 py-3"
                        label="Credential"
                        name={`certs[${index}].credential`}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.certs[index].credential}
                      />
                      {showDeleteIcon && (
                        <div
                          className="absolute top-2 right-0 pr-3 flex items-center cursor-pointer"
                          onClick={() => {
                            const newcerts = values.certs.filter((_, i) => i !== index);
                            setFieldValue('certs', newcerts);
                          }}
                        >
                          <img
                            src="/assets/icons/folder_delete2.svg"
                            alt="Remove certification icon"
                            className="w-5 h-5"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
                <span
                  onClick={() =>
                    setFieldValue('certs', [...values.certs, { title: '', issuer: '', issue_date: '', credential: '' }])
                  }
                  className="flex items-center gap-2 text-primary mt-1 text-md cursor-pointer w-fit"
                >
                  <img src="/assets/icons/icon_plus_primary.svg" alt="" className="w-4" />
                  <p>Add Certification</p>
                </span>
              </div>
              {/* Experience */}
              <div>
                {values.experience.map((exp, index) => {
                  let showDeleteIcon = index > 0;
                  return (
                    <div
                      key={index}
                      className={`relative flex flex-col gap-4 bg-white-100 p-4 rounded-md ${
                        showDeleteIcon ? 'mt-4 pt-10' : ''
                      }`}
                    >
                      <div>
                        <TextInput
                          classNames="bg-white-100 py-3"
                          label="Job Title"
                          name={`experience[${index}].title`}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.experience[index].title}
                          error={touched.experience && errors.experience && errors.experience[index]?.title}
                        />
                        {touched.experience &&
                          touched.experience[index]?.title &&
                          errors.experience &&
                          errors.experience[index]?.title && (
                            <div className="text-error text-sm">{errors.experience[index]?.title}</div>
                          )}
                      </div>
                      <div>
                        <Select
                          selectRef={positionTypeRef}
                          error={touched.experience && errors.experience && errors.experience[index]?.position_type}
                          setFieldValue={setFieldValue}
                          name={`experience[${index}].position_type`}
                          label="Employment Type"
                          value={values.experience[index].position_type}
                          onChangeHandler={handleChange}
                          options={positionTypeOptions}
                        />
                        {touched.experience &&
                          touched.experience[index]?.position_type &&
                          errors.experience &&
                          errors.experience[index]?.position_type && (
                            <div className="text-error text-sm">{errors.experience[index]?.position_type}</div>
                          )}
                      </div>
                      <div>
                        <TextInput
                          classNames="bg-white-100 py-3"
                          label="Company Name"
                          name={`experience[${index}].company_name`}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.experience[index].company_name}
                          error={touched.experience && errors.experience && errors.experience[index]?.company_name}
                        />
                        {touched.experience &&
                          touched.experience[index]?.company_name &&
                          errors.experience &&
                          errors.experience[index]?.company_name && (
                            <div className="text-error text-sm">{errors.experience[index]?.company_name}</div>
                          )}
                      </div>
                      <div>
                        <TextInput
                          classNames="bg-white-100 py-3"
                          label="Company Location"
                          name={`experience[${index}].company_location`}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.experience[index].company_location}
                          error={touched.experience && errors.experience && errors.experience[index]?.company_location}
                        />
                        {touched.experience &&
                          touched.experience[index]?.company_location &&
                          errors.experience &&
                          errors.experience[index]?.company_location && (
                            <div className="text-error text-sm">{errors.experience[index]?.company_location}</div>
                          )}
                      </div>
                      <div>
                        <TextInput
                          classNames="custom-date-input bg-white-100 py-3 text-transparent"
                          label="Start Date"
                          name={`experience[${index}].start_date`}
                          type="month"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.experience[index].start_date}
                          error={touched.experience && errors.experience && errors.experience[index]?.start_date}
                        />
                        {touched.experience &&
                          touched.experience[index]?.start_date &&
                          errors.experience &&
                          errors.experience[index]?.start_date && (
                            <div className="text-error text-sm">{errors.experience[index]?.start_date}</div>
                          )}
                      </div>
                      <div>
                        <TextInput
                          classNames="custom-date-input bg-white-100 py-3 text-transparent mb-"
                          label="End Date"
                          name={`experience[${index}].end_date`}
                          type="month"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.experience[index].end_date}
                          error={touched.experience && errors.experience && errors.experience[index]?.end_date}
                          disabled={checkboxValue}
                          setFieldValue={setFieldValue}
                        />
                        {touched.experience &&
                          touched.experience[index]?.end_date &&
                          errors.experience &&
                          errors.experience[index]?.end_date && (
                            <div className="text-error text-sm">{errors.experience[index]?.end_date}</div>
                          )}
                        <div className="mt-2">
                          <Checkbox
                            value={checkboxValue}
                            onChange={() => {
                              setCheckboxValue(!checkboxValue);
                              if (!checkboxValue) {
                                // Clear the end date when checkbox is checked
                                setFieldValue(`experience[${index}].end_date`, null);
                              }
                            }}
                          >
                            <span className="text-primary">Currently Employed</span>
                          </Checkbox>
                        </div>
                      </div>
                      {showDeleteIcon && (
                        <div
                          className="absolute top-2 right-0 pr-3 flex items-center cursor-pointer"
                          onClick={() => {
                            const newExperience = values.experience.filter((_, i) => i !== index);
                            setFieldValue('experience', newExperience);
                          }}
                        >
                          <img
                            src="/assets/icons/folder_delete2.svg"
                            alt="Remove experience icon"
                            className="w-5 h-5"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
                <span
                  onClick={() =>
                    setFieldValue('experience', [
                      ...values.experience,
                      {
                        title: '',
                        position_type: '',
                        company_name: '',
                        company_location: '',
                        start_date: '',
                        end_date: '',
                      },
                    ])
                  }
                  className="flex items-center gap-2 text-primary mt-1 text-md cursor-pointer w-fit"
                >
                  <img src="/assets/icons/icon_plus_primary.svg" alt="" className="w-4" />
                  <p>Add Experience</p>
                </span>
              </div>
              {/* Notice Period */}
              <div>
                <TextInput
                  classNames={`bg-white py-3${touched.notice_period && errors.notice_period ? ' border-error' : ''}`}
                  label="Notice period"
                  name="notice_period"
                  type="text"
                  error={touched.notice_period && errors.notice_period}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.notice_period}
                />
                {touched.notice_period && errors.notice_period && (
                  <div className="text-error text-sm block">{errors.notice_period}</div>
                )}
              </div>
              {/* Current Salary */}
              <div>
                <TextInput
                  classNames={`bg-white py-3${touched.current_salary && errors.current_salary ? ' border-error' : ''}`}
                  label="Current salary"
                  name="current_salary"
                  type="text"
                  error={touched.current_salary && errors.current_salary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.current_salary}
                />
                {touched.current_salary && errors.current_salary && (
                  <div className="text-error text-sm">{errors.current_salary}</div>
                )}
              </div>
              {/* Expected Salary */}
              <div>
                <TextInput
                  classNames={`bg-white py-3${
                    touched.expected_salary && errors.expected_salary ? ' border-error' : ''
                  }`}
                  label="Expected salary"
                  name="expected_salary"
                  type="text"
                  error={touched.expected_salary && errors.expected_salary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.expected_salary}
                />
                {touched.expected_salary && errors.expected_salary && (
                  <div className="text-error text-sm">{errors.expected_salary}</div>
                )}
              </div>
              {/* Category */}
              <div>
                <div>
                  <Select
                    selectRef={categoryRef}
                    setFieldValue={setFieldValue}
                    error={errors.category && touched.category}
                    name="category"
                    label="Candidate Category"
                    value={values.category}
                    options={jobCategories}
                  />
                </div>
                <Typography variant="errorText">
                  {errors.category && touched.category ? errors.category : ''}
                </Typography>
              </div>
              <Button
                type="submit"
                disabled={isCreating || isUpdating}
                isLoading={isCreating || isUpdating}
                classNames="flex gap-1 w-2/3 mx-auto justify-center items-center px-8 py-[10px] bg-primary text-white border-none rounded-md"
              >
                {isEditing ? 'Update' : 'Add'}{' '}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateNewCandidateForm;
