import { ForgetPassqordForm } from 'modules/auth/components/organisms';
import React from 'react';

const ForgetPassword = () => {
  return (
    <div>
      <ForgetPassqordForm />
    </div>
  );
};

export default ForgetPassword;
