import React from 'react';

const TableLoader = ({ numRows = 5, showButtons = false }) => {
  return (
    <div>
      <div className="p-6">
        {showButtons && (
          <div className="mb-6 flex justify-between">
            <div className="animate-pulse" style={{ backgroundColor: '#ebebeb', height: '40px', width: '33%' }}></div>
            <div className="animate-pulse" style={{ backgroundColor: '#ebebeb', height: '40px', width: '20%' }}></div>
          </div>
        )}
        <div className="animate-pulse flex flex-col space-y-4">
          {[...Array(numRows)].map((_, index) => (
            <div key={index} style={{ backgroundColor: '#ebebeb', height: '40px', width: '100%' }}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableLoader;
