import { apiSlice } from 'app/api/apiSlice';

const jobsApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchJobs: builder.query({
        query: ({ page, limit }) => {
          return {
            url: '/jobs/list',
            method: 'GET',
          };
        },
      }),
      deleteJob: builder.mutation({
        query: (jobId) => ({
          url: `/jobs/delete`,
          method: 'DELETE',
          body: jobId,
        }),
      }),
      uploadFile: builder.mutation({
        query: (payload) => ({
          url: '/jobs/bulk',
          method: 'POST',
          body: payload,
        }),
      }),
      createJob: builder.mutation({
        query: (jobData, token) => ({
          url: '/jobs/job/',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: jobData,
        }),
      }),
      updateJob: builder.mutation({
        query: ({ jobId, data }) => ({
          url: `/jobs/${jobId}`,
          method: 'PUT',
          body: data,
        }),
      }),
      getTemplates: builder.query({
        query: () => ({
          url: '/templates/list',
          method: 'GET',
        }),
      }),
      getTemplateById: builder.query({
        query: (templateId) => ({
          url: `/templates/${templateId}`,
          method: 'GET',
        }),
      }),
      getJobById: builder.query({
        query: (jobId) => ({
          url: `/jobs/${jobId}`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {
  useFetchJobsQuery,
  useDeleteJobMutation,
  useUploadFileMutation,
  useCreateJobMutation,
  useUpdateJobMutation,
  useGetTemplatesQuery,
  useGetTemplateByIdQuery,
  useGetJobByIdQuery,
} = jobsApi;

export { jobsApi };
