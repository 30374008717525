import { LogsTable } from 'modules/adminstration/components/organisms';
import { SearchInput, Typography } from 'modules/shared/components/atoms';
import React from 'react';

const Logs = () => {
  return (
    <div className="grid gap-10 px-10">
      <div className="grid grid-cols-2  ">
        <Typography className="font-semibold text-xl tracking-widest">Logs</Typography>
        <SearchInput label="search for your needs" />
      </div>
      <LogsTable />
    </div>
  );
};

export default Logs;
