import { AccountDetails } from 'modules/adminstration/components/organisms';
import React from 'react';

const index = () => {
  return (
    <div>
      <AccountDetails />
    </div>
  );
};

export default index;
