import React from 'react';
import { ActivityCard } from '../../molecules';

const notes = [
  {
    id: 4,
    status: 'Planned',
    content: 'Meeting with team',
    date: 'Feb 1, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 5,
    status: 'InProgress',
    content: 'Development of new feature',
    date: 'Feb 2, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 6,
    status: 'Done',
    content: 'Code review completed',
    date: 'Feb 3, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 7,
    status: 'Planned',
    content: 'Prepare presentation',
    date: 'Feb 4, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 8,
    status: 'InProgress',
    content: 'Client feedback session',
    date: 'Feb 5, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 9,
    status: 'Done',
    content: 'Design mockups finalized',
    date: 'Feb 6, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 10,
    status: 'Planned',
    content: 'Budget meeting',
    date: 'Feb 7, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 11,
    status: 'InProgress',
    content: 'Implementing new design',
    date: 'Feb 8, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 12,
    status: 'Done',
    content: 'Testing new feature',
    date: 'Feb 9, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 13,
    status: 'Planned',
    content: 'Update project documentation',
    date: 'Feb 10, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 19,
    status: 'Planned',
    content: 'Marketing strategy meeting',
    date: 'Feb 16, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 21,
    status: 'Done',
    content: 'Release new version',
    date: 'Feb 18, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 22,
    status: 'Planned',
    content: 'User training',
    date: 'Feb 19, 2024',
    avatar: '/assets/icons/avatar.png',
  },
  {
    id: 24,
    status: 'Done',
    content: 'Implement CI/CD pipeline',
    date: 'Feb 21, 2024',
    avatar: '/assets/icons/avatar.png',
  },
];
const JobActivity = () => {
  const plannedNotes = notes.filter((note) => note.status === 'Planned');
  const inProgressNotes = notes.filter((note) => note.status === 'InProgress');
  const doneNotes = notes.filter((note) => note.status === 'Done');

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-medium mb-8">Activity</h1>
      <div className="flex justify-between">
        <div className="">
          <div className="border border-white-300 border-opacity-50 rounded-md p-2">
            <div className="flex gap-1 items-center">
              <p className="w-[10px] h-[10px] mb-4 rounded-full bg-opacity-70 bg-secondary"></p>
              <h2 className="text-lg text-gray mb-4">Planned</h2>
            </div>
            {plannedNotes.map((note) => (
              <ActivityCard key={note.id} note={note} />
            ))}
          </div>
        </div>
        <div className="">
          <div className="border border-white-300 border-opacity-50 rounded-md p-2">
            <div className="flex gap-1 items-center">
              <p className="w-[10px] h-[10px] mb-4 rounded-full bg-info"></p>
              <h2 className="text-lg text-gray mb-4">InProgress</h2>
            </div>
            {inProgressNotes.map((note) => (
              <ActivityCard key={note.id} note={note} />
            ))}
          </div>
        </div>
        <div className="">
          <div className="border border-white-300 border-opacity-50 rounded-md p-2">
            <div className="flex gap-1 items-center">
              <p className="w-[10px] h-[10px] mb-4 rounded-full bg-success-100"></p>
              <h2 className="text-lg text-gray mb-4">Done</h2>
            </div>
            {doneNotes.map((note) => (
              <ActivityCard key={note.id} note={note} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobActivity;
