import { Field, Formik } from 'formik';
import { Tag } from 'modules/adminstration/components/molecules';
import { AddNewTag } from 'modules/adminstration/components/organisms';
import { Button, Select, SwitchButton, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import React, { useRef, useState } from 'react';

const initialTagsData = [
  {
    id: 1,
    name: 'available',
    color: '#00D609',
  },
  {
    id: 2,
    name: 'blacklist',
    color: '#E92C2C',
  },
  {
    id: 3,
    name: 'foreigner',
    color: '#077D96',
  },
  {
    id: 4,
    name: 'important',
    color: '#694C86',
  },
];

const clientsMenuItems = [
  { key: 1, value: 'LinkedIn clinet' },
  { key: 2, value: 'Twitter clinet' },
  { key: 3, value: 'facebook clinet' },
  { key: 4, value: 'vodafone clinet' },
];

const CustomizationClients = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedclientTemplate, setSelectedClientTemplate] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [tagsData, setTagsData] = useState(initialTagsData);
  const [selectedOption, setSelectedOption] = useState(null);
  const [newColor, setNewColor] = useState('');
  const clientsTemplateRef = useRef(null);

  const closeModal = () => {
    setIsModalOpened(false);
    setSelectedTag(null);
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  const handleEditClick = (tag) => {
    setSelectedTag(tag);
    openModal();
  };

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  const handleAddTag = (selectedColor, tagText) => {
    setNewColor(selectedColor);
    setTagsData([...tagsData, { id: tagsData.length + 1, color: selectedColor, name: tagText }]);
  };
  const handleUpdateTag = (updatedTag) => {
    const updatedTags = tagsData.map((tag) =>
      tag.id === updatedTag.id ? { ...tag, name: updatedTag.name, color: updatedTag.color } : tag,
    );
    setTagsData(updatedTags);
  };
  const handleSubmit = (values, { setSubmitting }) => {
    // Handle submission logic if needed
    setSubmitting(false);
  };
  return (
    <div className="grid gap-12 mx-14 shadow-sm px-6 py-3">
      <Typography className="font-semibold text-2xl tracking-widest">Clients</Typography>
      <article className="grid gap-5  mr-36">
        <div className="grid gap-4 mb-4">
          <Typography variant="mainCardTitle" className="tracking-widest">
            Name
          </Typography>
          <div className="w-60">
            <Select
              labelClassNames="text-base leading-[3.5] tracking-wider"
              selectRef={clientsTemplateRef}
              name="job_templage"
              label="Client / Clients"
              value={selectedclientTemplate}
              setFieldValue={() => setSelectedClientTemplate(1)}
              options={clientsMenuItems}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <Typography variant="mainCardTitle" className="tracking-widest">
            Tags
          </Typography>
          <Button onClick={openModal} classNames="bg-primary px-4 py-2 tracking-widest text-white border-none">
            add a new tag
          </Button>
        </div>
        <article className="w-full py-8 shadow-lg rounded-sm px-10 grid gap-6">
          {tagsData.map((tag) => (
            <div key={tag.id} className="flex justify-between">
              <Tag colorClass={tag.color} text={tag.name} />
              <div className="flex gap-4">
                <img
                  src="/assets/icons/folder_edit2.svg"
                  className="cursor-pointer"
                  alt=""
                  onClick={() => handleEditClick(tag)}
                />
                <img src="/assets/icons/folder_delete2.svg" alt="" />
              </div>
            </div>
          ))}
        </article>
        <Formik initialValues={{ visibility: '', visibilityStatus: false }} onSubmit={handleSubmit}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="grid gap-4 my-5">
              <Typography variant="mainCardTitle" className="tracking-widest">
                Visibility
              </Typography>
              <div className="items-center grid gap-6">
                <label className="flex items-center mr-4">
                  <Field
                    type="radio"
                    name="visibility"
                    value="public"
                    onChange={() => {
                      formik.handleChange('visibility')('public');
                      handleRadioChange('public');
                    }}
                    checked={selectedOption === 'public'}
                    className="mr-2 focus:ring-none radio-button"
                  />
                  <div>
                    <p className="text-base">Public</p>
                    <p className="text-sm text-neutral-200">Clients and their details are visible to everyone.</p>
                  </div>
                </label>
                <label className="flex items-center mr-4">
                  <Field
                    type="radio"
                    name="visibility"
                    value="private"
                    onChange={() => {
                      formik.handleChange('visibility')('private');
                      handleRadioChange('private');
                    }}
                    checked={selectedOption === 'private'}
                    className="mr-2 focus:ring-none radio-button"
                  />
                  <div>
                    <p className="text-base">Private</p>
                    <p className="text-sm text-neutral-200">
                      Clients are visible on the organization list but their details are only accessible to team members
                      and admin users.
                    </p>
                  </div>
                </label>
                <label className="flex items-center">
                  <Field
                    type="radio"
                    name="visibility"
                    value="confidential"
                    onChange={() => {
                      formik.handleChange('visibility')('confidential');
                      handleRadioChange('confidential');
                    }}
                    checked={selectedOption === 'confidential'}
                    className="mr-2 focus:ring-none radio-button"
                  />
                  <div>
                    <p className="text-base">Confidential</p>
                    <p className="text-sm text-neutral-200">
                      Clients are only visible to team members and admin users.
                    </p>
                  </div>
                </label>
              </div>
              <Typography variant="mainCardTitle" className="tracking-widest">
                Restrict Access to Client Visibility Control
              </Typography>
              <article className="flex justify-between items-center">
                <Typography className="font-semibold text-xl tracking-widest">Status</Typography>
                <Field
                  name="visibilityStatus"
                  render={({ field }) => (
                    <SwitchButton
                      name={field.name}
                      checkboxValue={formik.values[field.name]}
                      onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                    />
                  )}
                />
              </article>
            </form>
          )}
        </Formik>
      </article>
      <BasicModal
        title={selectedTag ? 'Edit Tag' : 'Add a Tag'}
        isOpen={isModalOpened}
        onClose={closeModal}
        closeOnOverlayClick={false}
      >
        <AddNewTag
          closeModal={closeModal}
          onAddTag={handleAddTag}
          selectedTag={selectedTag}
          updateTag={handleUpdateTag}
        />
      </BasicModal>
    </div>
  );
};

export default CustomizationClients;
