// // src/components/CertificateCard.js
// import React from 'react';
// import { Link } from 'react-router-dom';

// const CertificateCard = () => (
//   <div className="p-5 bg-white shadow-lg border border-gray-100 rounded-lg mt-10">
//     <h2 className="text-xl">Certificate</h2>
//     <div className="mt-4 flex flex-col gap-1">
//       <h3 className="text-lg">UX Management at The Interaction Design Foundation</h3>
//       <p className="text-gray-200">Apr 2024 - Mar 2024</p>
//       <Link href="#" className="text-primary  flex gap-2 items-center">
//         <img src="/assets/icons/link-external-icon.png" alt="" className="w-5 h-5" />
//         view Certificate
//       </Link>
//     </div>
//     <div className="mt-4 flex flex-col gap-1">
//       <h3 className="text-lg ">UX Design professional at Coursera</h3>
//       <p className="text-gray-200">Jan 2024 - Mar 2024</p>
//       <Link href="#" className="text-primary flex gap-2 items-center">
//         <img src="/assets/icons/link-external-icon.png" alt="" className="w-5 h-5" />
//         view Certificate
//       </Link>
//     </div>
//   </div>
// );

// export default CertificateCard;
import React from 'react';
import { Link } from 'react-router-dom';

const CertificateCard = ({ certificateData }) => {
  return (
    <div className="p-5 bg-white shadow-lg border border-gray-100 rounded-lg mt-10">
      <h2 className="text-xl">Certificates</h2>
      {certificateData.map((certificate, index) => (
        <div key={index} className="mt-4 flex flex-col gap-1">
          <h3 className="text-lg">{certificate.title}</h3>
          <p className="text-gray-200">{certificate.issue_date}</p>
          <Link to={certificate.link} className="text-primary flex gap-2 items-center">
            <img src="/assets/icons/link-external-icon.png" alt="" className="w-5 h-5" />
            View Certificate
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CertificateCard;
