import React from 'react';

const ToggleSwitch = ({ classNames = '', checkboxValue, onCheckboxChange = () => {} }) => {
  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    onCheckboxChange(newValue);
  };
  return (
    <label className={`relative items-center inline-flex cursor-pointer ${classNames}`}>
      <input type="checkbox" className="sr-only peer" checked={checkboxValue} onChange={handleCheckboxChange} />
      <div
        className="w-11 h-6 bg-white-400 peer-focus:outline-none rounded-full peer dark:peer-focus:ring-primary dark:bg-gray peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
      after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-white-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-neutral-200 peer-checked:bg-primary "
      ></div>
    </label>
  );
};

export default ToggleSwitch;
