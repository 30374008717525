import { useOutSideClick } from 'modules/shared/hooks';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

const SelectTwo = ({
  label,
  options,
  name,
  setFieldValue,
  error,
  selectRef = '',
  value,
  classNames = '',
  labelClassNames = 'text-sm  leading-[4.5]',
}) => {
  const errorClassNames = error ? 'border-error' : '';
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const defaultButtonClass = twMerge(
    `peer relative w-full h-full border-neutral-100 bg-transparent text-left outline-0 focus:outline-0 transition-all border text-sm px-3 rounded-[7px] ${
      selectedOption ? 'border-t-transparent border-b-primary border-r-primary border-l-primary' : ''
    }`,
    error ? errorClassNames : '',
  );
  const isListOpened = `peer w-full h-full bg-transparent text-left outline outline-0 focus:outline-0 transition-all border-2 border-primary text-sm px-3  rounded-[7px] ${
    error ? 'border-text-error' : ''
  } border-primary border-t-transparent`;
  const defaultLabelClass = `flex w-full h-full select-none pointer-events-none absolute left-0  transition-all -top-1.5 before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all  after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 after:rounded-tr-md after:pointer-events-none after:transition-all   text-blue-gray-400 before:border-gray-900 after:border-gray-900 ${labelClassNames}`;

  const clicked = `flex w-full h-full select-none pointer-events-none absolute left-0  transition-all -top-1.5 before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 before:rounded-tl-md before:pointer-events-none before:transition-all  after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 after:rounded-tr-md after:pointer-events-none after:transition-all  text-[11px] ${
    selectedOption
      ? isOpen
        ? 'before:border-t-[2px] after:border-t-[2px] after:border-r-[2px] before:border-l-[2px] before:border-primary'
        : 'before:border-t-[1px] after:border-t-[1px] after:border-r-[1px] before:border-l-[1px] before:border-primary'
      : 'before:border-t-2 after:border-t-2 after:border-r-2 before:border-l-2 before:border-primary'
  }    leading-tight  before:border-neutral1-00 after:border-primary`;
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeList = () => {
    setIsOpen(false);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFieldValue(name, option);
    setIsOpen(false);
  };
  useOutSideClick(selectRef, closeList);
  return (
    <div ref={selectRef} className={twMerge(`relative w-full  h-11 font-poppins`, classNames)}>
      <button
        type="button"
        className={isOpen ? isListOpened : defaultButtonClass}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        role="combobox"
        aria-controls="custom-select-options"
        onClick={handleButtonClick}
      >
        <span
          className={`absolute top-2/4 -translate-y-2/4 left-3 pt-0.5 ${
            isOpen ? 'text-blue-gray-500' : 'text-blue-gray-700'
          }`}
        >
          {selectedOption?.value}
        </span>
        <img className="absolute right-6" src="/assets/icons/black_arrow.svg" width={10} height={10} />
      </button>
      <label className={` ${isOpen || selectedOption ? clicked : defaultLabelClass + labelClassNames} `}>{label}</label>

      {isOpen && (
        <ul
          name={name}
          tabIndex="-1"
          role="listbox"
          className="w-full absolute top-12 left-0 opacity-1 z-50 scale-100 max-h-96 bg-white p-3 border-2 border-primary rounded-md shadow-lg shadow-primary/10 text-sm font-normal overflow-auto focus:outline-none"
          id="custom-select-options"
          aria-orientation="vertical"
        >
          {options?.map((option, index) => {
            const { key, value } = option;
            const selectedId = selectedOption?.key;
            const hilightOption = key === selectedId;
            return (
              <option
                key={index}
                value={key}
                role="option"
                className={`pt-2 my-2 pb-2 px-3 rounded-md leading-tight cursor-pointer select-none hover:bg-primary/70 hover:text-white focus:bg-blue-gray-50 hover:bg-opacity-80 focus:bg-opacity-80 hover:text-blue-gray-900 focus:text-blue-gray-900 ouoptionne outline-0 transition-all ${
                  hilightOption ? 'bg-primary/70' : ''
                }`}
                tabIndex="1"
                aria-selected={key === selectedOption}
                data-selected={key === selectedOption}
                onClick={() => handleOptionClick(option)}
              >
                {value}
              </option>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SelectTwo;
