import React from 'react';
import { Avatar, CompletionPercentage } from '../../atoms';

const JobTrackingItem = ({
  position = '',
  category = '',
  datePostedAge = '',
  avatarUrl = '',
  alt = '',
  percentage = 0,
}) => {
  return (
    <article className="flex gap-4 items-center justify-between">
      <div className="flex gap-6">
        <div>
          <Avatar avatarUrl={avatarUrl} alt={alt} />
        </div>
        <div>
          <p className="inline-flex gap-2">
            <span className="text-base font-semibold">{position}</span>
            <span className="text-base font-semibold text-primary">{category}</span>
          </p>
          <p>
            <span className="text-sm">Applied {datePostedAge}</span>
          </p>
        </div>
      </div>
      <div className="flex gap-2">
        <CompletionPercentage percentage={percentage} />
      </div>
    </article>
  );
};

export default JobTrackingItem;
