import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useGetCandidateByIdQuery } from 'features/candidatesSlice';
import { Header, NavigationTabs } from '../components/molecules';
import Summary from '../components/organisms/Summary';
import { NavLink } from 'react-router-dom';
import { Typography } from 'modules/shared/components/atoms';
import { TableLoader } from 'modules/shared/components/molecules';

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('Summary');
  const { candidateId } = useParams();
  const { data: candidateData, isLoading, refetch: refetchCandidate, error } = useGetCandidateByIdQuery(candidateId);
  console.log('candidateData', candidateData);

  if (isLoading) {
    return <TableLoader numRows={7} />;
  } else if (error) {
    return <div>Error fetching candidate data</div>;
  } else {
    return (
      <div>
        <div className="mb-4 flex items-center">
          <NavLink to={'/dashboard/candidates/'} className="w-14">
            <img src="/assets/icons/back_arrow.svg" alt="" />
          </NavLink>
          <Typography className="">Candidate Profile</Typography>
        </div>
        <Header
          candidateName={candidateData[0]?.first_name}
          currentPosition={candidateData[0]?.current_position}
          location={candidateData[0]?.location}
          email={candidateData[0]?.email}
          phone={candidateData[0]?.phone}
          linkedinUrl={candidateData[0]?.linkedin_url}
        />
        <NavigationTabs activeTab={activeTab} onTabClick={setActiveTab} />
        <div>{activeTab === 'Summary' && <Summary candidateData={candidateData[0]} />}</div>
      </div>
    );
  }
};

export default UserProfile;
