import React from 'react';
import { twMerge } from 'tailwind-merge';

const Buttton = ({
  children,
  iconUrl,
  onClick,
  iconName = 'icon',
  classNames,
  iconClassNames,
  disabled = false,
  type = '',
  isLoading = false,
}) => {
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      onClick={onClick}
      className={twMerge(
        `disabled:bg-neutral-100 disabled:text-white-100 group flex justify-center items-center px-8 py-[10px] text-black hover:bg-primary hover:text-white border rounded-md`,
        isLoading && 'cursor-wait',
        classNames,
      )}
    >
      <span className="flex-grow text-center">{children}</span>
      {iconUrl && !isLoading && (
        <img
          className={twMerge('ml-2 filter-black group-hover:filter-white', iconClassNames)} // Added margin-left here
          src={iconUrl}
          alt={iconName}
          width={16}
          height={16}
        />
      )}
      {isLoading && (
        <svg
          className="ml-2 animate-spin h-5 w-5 text-current" // Added margin-left here
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
    </button>
  );
};

export default Buttton;
