import React, { useState, useEffect, useRef } from 'react';

const NoteCard = ({ note, openPopup, currentPopup, onClick, onEdit, onDelete }) => {
  const { title, author, date, content, noteAvatar } = note;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    if (currentPopup !== note.id) {
      setIsPopupOpen(false);
    }
  }, [currentPopup, note.id]);

  const togglePopup = (e) => {
    e.stopPropagation();
    if (isPopupOpen) {
      setIsPopupOpen(false);
      openPopup(null);
    } else {
      setIsPopupOpen(true);
      openPopup(note.id);
    }
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setIsPopupOpen(false);
    onEdit({
      type: 'edit',
      noteId: note.id,
      title: 'Edit Note',
      content: note.content,
    });
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setIsPopupOpen(false);
    onDelete({
      type: 'delete',
      noteId: note.id,
      title: 'Delete Note',
      content: 'Are you sure you want to delete this note?',
    });
  };
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false);
    }
  };
  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isPopupOpen]);
  return (
    <div className="border-l-4 border-error m-4 rounded-md" onClick={onClick}>
      <div className="max-w-sm rounded overflow-hidden border rounded-sm border-neutral-200">
        <div className="px-6 py-4">
          <div className="flex justify-between">
            <div className="font-medium text-xl mb-2">{title}</div>
            <div className="relative" ref={popupRef}>
              <img
                src="/assets/icons/dots-vertical-rounded.png"
                alt="menu"
                className="h-6 cursor-pointer"
                onClick={togglePopup}
              />
              {isPopupOpen && (
                <div className="absolute top-6 -right-5 mt-2 w-32 bg-white rounded-sm shadow-lg z-10">
                  <p className="absolute right-6 -top-2 w-4 h-4 border-l border-t border-black-shd1 bg-white z-20 rotate-45"></p>
                  <div className="py-2">
                    <button className="px-4 flex py-2 text-sm text-neutral-100 w-full text-left" onClick={handleEdit}>
                      <img src="/assets/icons/edit-icon.png" alt="Edit" className="w-4" />
                      Edit
                    </button>
                    <button className="flex px-4 py-2 text-sm text-neutral-100 w-full text-left" onClick={handleDelete}>
                      <img src="/assets/icons/DeleteOutline.png" alt="Delete" className="w-4 filter-black opacity-60" />
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-gray text-base flex gap-10 items-center mb-2">
            <div className="flex gap-1">
              <img className="w-5 h-5 rounded-full" src={noteAvatar} alt="Avatar" />
              <p className="text-gray-900 leading-none">{author}</p>
            </div>
            <p className="text-gray-600">{date}</p>
          </div>
          <p
            className="overflow-hidden text-ellipsis text-neutral-100 text-base w-full"
            style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
          >
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoteCard;
