import { Button, Typography } from 'modules/shared/components/atoms';
import { useFetchJobsQuery } from '../../../../features/jobsSlice';
import { JobsTable } from 'modules/jobs/components/organisms';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import { openJobModal } from 'features/modalsSlice';
import { useDispatch, useSelector } from 'react-redux';
import JobsModal from 'modules/jobs/components/organisms/JobsModal';
import { TableLoader } from 'modules/shared/components/molecules';
const Jobs = () => {
  const dispatch = useDispatch();
  const isJobModalOpen = useSelector((state) => state.modal.isJobModalOpen);
  const {
    data,
    isLoading,
    refetch: refetchJobs,
    error,
  } = useFetchJobsQuery({
    page: 1,
    limit: 10,
  });

  return (
    <div>
      <div className="flex justify-between mb-6 border-b border-b-black-shd1 pb-6">
        <div>
          <Typography className="font-bold">Jobs</Typography>
        </div>
        <div className="flex gap-4 items-center">
          <Button onClick={() => dispatch(openJobModal())} iconUrl="/assets/icons/icon_plus.svg">
            {' '}
            Create job
          </Button>
        </div>
      </div>
      <div className={`${error ? 'text-center text-xl font-medium' : ''}`}>
        {isLoading ? <TableLoader numRows={7} /> : error ? 'Error fetching jobs' : <JobsTable jobs={data.jobs} />}
      </div>

      <JobsModal isJobModalOpen={isJobModalOpen} refetchJobs={refetchJobs} />
    </div>
  );
};

export default Jobs;
