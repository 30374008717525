import { useGetPlacementByIdQuery, useUpdatePlacementMutation } from 'features/placementsSlice';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlacementCandidatesTable } from '../../molecules';
import { Button, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import { CreatePlacementFolder, SearchForCandidates } from '..';
import { TableLoader } from 'modules/shared/components/molecules';
import { toast } from 'react-toastify';
const PlacementDetails = () => {
  const { placementID } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedJob, setSelectedJob] = useState(null);
  // const [selectedJobId, setSelectedJobId] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [placementFolderData, setPlacementFolderData] = useState([]);
  const [step, setStep] = useState('CREATE_FOLDER');

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  const { data, isLoading, refetch: refetchPlacement } = useGetPlacementByIdQuery(placementID);
  const [updatePlacement, { isLoading: isLoadingUpdatePlacement }] = useUpdatePlacementMutation();

  const handleConfirmUpdatePlacement = async () => {
    const submissionData = {
      jobID: data[0].jobID,
      applicants: selectedRows,
      name: data[0].name,
      placement_name: data[0].placement_name,
      placement_max_number: placementFolderData.placement_max_number,
      created_on: data[0].created_on,
    };
    try {
      await updatePlacement({ placementId: placementID, data: submissionData }).unwrap();
      refetchPlacement();
      setSearchResults(null);
      setSelectedRows([]);
      closeModal();
      toast.success('Placement updated successfully');
    } catch (error) {
      const message = error?.data?.message || 'An unexpected error occurred. Please try again.';
      toast.error(message);
    }
  };

  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleUpdatePlacementClick = () => {
    if (searchResults) {
      setIsConfirmModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };
  const handleJobChange = (job, jobId) => {
    // setSelectedJob(job);
    // setSelectedJobId(jobId);
  };
  const handleSearchLoading = (isLoading) => {
    setSearchLoading(isLoading);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(false);
    // setSearchResults(null);
    setStep('CREATE_FOLDER');
  };
  const SearchForCandidatesForm = (values) => {
    setStep('SEARCH_CANDIDATES');
    setPlacementFolderData(values);
  };
  const onCancel = () => {
    closeModal();
    // setSearchResults(null);
  };

  if (isLoading) {
    return <TableLoader numRows={7} showButtons={true} />;
  }

  return (
    <div>
      <div className="flex text-center justify-between items-center gap-3 py-2 mb-4">
        <h3 className="text-xl text-neutral font-semibold">{data[0].name}</h3>
        {!searchResults ? (
          <Button
            classNames="text-primary  border-primary px-3 py-3 disabled:bg-white disabled:text-gray disabled:border-gray"
            onClick={handleUpdatePlacementClick}
          >
            Update Placement
          </Button>
        ) : (
          <Button
            classNames="text-primary  border-primary px-3 py-3 disabled:bg-white disabled:text-gray disabled:border-gray"
            onClick={handleUpdatePlacementClick}
            disabled={selectedRows.length === 0}
          >
            Add Candidates
          </Button>
        )}
      </div>
      <PlacementCandidatesTable
        data={searchResults ? searchResults.applicants : data[0]?.applicants}
        handleSelectedRowsChange={handleSelectedRowsChange}
        selectedRows={selectedRows}
        refetchPlacement={refetchPlacement}
        placementID={placementID}
        setSelectedRows={setSelectedRows}
        isSearchData={searchResults ? true : false}
        searchLoading={searchLoading}
      />
      <BasicModal
        title={step === 'CREATE_FOLDER' ? 'Update number of Candidates in the File' : 'Search For Matching Candidates'}
        isOpen={isModalOpen}
        onClose={closeModal}
        closeOnOverlayClick={false}
      >
        <div className="relative">
          <div
            className={`transition-opacity duration-500 ease-in-out ${
              step === 'CREATE_FOLDER' ? 'opacity-100' : 'opacity-0 pointer-events-none absolute inset-0'
            }`}
          >
            {step === 'CREATE_FOLDER' && (
              <CreatePlacementFolder
                handleFormSubmit={SearchForCandidatesForm}
                closeModal={closeModal}
                isUpdate={true}
                placementData={data[0]}
              />
            )}
          </div>

          <div
            className={`transition-opacity duration-500 ease-in-out ${
              step === 'SEARCH_CANDIDATES' ? 'opacity-100' : 'opacity-0 pointer-events-none absolute inset-0'
            }`}
          >
            {step === 'SEARCH_CANDIDATES' && (
              <SearchForCandidates
                closeModal={closeModal}
                setSearchResults={handleSearchResults}
                currentJob={data[0].name}
                isUpdating={true}
                currentJobId={data[0].jobID}
                passSearchLoaging={handleSearchLoading}
              />
            )}
          </div>
        </div>
      </BasicModal>
      <BasicModal isOpen={isConfirmModalOpen} onClose={closeModal}>
        <div className="flex flex-col gap-4 mb-8 items-center">
          <img src="/assets/icons/placement-popup.svg" alt="delete" />
          <p className="text-center text-xl font-semibold text-neutral mt-4 tracking-wide">Update Placement</p>
          <p className="text-center text-lg  text-neutral tracking-wide">
            The {data[0].placement_name} will be updated
          </p>
        </div>
        <div className="grid grid-cols-2 place-content-center mx-20">
          <div className="flex items-center justify-center px-4 cursor-pointer" onClick={onCancel}>
            <Typography variant="body" className="w-full text-center">
              Cancel
            </Typography>
          </div>
          <div>
            <Button
              type="submit"
              classNames="flex mx-auto bg-primary text-white border-none w-full"
              onClick={handleConfirmUpdatePlacement}
              disabled={isLoadingUpdatePlacement ? true : false}
            >
              Update
            </Button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default PlacementDetails;
