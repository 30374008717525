import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuNavLink = ({ iconUrl = '', url = '', text = '', classNames = 'py-4 px-4' }) => {
  return (
    <NavLink
      to={url}
      className={({ isActive }) => {
        const activeBoxStyle = isActive ? 'shadow-sm-inset' : 'hover:shadow-sm-inset';
        return `flex gap-4  items-center justify-start rounded-md cursor-pointer ${activeBoxStyle} ${classNames}`;
      }}
      end
    >
      {({ isActive }) => {
        const imageCssFilterClasses = isActive ? 'filter-primary' : '';
        const activeClassNames = isActive ? 'text-primary' : ' text-neutral-100';
        return (
          <>
            {iconUrl && (
              <img
                src={iconUrl}
                // width={25}
                // height={25}
                className={` object-contain transition-all ${imageCssFilterClasses}`}
                alt=""
              />
            )}
            <p className={` transition-all ${activeClassNames} `}>{text}</p>
          </>
        );
      }}
    </NavLink>
  );
};

export default MenuNavLink;
