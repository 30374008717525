import {
  Dropdown,
  RangeSlider,
  SearchInput,
  Select,
  SwitchButton,
  TextInputCounter,
  Typography,
} from 'modules/shared/components/atoms';
import { ImageUploader } from 'modules/shared/components/molecules';
import React, { useRef, useState } from 'react';

const CarrerResumes = () => {
  const [rangeValue, setRangeValue] = useState(20);
  const [selectedJobTemplate, setSelectedJobTemplate] = useState(null);

  const statusTemplateRef = useRef(null);
  const candidateTemplateRef = useRef(null);
  const selectRefOne = useRef(null);
  const selectRefTwo = useRef(null);
  const selectRefThree = useRef(null);
  const selectRefFour = useRef(null);
  const selectRefFive = useRef(null);
  const selectRefSix = useRef(null);

  const brandedMenuItems = [
    { key: 1, value: 'LinkedIn clinet' },
    { key: 2, value: 'Twitter clinet' },
    { key: 3, value: 'facebook clinet' },
    { key: 4, value: 'vodafone clinet' },
  ];

  const handleRangeChange = (newValue) => {
    // Handle the value change in the App component if needed
    setRangeValue(newValue);
  };
  return (
    <div className="px-10  grid gap-16">
      <div className="grid grid-cols-2  ">
        <Typography className="font-semibold text-xl tracking-widest">Career page & resume</Typography>
        <SearchInput label="search for your needs" />
      </div>
      <div className="grid gap-4">
        <Typography variant="mainCardTitle">Career page</Typography>
        <article className="w-full py-6 shadow-lg rounded-md px-10  ">
          <Typography className="font-semibold text-xl tracking-widest">status</Typography>
          <article className="grid items-center grid-cols-3 ">
            <Typography variant="inputLabel" className="col-span-2 text-neutral tracking-wider">
              Enable the career page feature to gain access to your very own customizable career page to showcase your
              openings and collect applications.
            </Typography>
            <SwitchButton classNames="justify-self-end" />
          </article>
        </article>
      </div>
      <div className="grid gap-4">
        <Typography variant="mainCardTitle">Resumes</Typography>
        <article className="w-full py-6 shadow-lg rounded-md px-10  ">
          <Typography className="font-semibold text-xl tracking-widest">status</Typography>
          <article className="flex items-center ">
            <Typography variant="inputLabel" className="col-span-2 text-neutral tracking-wider">
              Any change will be applied to newly created candidates only and will not change the default resume setting
              for existing candidates.
            </Typography>
            <div className="">
              <Select
                classNames="min-w-[120px]"
                labelClassNames="text-base leading-[3.5] tracking-wider"
                selectRef={statusTemplateRef}
                name="job_templage"
                label="Branded "
                value={selectedJobTemplate}
                setFieldValue={() => setSelectedJobTemplate(1)}
                options={brandedMenuItems}
              />
            </div>
          </article>
        </article>
        <article className="w-full grid gap-8 py-8 shadow-lg rounded-md px-10  ">
          <article>
            <Typography className="font-semibold text-xl tracking-widest">Branded resume</Typography>
            <Typography variant="inputLabel" className="text-neutral tracking-wider">
              The branded resume is an altered version of the original.
            </Typography>
          </article>
          <article className="grid items-center grid-cols-2 ">
            <Typography variant="inputLabel" className="col-span-1 text-neutral tracking-wider">
              Watermark
            </Typography>
            <SwitchButton classNames="justify-self-end" />
          </article>
          <article className="grid items-center grid-cols-2 ">
            <Typography variant="inputLabel" className="col-span-1 text-neutral tracking-wider">
              Image as watermark
            </Typography>
            <SwitchButton classNames="justify-self-end" />
          </article>
          <article className="grid items-center grid-cols-4 ">
            <Typography variant="inputLabel" className=" text-neutral tracking-wider">
              Watermark text
            </Typography>
            <div className="col-span-3">
              <TextInputCounter onChange={() => {}} onBlur={() => {}} label="Type your text here" />
            </div>
          </article>
          <Typography className="font-semibold text-xl tracking-widest grid text-center">Edit resume</Typography>
          <article className="bg-white-100 ">
            <div className="border-b-2 border-dashed border-neutral-300  ">
              <ImageUploader
                classNames={'bg-white-100  w-28 h-28'}
                iconUrl="/assets/icons/upload_logo.svg"
                iconClassNames="w-8 right-10 top-6"
                text="Upload your logo"
                textClassName="text-neutral-100 text-base right-0 -bottom-0 w-22 tracking-wide"
              />
            </div>
            <div className="w-full grid place-items-center    h-56">
              <p className="text-neutral-200 text-xl  px-4 tracking-widest w-72 origin-center -rotate-30">
                Your watermark will appear here
              </p>
            </div>
            <div className="grid place-items-center border-b-2 border-dashed border-neutral-300 pb-6  ">
              <p className="text-black text-sm tracking-widest w-72 origin-center ">Opacity: ( {rangeValue}%)</p>
              <RangeSlider defaultvalue={rangeValue} onChange={handleRangeChange} />
            </div>
            <div className="w-full flex items-center place-content-center gap-2 pt-4 pb-20"></div>
          </article>
        </article>
        <article className="w-full grid gap-8 py-8 shadow-lg rounded-md px-10  ">
          <article>
            <Typography className="font-semibold text-xl tracking-widest">Custom resume</Typography>
            <Typography variant="inputLabel" className="text-neutral tracking-wider w-3/4">
              Any change will be applied to newly created candidates only and will not change the default resume setting
              for existing candidates.
            </Typography>
          </article>
          <article className="grid items-center grid-cols-4 ">
            <Typography variant="inputLabel" className="tracking-wider">
              Watermark text
            </Typography>
            <div className="col-span-3">
              <TextInputCounter onChange={() => {}} onBlur={() => {}} label="Type your text here" />
            </div>
          </article>
          <article className="grid items-center grid-cols-4 ">
            <Typography variant="inputLabel" className="tracking-wider">
              Watermark text
            </Typography>
            <div className="col-span-3">
              <TextInputCounter onChange={() => {}} onBlur={() => {}} label="Type your text here" />
            </div>
          </article>
          <article className="grid items-center grid-cols-4 ">
            <Typography variant="inputLabel" className=" tracking-wider">
              Watermark text
            </Typography>
            <div className="col-span-3">
              <TextInputCounter onChange={() => {}} onBlur={() => {}} label="Type your text here" />
            </div>
          </article>
          <article className="grid items-center grid-cols-4 ">
            <Typography variant="inputLabel" className="tracking-wider">
              Watermark text
            </Typography>
            <div className="col-span-3">
              <TextInputCounter onChange={() => {}} onBlur={() => {}} label="Type your text here" />
            </div>
          </article>
          <article className="grid items-center grid-cols-2 ">
            <Typography variant="inputLabel" className="col-span-1 text-neutral tracking-wider">
              Watermark
            </Typography>
            <SwitchButton classNames="justify-self-end" />
          </article>
          <article className="grid items-center grid-cols-2 ">
            <Typography variant="inputLabel" className="col-span-1 text-neutral tracking-wider">
              Image as watermark
            </Typography>
            <SwitchButton classNames="justify-self-end" />
          </article>
          <article className="grid items-center grid-cols-3 ">
            <Typography variant="inputLabel" className="col-span-2  tracking-wider">
              Default template
            </Typography>
            <div className=" grid place-content-end">
              <Select
                classNames="min-w-[120px]"
                labelClassNames="text-base leading-[3.5] tracking-wider"
                selectRef={candidateTemplateRef}
                name="job_templage"
                label="Classic"
                value={selectedJobTemplate}
                setFieldValue={() => setSelectedJobTemplate(1)}
                options={brandedMenuItems}
              />
            </div>
          </article>
          <Typography className="font-semibold text-xl tracking-widest grid text-center">Edit resume</Typography>
          <article className="bg-white-100 ">
            <div className="border-b-2 border-dashed border-neutral-300 grid grid-cols-4 py-4">
              <ImageUploader
                onImageChange={(file) => {}}
                classNames={'bg-white-100  w-28 h-28'}
                iconUrl="/assets/icons/upload_logo.svg"
                iconClassNames="w-8 right-10 top-6"
                text="Upload your logo"
                textClassName="text-neutral-100 text-base right-0 -bottom-0 w-22 tracking-wide"
              />
              <div className="grid grid-cols-2 col-span-3 px-2">
                <div className="grid gap-2">
                  <div className="bg-white">
                    <Select
                      labelClassNames="text-base leading-[3.5] tracking-wider"
                      selectRef={selectRefOne}
                      name="job_templage"
                      label="Candidate full name"
                      value={selectedJobTemplate}
                      setFieldValue={() => setSelectedJobTemplate(1)}
                      options={brandedMenuItems}
                    />
                  </div>

                  <div className="bg-white">
                    <Select
                      labelClassNames="text-base leading-[3.5] tracking-wider"
                      selectRef={selectRefTwo}
                      name="job_templage"
                      label="Candidate current position"
                      value={selectedJobTemplate}
                      setFieldValue={() => setSelectedJobTemplate(1)}
                      options={brandedMenuItems}
                    />
                  </div>

                  <div className="bg-white">
                    <Select
                      labelClassNames="text-base leading-[3.5] tracking-wider"
                      selectRef={selectRefThree}
                      name="job_templage"
                      label="Candidate current employer"
                      value={selectedJobTemplate}
                      setFieldValue={() => setSelectedJobTemplate(1)}
                      options={brandedMenuItems}
                    />
                  </div>
                </div>
                <div className="grid gap-1 px-2">
                  <div className="bg-white">
                    <Select
                      labelClassNames="text-base leading-[3.5] tracking-wider"
                      selectRef={selectRefFour}
                      name="job_templage"
                      label="Recruiter full name"
                      value={selectedJobTemplate}
                      setFieldValue={() => setSelectedJobTemplate(1)}
                      options={brandedMenuItems}
                    />
                  </div>

                  <div className="bg-white">
                    <Select
                      labelClassNames="text-base leading-[3.5] tracking-wider"
                      selectRef={selectRefFive}
                      name="job_templage"
                      label="Recruiter email address"
                      value={selectedJobTemplate}
                      setFieldValue={() => setSelectedJobTemplate(1)}
                      options={brandedMenuItems}
                    />
                  </div>

                  <div className="bg-white">
                    <Select
                      labelClassNames="text-base leading-[3.5] tracking-wider"
                      selectRef={selectRefSix}
                      name="job_templage"
                      label="SRecruiter phone number"
                      value={selectedJobTemplate}
                      setFieldValue={() => setSelectedJobTemplate(2)}
                      options={brandedMenuItems}
                    />
                  </div>
                </div>
              </div>
              <div className=""></div>
            </div>
            <div className="w-full grid place-items-center    h-56">
              <p className="text-neutral-200 text-xl  px-4 tracking-widest w-72 origin-center -rotate-30">
                Your watermark will appear here
              </p>
            </div>
            <div className="grid place-items-center border-b-2 border-dashed border-neutral-300 pb-6  ">
              <p className="text-black text-sm tracking-widest w-72 origin-center ">Opacity: ( {rangeValue}%)</p>
              <RangeSlider defaultvalue={rangeValue} onChange={handleRangeChange} />
            </div>
            <div className="w-full flex items-center place-content-center gap-2 pt-4 pb-20">
              <img src="/assets/icons/pencil.svg" alt="pencil" />
              <p className="text-neutral-100 text-sm tracking-widest">You can add your words here .</p>
            </div>
          </article>
        </article>
      </div>
    </div>
  );
};

export default CarrerResumes;
