import React from 'react';
import Avatar from '../../atoms/Avatar';

const Notification = ({ avatarUrl = '', name = '', content = '', notificationTime = '' }) => {
  const firstLetter = name[0];
  return (
    <article className="flex items-center gap-4">
      <Avatar avatarUrl={avatarUrl} alt={name} />
      <div className="flex flex-col gap-1">
        <p className="text-base font-semibold">{content}</p>
        <p className="text-sm">{notificationTime}</p>
      </div>
    </article>
  );
};

export default Notification;
