import DataTable from 'react-data-table-component';
import { Avatar } from 'modules/homepage/components/atoms';

const data = [
  {
    id: 1,
    client_name: {
      name: 'Linkedin',
      avatar: '/assets/icons/avatar.png',
    },
    owner: {
      job_title: 'Software Engineer',
      name: 'Amgad Mohamed',
    },
    team: 'Finance',
    created_date: '4 days ago ( 12/ 8/2023 )',
  },
  {
    id: 1,
    client_name: {
      name: 'Linkedin',
      avatar: '/assets/icons/avatar.png',
    },
    owner: {
      job_title: 'Software Engineer',
      name: 'Amgad Mohamed',
    },
    team: 'Finance',
    created_date: '4 days ago ( 12/ 8/2023 )',
  },
  {
    id: 1,
    client_name: {
      name: 'Linkedin',
      avatar: '/assets/icons/avatar.png',
    },
    owner: {
      job_title: 'Software Engineer',
      name: 'Amgad Mohamed',
    },
    team: 'Finance',
    created_date: '4 days ago ( 12/ 8/2023 )',
  },
  {
    id: 1,
    client_name: {
      name: 'Linkedin',
      avatar: '/assets/icons/avatar.png',
    },
    owner: {
      job_title: 'Software Engineer',
      name: 'Amgad Mohamed',
    },
    team: 'Finance',
    created_date: '4 days ago ( 12/ 8/2023 )',
  },
  {
    id: 1,
    client_name: {
      name: 'Linkedin',
      avatar: '/assets/icons/avatar.png',
    },
    owner: {
      job_title: 'Software Engineer',
      name: 'Amgad Mohamed',
    },
    team: 'Finance',
    created_date: '4 days ago ( 12/ 8/2023 )',
  },
];

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400',
      fontSize: '14px',
    },
  },
};

const columns = [
  {
    name: 'Team',
    selector: (row) => row.team,
    cell: (row) => (
      <div className="flex items-center gap-2">
        <div className="">
          <Avatar classNames="w-8 h-8 font-normal" avatarUrl={row.avatar} alt={row.team} />
        </div>
        <p>{row.team}</p>
      </div>
    ),
    sortable: true,
    width: '200px',
  },
  {
    name: 'Owner',
    selector: (row) => row.owner.name,
    cell: (row) => (
      <div className="flex items-center gap-1.5">
        <p className="text-sm text-primary">{row.owner.name}</p>
        <p className="text-sm text-neutral-100">added themselves to the activit</p>
        <p className="text-sm text-primary tracking-widest">{row.owner.job_title}</p>
      </div>
    ),
    sortable: true,
    width: '500px',
  },

  { name: 'Date & time', selector: (row) => row.created_date, sortable: true },
];

const LogsTable = () => {
  if (data) {
    return (
      <div className="relative ">
        <DataTable columns={columns} data={data} selectableRowsHighlight customStyles={customStyles} />
      </div>
    );
  }
};

export default LogsTable;
