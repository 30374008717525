import React, { useState } from 'react';

const ToolTip = ({ buttonText, tooltipContent }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="">
      <p
        className={`text-white text-sm  text-center cursor-pointer`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {buttonText}
      </p>

      <div
        className={`absolute z-50 w-64 px-6 py-2 text-sm text-white bg-[#373636] rounded-sm`}
        style={{ display: isHovered ? 'block' : 'none' }}
      >
        {tooltipContent}
      </div>
    </div>
  );
};

export default ToolTip;
