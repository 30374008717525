import { Button, Tooltip } from 'modules/shared/components/atoms';
import { Formik, useFormik } from 'formik';
import { twMerge } from 'tailwind-merge';
import { CustomSelect } from 'modules/shared/components/molecules';

const preferredDate = [
  {
    id: 1,
    title: 'Preferred date format',
    inputName: 'Preferred Date',
    options: [
      {
        id: 1,
        label: 'YYYY - MM - DD',
        value: 'YYYY - MM - DD',
      },
      {
        id: 2,
        label: 'MM - DD - YYYY',
        value: 'MM - DD - YYYY',
      },
    ],
    description:
      'When selecting a preferred date format, all dates displayed within the system will show in that specific format in your account only.',
  },
  {
    id: 2,
    title: 'Preferred time format',
    inputName: 'Preferred Time ',
    options: [
      {
        id: 1,
        value: '24 Hours',
        label: '24 Hours',
      },
      {
        id: 2,
        value: '12 Hours',
        label: '12 Hours',
      },
    ],
    description:
      'When selecting a preferred date format, all dates displayed within your system will show in that specific format in your account only.',
  },
  {
    id: 3,
    title: 'Preferred currency ',
    inputName: 'Preferred currency',
    options: [
      {
        id: 1,
        label: 'US Dollar',
        value: 'US Dollar',
      },
      {
        id: 2,
        label: 'Euro',
        value: 'Euro',
      },
    ],
    description:
      'Selecting a preferred currency will bring-up that currency first when trying to enter a monetary value such as a salary. Changing the preferred currency will not convert any of the existing monetary entries in your account.',
  },
  {
    id: 4,
    title: 'Preferred language',
    inputName: 'Preferred language',
    options: [
      {
        id: 1,
        value: 'English',
        label: 'English',
      },
      {
        id: 2,
        value: 'Arabic',
        label: 'Arabic',
      },
    ],
    description: 'This language will be used for your system interface.',
  },
];

const styles = {
  control: {
    backgroundColor: '',
    borderRadius: '8px',
    outline: 'none',
    borderColor: '#99999980',
    padding: '4px 5px',
  },
};
const Preference = () => {
  const initialValues = {};
  preferredDate.forEach((item) => {
    initialValues[item.inputName] = '';
  });
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });
  const generateFormikConfig = (fieldName) => ({
    values: formik.values[fieldName],
    errors: formik.errors[fieldName],
    touched: formik.touched[fieldName],
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-20  pl-16 pr-32">
      {preferredDate.map((item) => (
        <article className={twMerge('shadow-xs w-auto px-10 py-4 rounded-md grid gap-10')}>
          <div className="flex gap-6 items-center">
            <h5 className="text-xl font-semibold text-primary tracking-widest">{item.title}</h5>
            <Tooltip
              buttonText={<img src="/assets/icons/circular_tranquility.svg" alt="line" className="my-4 mr-2" />}
              tooltipContent={item.description}
            />
          </div>
          <Formik key={item.id} onSubmit={formik.handleSubmit}>
            <form onSubmit={formik.handleSubmit} className="grid gap-8">
              <div className="flex items-center gap-8 pl-6">
                <label className="font-normal tracking-widest">{item.inputName}</label>
                <CustomSelect
                  styles={styles}
                  selectDefaultValue={item.options[0]}
                  error={generateFormikConfig(item.inputName).errors}
                  option={item.options}
                  isMulti={false}
                  label={`Select ${item.inputName} *`}
                  value={generateFormikConfig(item.inputName).values}
                  onBlur={generateFormikConfig(item.inputName).handleBlur}
                  onChange={(value) => formik.setFieldValue(item.inputName, value)}
                />
              </div>
              <Button type="submit" classNames="w-full text-white bg-primary border-none py-3">
                Save
              </Button>
            </form>
          </Formik>
        </article>
      ))}
    </div>
  );
};

export default Preference;
