import React from 'react';

const Activity = ({ note }) => {
  const { content, date, avatar } = note;
  return (
    <div className="p-4 border border-white-300 border-opacity-50  rounded-sm bg-white my-6 flex items-center">
      <img src={avatar} alt="Avatar" className="w-12 h-12 rounded-full mr-2" />
      <div>
        <p className="text-lg opacity-70 mb-2">{content}</p>
        <p className="text-gray opacity-75">{date}</p>
      </div>
    </div>
  );
};

export default Activity;
