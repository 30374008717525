import React, { useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, DropFileInput, Select } from 'modules/shared/components/atoms';
import { toast } from 'react-toastify';
import { useUploadFileMutation as uploadFileMutation } from 'features/jobsSlice';

const validationSchema = Yup.object().shape({
  resumeFiles: Yup.array().min(1, 'Please upload at least one file'),
});

const initialValues = {
  resumeFiles: [],
};

const UploadResumeJob = ({ refetch, closeModal }) => {
  const jobTemplateRef = useRef(null);
  const [uploadResume, { isLoading }] = uploadFileMutation();
  const onFileChange = (files, setFieldValue) => {
    // Set the field value for 'resumeFiles'
    setFieldValue('resumeFiles', files);
  };

  const handleSubmit = async (values) => {
    const payload = new FormData();
    values.resumeFiles.forEach((file) => {
      payload.append('files', file);
    });
    try {
      await uploadResume(payload).unwrap();
      closeModal();
      setTimeout(() => {
        refetch();
      }, 200);
      toast.success('Jobs uploaded successfully');
    } catch (err) {
      if (err?.status === 400) {
        const errorMessage = err?.data?.message || 'Bad request. Please check your input and try again.';
        toast.error(errorMessage);
      } else {
        const errorMessage = err?.data?.message || 'An error occurred. Please try again.';
        toast.error(errorMessage);
      }
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ setFieldValue }) => (
        <Form className="grid gap-7 max-w-2xl mx-auto">
          <div>
            <Field name="resumeFiles">
              {({ field }) => (
                <>
                  <DropFileInput
                    {...field}
                    fileTypes=".csv, .json"
                    onFileChange={(files) => onFileChange(files, setFieldValue)}
                  />
                  <ErrorMessage name="resumeFiles" component="div" className="text-error" />
                </>
              )}
            </Field>
          </div>
          <Button
            type="submit"
            classNames="flex mx-auto bg-primary text-white border-none w-4/6"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UploadResumeJob;
