import { JobCard } from 'modules/adminstration/components/molecules';
import { SearchInput, Typography } from 'modules/shared/components/atoms';
import React from 'react';
const jobsData = [
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
  {
    jobImage: '/assets/images/Rectangle.png',
    title: 'Front End',
    type: 'programming',
    desc: 'Lorem ipsum dolor sit amet consectetur. Ullamcorper commod.',
  },
];
const index = () => {
  return (
    <div className="grid gap-12 mx-12 ">
      <div className="grid grid-cols-2 items-center">
        <Typography className="font-semibold text-xl tracking-widest">Integrations</Typography>
        <SearchInput label="Search for your integration" />
      </div>
      <div className="shadow-sm rounded-sm grid grid-cols-3 gap-10 p-12">
        {jobsData.map((job, inde) => (
          <div className="">
            <JobCard imageUrl={job.jobImage} title={job.title} type={job.type} desc={job.desc} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default index;
