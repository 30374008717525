import React from 'react';
import { PlacementCard } from '../../molecules';

const PlacementList = ({ placementsData, isLoading, refetchPlacements }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="grid grid-cols-4 gap-5">
      {placementsData?.map((placement) => (
        <PlacementCard
          refetchPlacements={refetchPlacements}
          JobName={placement?.placement_name}
          candidatesNumber={placement?.applicants.length}
          key={placement?.placementID}
          placementID={placement?.placementID}
        />
      ))}
    </div>
  );
};

export default PlacementList;
