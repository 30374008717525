import React from 'react';

const PackageDetails = ({ jobData }) => {
  return (
    <div className=" p-6 rounded-md  shadow-xs">
      <h3 className="text-xl font-medium mb-4">Package Details</h3>
      <ul className="space-y-4">
        <li className="flex items-center">
          {/* Change location icon when we merge candidate profile */}
          <img src="/assets/icons/AttachFile.png" alt="" />
          {jobData.location}
        </li>
        <li className="flex items-center">
          <img src="/assets/icons/bx-dollar.png" alt="" />
          Salary from {jobData.max_salary}$
        </li>
        {/* 
          <li className="flex items-center">
          <img src="/assets/icons/AttachFile.png" alt="" />
          Contract for 1 year
          </li>
          */}
      </ul>
      <div className="mt-6 flex gap-5 w-full justify-between">
        <span className="px-7 py-2 rounded-md bg-error text-error bg-opacity-10 opacity-70 font-medium text-lg text-center">
          {jobData.position_type}
        </span>
      </div>
    </div>
  );
};

export default PackageDetails;
