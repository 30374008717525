const clientIndustries = [
  { key: 1, value: 'Aerospace' },
  { key: 2, value: 'Agriculture' },
  { key: 3, value: 'Automotive' },
  { key: 4, value: 'Banking' },
  { key: 5, value: 'Biotechnology' },
  { key: 6, value: 'Chemicals' },
  { key: 7, value: 'Construction' },
  { key: 8, value: 'Consumer Goods' },
  { key: 9, value: 'Education' },
  { key: 10, value: 'Electronics' },
  { key: 11, value: 'Energy' },
  { key: 12, value: 'Engineering' },
  { key: 13, value: 'Entertainment' },
  { key: 14, value: 'Environmental' },
  { key: 15, value: 'Fashion' },
  { key: 16, value: 'Financial Services' },
  { key: 17, value: 'Food and Beverage' },
  { key: 18, value: 'Government' },
  { key: 19, value: 'Healthcare' },
  { key: 20, value: 'Hospitality' },
  { key: 21, value: 'Information Technology (IT)' },
  { key: 22, value: 'Insurance' },
  { key: 23, value: 'Legal Services' },
  { key: 24, value: 'Manufacturing' },
  { key: 25, value: 'Media' },
  { key: 26, value: 'Medical Sector' },
  { key: 27, value: 'Mining' },
  { key: 28, value: 'Non-profit' },
  { key: 29, value: 'Pharmaceuticals' },
  { key: 30, value: 'Real Estate' },
  { key: 31, value: 'Retail' },
  { key: 32, value: 'Telecommunications' },
  { key: 33, value: 'Transportation' },
  { key: 34, value: 'Utilities' },
  { key: 35, value: 'Wholesale' },
  { key: 36, value: 'Other' },
];

export default clientIndustries;
