import React from 'react';
import { twMerge } from 'tailwind-merge';

const Avatar = ({ alt = '', avatarUrl = '', classNames = '', avaterClassNames = '' }) => {
  const firstLetter = alt[0];
  return (
    <div>
      {avatarUrl ? (
        <img
          className={twMerge(`rounded-full w-12 h-12 object-fill bg-secondary`, avaterClassNames)}
          src={avatarUrl}
          alt={alt}
        />
      ) : (
        ''
      )}
      {!avatarUrl ? (
        <span
          className={twMerge`flex items-center justify-center text-white text-base font-semibold rounded-full w-12 h-12 object-fill bg-primary uppercase ${classNames}`}
        >
          {firstLetter}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export default Avatar;
