import { Avatar } from 'modules/homepage/components/atoms';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, DropFileInput, Select, Typography } from 'modules/shared/components/atoms';
import React, { useRef, useState } from 'react';
import './style.css';

const validationSchema = Yup.object().shape({
  jobTemplate: Yup.object().required('Candidate is required'),
  fileType: Yup.string().required('File type is required'),
  resumeFiles: Yup.array().min(1, 'Please upload at least one file'),
});

const DataImport = () => {
  const candidateTemplateRef = useRef(null);
  const [selectedJobTemplate, setSelectedJobTemplate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  const onFileChange = (files, setFieldValue) => {
    setFieldValue('resumeFiles', files);
  };

  const handleSubmit = () => {};

  const filesTypes = [
    { label: 'JSON', id: '1' },
    { label: 'CSV', id: '2' },
  ];

  return (
    <div className="grid gap-8 px-10">
      <Typography className="font-semibold text-xl tracking-widest">Data import</Typography>
      <Formik
        initialValues={{ jobTemplate: '', fileType: null, resumeFiles: null }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, handleChange }) => (
          <Form className="grid gap-5 shadow-sm p-10 rounded-sm">
            <Typography className="font-semibold text-2xl text-primary tracking-widest">
              Add a candidate to a job
            </Typography>
            <div className="grid gap-8 px-4">
              <p className="tracking-wider text-lg">Have them added to the job of your choice by sending an email.</p>
              <div className="flex gap-4 items-center">
                <Avatar classNames="w-5 h-5 font-normal" avatarUrl={''} alt={'1'} />
                <Typography className="font-semibold text-lg tracking-wider">Select a data set to upload</Typography>
              </div>
              <div className="flex gap-24 items-center">
                <div>
                  <Field name={'jobTemplate'}>
                    {({ field }) => (
                      <Select
                        {...field}
                        selectRef={candidateTemplateRef}
                        labelClassNames="text-base leading-[3.5] tracking-widest"
                        classNames={'w-80'}
                        setFieldValue={setFieldValue}
                        label="Candidate"
                        options={[
                          { key: 1, value: 'Job 1' },
                          { key: 2, value: 'Job 2' },
                          { key: 3, value: 'Job 3' },
                          { key: 4, value: 'Job 4' },
                        ]}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="jobTemplate" component="div" className="text-error text-sm mt-1" />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center">
                    {filesTypes.map(({ id, label }) => {
                      return (
                        <label className="flex items-center mr-4">
                          <Field
                            type="radio"
                            name="fileType"
                            value={id}
                            onChange={(e) => setFieldValue('fileType', e.target.value)}
                            checked={values.fileType === id}
                            className="mr-2 focus:ring-none radio-button"
                          />
                          {label}
                        </label>
                      );
                    })}
                  </div>
                  <ErrorMessage name="fileType" component="div" className="text-error text-sm mt-1" />
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <Avatar classNames="w-5 h-5 font-normal" avatarUrl={''} alt={'2'} />
                <Typography className="font-semibold text-lg tracking-wider">Select a file to upload</Typography>
              </div>
              <Field
                name="resumeFiles"
                render={({ field, form }) => (
                  <DropFileInput
                    classNames="w-full h-60"
                    fileTypes=".CSV"
                    onFileChange={(files) => {
                      form.setFieldValue(field.name, files);
                    }}
                  />
                )}
              />

              <ErrorMessage name="resumeFiles" component="div" className="text-error text-sm mt-1 z-50" />
              <Button type="submit" classNames="bg-primary border-none w-32 text-white place-self-end">
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <article className="grid gap-5 shadow-sm p-10 rounded-sm">
        <Typography className="font-semibold text-2xl text-primary tracking-widest">History</Typography>
        <div className="grid w-full text-center items-center">
          <img src="/assets/icons/file_import.svg" alt="import" className="mx-auto my-8" />
          <Typography className=" font-semibold text-xl tracking-wide">No imports found</Typography>
          <p className="tracking-widest text-lg px-24">
            No imports have been made yet. All imports will be displayed on this page once the first import has been
            made
          </p>
        </div>
      </article>
    </div>
  );
};

export default DataImport;
