import { useDeleteCandidateMutation, useFetchCandidatesQuery } from 'features/candidatesSlice';
import { Button, Typography, UnixTimestampConverter } from 'modules/shared/components/atoms';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { BasicModal, TableLoader } from 'modules/shared/components/molecules';
import { CreateNewCandidateForm } from '..';
import { useNavigate } from 'react-router-dom';
const CustomTableCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="checkbox"
          className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[3px] bg-white border border-neutral-200 gradient-border-checkbox outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-1 checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer focus:shadow-none  focus:before:scale-100 focus:before:opacity-[0.12]   focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent dark:border-neutral-600 dark:checked:border-primary dark:checked:bg-primary"
          style={{ height: '20px', width: '20px' }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
      </div>
    </>
  );
});

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0 ',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400',
      fontSize: '14px',
      padding: '0px',
    },
  },
  cells: {
    style: {
      padding: '10px 0px', // Added padding top and bottom as requested
    },
  },
};

const CandidatesTable = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [candidateId, setCandidateId] = useState(null);
  const [candidatesData, setCandidatesData] = useState([]);
  const navigate = useNavigate();

  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };
  const {
    data,
    error: candidatesError,
    isLoading: candidatesIsLoading,
    refetch: refetchCandidates,
  } = useFetchCandidatesQuery({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    if (data) {
      setCandidatesData(data.applicants);
    }
  }, [data]);
  const [deleteCandidate, { isLoading: isDeleteCandidateLoading }] = useDeleteCandidateMutation();
  const handleDeleteSelectedCandidates = async () => {
    const ids = selectedRows.map((row) => row.appID);
    const payload = { applicants: ids };

    try {
      await deleteCandidate(payload).unwrap();
      setToggleCleared(!toggleCleared);
      setSelectedRows([]);
      refetchCandidates();
      setIsDeleteModalOpen(false);
      toast.success('Candidates deleted successfully');
    } catch (err) {
      const {
        data: { message },
      } = err;
      toast.error(message);
    }
  };

  const handleEditSelectedCandidate = (row) => {
    setCandidateId(row.appID);
    openModal();
  };

  const openModal = () => {
    setIsModalOpened(true);
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };
  const closeModal = () => {
    setIsModalOpened(false);
    setSelectedRows([]);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((selectedRow) => selectedRow.appID === row.appID),
      style: {
        backgroundColor: '#F4F4F4',
      },
    },
  ];

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.first_name,
      sortable: true,
      width: '170px',
      cell: (row) => (
        <div
          className="flex text-center items-center gap-2 pl-0 cursor-pointer"
          onClick={() => navigate(`/dashboard/candidates/${row.appID}`)}
        >
          <p className="text-left">
            {' '}
            {row.first_name} {row.last_name}
          </p>
        </div>
      ),
    },
    {
      name: 'Current Position',
      selector: (row) => row.current_position,
      sortable: true,
      width: '130px',
    },
    { name: 'Location', selector: (row) => row.location, sortable: true, width: '110px' },
    { name: 'Current Employer', selector: (row) => row.current_employer, sortable: true, width: '150px' },
    {
      name: 'Experience',
      cell: (row) => (
        <div className="flex flex-wrap text-center items-center gap-2 pl-0">
          {Array.isArray(row.experience) && row.experience.length > 0
            ? row.experience.map((exp, index) => (
                <p key={index} className="text-left">
                  {exp.title}
                </p>
              ))
            : ''}
        </div>
      ),
      sortable: true,
      width: '150px',
    },
    {
      name: 'Notice Period',
      selector: (row) => row.notice_period,
      sortable: true,
      width: '120px',
      cell: (row) => <div className="w-full text-center">{row.notice_period}</div>,
    },
    { name: 'Current Salary', selector: (row) => row.current_salary, sortable: true, width: '120px' },
    { name: 'Expected Salary', selector: (row) => row.expected_salary, sortable: true, width: '150px' },
    { name: 'Resume Summary', selector: (row) => row.resume_summary, sortable: true, width: '150px' },
    { name: 'Email', selector: (row) => row.email, sortable: true, width: '150px' },
    { name: 'Phone', selector: (row) => row.phone, sortable: true, width: '120px' },
    {
      name: 'Created On',
      selector: (row) => <p>{<UnixTimestampConverter timestamp={row.created_on} />}</p>,
      sortable: true,
      width: '135px',
      style: { padding: '0px' },
    },
  ];

  if (candidatesError) return <div>Error occurred: {candidatesError.message}</div>;
  if (candidatesIsLoading) return <TableLoader numRows={7} />;

  return (
    <div className="relative">
      <DataTable
        columns={columns}
        data={candidatesData}
        progressPending={candidatesIsLoading}
        onSelectedRowsChange={handleSelectedRowsChange}
        selectableRowsHighlight
        conditionalRowStyles={conditionalRowStyles}
        selectableRows
        pagination
        customStyles={customStyles}
        selectableRowsComponent={CustomTableCheckbox}
        clearSelectedRows={toggleCleared}
      />
      {selectedRows.length > 0 && (
        <Button
          classNames={`bg-primary text-white border-none text-sm tracking-wider py-2 px-4 bottom-2 absolute ${
            selectedRows?.length === 0 ? 'hidden' : ''
          }`}
          iconUrl="/assets/icons/folder_delete2.svg"
          iconClassNames="filter-white "
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete
        </Button>
      )}
      {selectedRows.length === 1 && (
        <Button
          classNames="bg-primary text-white border-none text-sm tracking-wider py-2 px-4 bottom-2 left-28 absolute"
          onClick={() => handleEditSelectedCandidate(selectedRows[0])}
        >
          Edit
        </Button>
      )}
      <BasicModal isOpen={isModalOpened} onClose={closeModal}>
        <CreateNewCandidateForm
          closeModal={closeModal}
          candidateId={candidateId}
          isEditing={true}
          selectableRows={setSelectedRows}
          refetch={refetchCandidates}
        />
      </BasicModal>
      <BasicModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
        <div className="flex flex-col items-center justify-center gap-5 mb-5">
          <img src="/assets/icons/delete-placement-icon.svg" alt="" />
          <p className="text-xl font-semibold text-center text-neutral">Delete Candidates</p>
          <p className="text-lg text-center text-neutral mx-5">
            Are you sure you want to delete the selected Candidates?
          </p>
        </div>
        <div className="grid grid-cols-2 place-content-center mx-20">
          <div className="flex items-center justify-center px-4 cursor-pointer" onClick={closeDeleteModal}>
            <Typography variant="body" className="w-full text-center">
              Cancel
            </Typography>
          </div>
          <div>
            <Button
              type="submit"
              classNames="flex mx-auto bg-primary text-white border-none w-full"
              onClick={handleDeleteSelectedCandidates}
              disabled={isDeleteCandidateLoading ? true : false}
              isLoading={isDeleteCandidateLoading}
            >
              Delete
            </Button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default CandidatesTable;
