import React from 'react';
import { Checkbox, Typography } from 'modules/shared/components/atoms';
const ActivityItem = () => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <Checkbox value="" name="terms">
          <Typography className="text-base text-black">My Activities</Typography>
        </Checkbox>
      </div>
      <div className="flex justify-between items-center gap-2">
        <div>
          <Typography className="text-sm text-black">2023-09-29 . 8:48 Am</Typography>
        </div>
        <div className="cursor-pointer">
          <img src="/assets/icons/pencil.svg" alt="edit" />
        </div>
        <div className="cursor-pointer">
          <img src="/assets/icons/trash.svg" alt="delete" />
        </div>
      </div>
    </div>
  );
};

export default ActivityItem;
