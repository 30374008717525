import { useCreateJobMutation, useFetchJobsQuery, useGetJobByIdQuery, useUpdateJobMutation } from 'features/jobsSlice';
import { useFetchClientsQuery } from 'features/clientsSlice';
import { Field, Form, Formik } from 'formik';
import { Button, Select, SelectTwo, TextInput, TextInputCounter, Typography } from 'modules/shared/components/atoms';
import { jobCategories } from 'modules/jobs/data';
import { useRef } from 'react';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';
import { Loader } from 'modules/shared/components/atoms';
const validationSchema = Yup.object({
  name: Yup.string().required('Position name is required'),
  description: Yup.string().required('Description is required'),
  min_salary: Yup.number()
    .transform((value, originalValue) => (isNaN(originalValue) ? NaN : value))
    .min(0, 'Minimum salary must be a positive number')
    .required('Minimum salary is required'),
  max_salary: Yup.number()
    .transform((value, originalValue) => (isNaN(originalValue) ? NaN : value))
    .when('min_salary', (minSalary, schema) => {
      return schema.min(minSalary, 'Maximum salary must be greater than or equal to minimum salary');
    })
    .required('Maximum salary is required'),
  experience: Yup.number().min(0, 'Experience must be a positive number'),
  location: Yup.string().required('Location is required'),
  salary_currency: Yup.string().required('Salary currency is required'),
  salary_frequence: Yup.string().required('Salary frequency is required'),
  key_responsibilities: Yup.string().required('Key responsibilities are required'),
  position_type: Yup.string().required('Position type is required'),
  stage: Yup.string(),
  // is_remote: Yup.boolean(),
  skills: Yup.string(),
  qualifications: Yup.string(),
  category: Yup.string().required('Job category is required'),
});

const JobForm = ({ refetch, closeModal, jobId, isEditing = false, templateObjectData }) => {
  const selectRefOne = useRef(null);
  const selectRefTwo = useRef(null);
  const selectRefThree = useRef(null);
  const selectRefFour = useRef(null);
  const selectRefFive = useRef(null);
  const selectRefSix = useRef(null);
  const [createJobMutation, { data, error, isLoading: isCreateJobLoading }] = useCreateJobMutation();
  const [updateJob, { isLoading: updateJobLoading, isError, isSuccess }] = useUpdateJobMutation();
  const {
    data: clientsData,
    refetch: refetchClients,
    isloading,
    error: clientsError,
  } = useFetchClientsQuery({
    page: 1,
    limit: 10,
  });
  const clients = clientsData
    ? clientsData.clients.map((client) => {
        return {
          key: client.clientID,
          value: client.client_contact_email,
        };
      })
    : [];
  const shouldFetch = jobId ? jobId : null;
  const {
    data: jobData,
    isLoading: isJobLoading,
    refetch: refetchJob,
  } = useGetJobByIdQuery(jobId, { skip: !shouldFetch });
  const { data: jobsData, isLoading, refetch: refetchJobs } = useFetchJobsQuery({ page: 1, limit: 10 });

  const templateData = templateObjectData ? templateObjectData[0] : null;
  const dataToUse = isEditing && jobData ? jobData[0] : templateData;

  const initialValues = {
    name: dataToUse?.name || '',
    description: dataToUse?.description || '',
    min_salary: dataToUse?.min_salary || '',
    max_salary: dataToUse?.max_salary || '',
    experience: dataToUse?.experience || '',
    location: dataToUse?.location || '',
    salary_currency: dataToUse?.salary_currency || 'USD',
    salary_frequence: dataToUse?.salary_frequence || '',
    position_type: dataToUse?.position_type || '',
    stage: dataToUse?.stage || '',
    skills: dataToUse?.skills || '',
    qualifications: dataToUse?.qualifications || '',
    key_responsibilities: dataToUse?.key_responsibilities || '',
    created_on: dataToUse?.created_on || new Date().toISOString().split('T')[0], // Setting current date if not present
    category: dataToUse?.category || '',
    clientID: dataToUse?.clientID || '',
  };
  const handleSubmit = async (values) => {
    try {
      if (isEditing) {
        await updateJob({ jobId, data: values }).unwrap();
        toast.success('Job updated successfully');
        refetchJob();
      } else {
        await createJobMutation(values).unwrap();
        toast.success('Job created successfully');
      }
      closeModal();
      refetch();
      refetchJobs();
    } catch (err) {
      const {
        data: { message },
      } = err;
      toast.error(message);
    }
  };

  if (isJobLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  return (
    <div className="flex flex-col gap-6">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          const { key = '' } = values?.clientID;
          const payload = {
            ...values,
            clientID: key,
          };
          return handleSubmit(payload, formikHelpers);
        }}
      >
        {({ setFieldValue, values, handleChange, handleBlur, errors, touched, isSubmitting, isValid }) => {
          return (
            <Form className="flex flex-col  gap-6">
              <div>
                <TextInputCounter
                  MaximumLength={100}
                  label="Position name *"
                  name="name"
                  type="text"
                  error={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                <Typography variant="errorText">{errors.name && touched.name ? errors.name : ''}</Typography>
              </div>
              <div>
                <SelectTwo
                  selectRef={selectRefSix}
                  error={errors.clientID && touched.clientID}
                  setFieldValue={setFieldValue}
                  name="clientID"
                  label="Select client: *"
                  value={values.clientID}
                  onChangeHandler={handleChange}
                  options={clients}
                />
                <Typography variant="errorText">
                  {errors.clientID && touched.clientID ? errors.clientID : ''}
                </Typography>
              </div>
              <div>
                <TextInput
                  label="Minimum Salary *"
                  name="min_salary"
                  error={touched.min_salary && errors.min_salary}
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.min_salary}
                />
                <Typography variant="errorText">
                  {errors.min_salary && touched.min_salary ? errors.min_salary : ''}
                </Typography>
              </div>
              <div>
                <TextInput
                  label="Maximum Salary *"
                  name="max_salary"
                  error={touched.max_salary && errors.max_salary}
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.max_salary}
                />
                <Typography variant="errorText">
                  {errors.max_salary && touched.max_salary ? errors.max_salary : ''}
                </Typography>
              </div>

              <div>
                <TextInput
                  label="Eperience"
                  name="experience"
                  error={touched.experience && errors.experience}
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.experience}
                />
                <Typography variant="errorText">
                  {errors.experience && touched.experience ? errors.experience : ''}
                </Typography>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-4 justify-between">
                  <div className="w-full">
                    <TextInput
                      classNames={`bg-white py-3${touched.location && errors.location ? 'border-error ' : ''}`}
                      id="location"
                      name="location"
                      type="text"
                      label="add location"
                      error={touched.location && errors.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.location}
                      disabled={values.is_remote}
                    />
                  </div>
                  {/* <div className=" items-center">
                <Checkbox onChange={handleChange} value={values.is_remote} name="is_remote">
                  {' '}
                  <span className="text-black">Remote</span>
                </Checkbox>
                </div>*/}
                </div>
                <div>
                  <Typography variant="errorText">
                    {touched.location && errors.location ? errors.location : ''}
                  </Typography>
                </div>
              </div>
              <div>
                <Select
                  selectRef={selectRefOne}
                  error={errors.position_type && touched.position_type}
                  setFieldValue={setFieldValue}
                  name="position_type"
                  label="Select position type:"
                  value={values.position_type}
                  onChangeHandler={handleChange}
                  options={[
                    { key: 1, value: 'Full-Time' },
                    { key: 2, value: 'Part-Time' },
                    { key: 3, value: 'Contract' },
                    { key: 4, value: 'Internship' },
                    { key: 4, value: 'Freelance' },
                  ]}
                />
                <Typography variant="errorText">
                  {errors.position_type && touched.position_type ? errors.position_type : ''}
                </Typography>
              </div>

              <div>
                <div>
                  <Select
                    selectRef={selectRefTwo}
                    setFieldValue={setFieldValue}
                    error={errors.salary_currency && touched.salary_currency}
                    name="salary_currency"
                    label="Salary Currency"
                    value={values.salary_currency}
                    onChangeHandler={handleChange}
                    options={[
                      { key: 1, value: 'USD' },
                      { key: 2, value: 'EUR' },
                      { key: 3, value: 'GPB' },
                      { key: 4, value: 'JPY' },
                      { key: 5, value: 'EGP' },
                    ]}
                  />
                </div>
                <Typography variant="errorText">
                  {errors.salary_currency && touched.salary_currency ? errors.salary_currency : ''}
                </Typography>
              </div>
              <div>
                <div>
                  <Select
                    selectRef={selectRefThree}
                    setFieldValue={setFieldValue}
                    error={errors.salary_frequence && touched.salary_frequence}
                    name="salary_frequence"
                    label="Salary Frequence"
                    value={values.salary_frequence.value}
                    onChangeHandler={handleChange}
                    options={[
                      { key: 1, value: 'Per Year' },
                      { key: 2, value: 'Per Month' },
                      { key: 3, value: 'Per Hour' },
                    ]}
                  />
                </div>
                <Typography variant="errorText">
                  {errors.salary_frequence && touched.salary_frequence ? errors.salary_frequence : ''}
                </Typography>
              </div>
              <div>
                <div className="h-11">
                  <TextInput
                    type="text"
                    error={errors.key_responsibilities && touched.key_responsibilities}
                    name="key_responsibilities"
                    label="Key Responsibilities"
                    value={values.key_responsibilities}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <Typography variant="errorText">
                  {errors.key_responsibilities && touched.key_responsibilities ? errors.key_responsibilities : ''}
                </Typography>
              </div>
              <div>
                <div>
                  <Select
                    selectRef={selectRefFour}
                    setFieldValue={setFieldValue}
                    name="stage"
                    label="Stage"
                    value={values.stage}
                    onChangeHandler={handleChange}
                    options={[
                      { key: 1, value: 'Newly Posted Active' },
                      { key: 2, value: 'Reviewing Applications' },
                      { key: 3, value: 'Conducting Interviews' },
                      { key: 4, value: ' Filled' },
                    ]}
                  />
                </div>
              </div>
              <div>
                <div>
                  <Select
                    selectRef={selectRefFive}
                    setFieldValue={setFieldValue}
                    error={errors.category && touched.category}
                    name="category"
                    label="Job Category"
                    value={values.category}
                    options={jobCategories}
                  />
                </div>
                <Typography variant="errorText">
                  {errors.category && touched.category ? errors.category : ''}
                </Typography>
              </div>
              <div className="flex items-center gap-4 justify-between">
                <div className="w-full">
                  <TextInput
                    name="skills"
                    type="text"
                    label="Skills"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.skills}
                  />
                </div>
              </div>
              <div>
                <div className="w-full">
                  <TextInput
                    name="qualifications"
                    type="text"
                    label="Qualifications"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.qualifications}
                  />
                </div>
              </div>
              <Field name="description">
                {({ field }) => (
                  <ReactQuill
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ size: [] }],
                        [{ align: ['right', 'center', 'justify'] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                      ],
                    }}
                    placeholder="Provide summary outlining the responsibilities..."
                    className={twMerge(
                      errors.description && touched.description ? 'border-error ' : 'border-neutral border-spacing-3',
                      'border rounded-md overflow-hidden relative',
                    )}
                    value={values.description}
                    onChange={(val) => {
                      const isEmpty = val.replace(/<\/?[^>]+(>|$)/g, '').trim() === '';
                      setFieldValue('description', isEmpty ? '' : val);
                    }}
                  />
                )}
              </Field>
              {errors.description && touched.description && (
                <div className="text-error text-sm mt-2">{errors.description}</div>
              )}
              <div className="flex justify-center">
                <Button
                  isLoading={isCreateJobLoading || updateJobLoading}
                  classNames={'w-full md:w-1/2 bg-primary text-white border-0'}
                  disabled={isSubmitting}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default JobForm;
