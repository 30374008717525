import React from 'react';

const SkillsCard = ({ skillsData }) => {
  return (
    <div className="p-5 bg-white shadow-lg border border-gray-100 rounded-lg mt-10">
      <h2 className="text-xl ">Skills</h2>
      <div className="flex flex-wrap mt-4">
        {skillsData.map((skill) => (
          <span className="px-3 py-1 m-1 border border-primary-100  rounded-lg">{skill}</span>
        ))}
      </div>
    </div>
  );
};

export default SkillsCard;
