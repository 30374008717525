import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

const DropFileInput = (props) => {
  const { fileTypes, onFileChange, classNames, tittle = 'Drag and drop files here' } = props;
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  return (
    <div className="flex flex-col py-6 px-16 bg-white rounded-lg gap-6 shadow-xs">
      <div
        ref={wrapperRef}
        className={`relative w-[400px] h-56 outline-dashed outline-2 outline-primary rounded-lg flex items-center justify-center mx-auto hover:opacity-60 ${classNames}`}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="text-center font-semibold p-4">
          <img className=" mx-auto " src="/assets/icons/uploadIcon.svg" alt="" />
          <p className="text-center snap-center text-base tracking-[.95px]  font-normal my-4">{tittle}</p>
          <p className="text-center snap-center text-base tracking-[.95px] text-gray   font-normal my-4">
            Supported file types (max 20MB):
            <br />
            {fileTypes}
          </p>
        </div>
        <input
          className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
          type="file"
          value=""
          onChange={onFileDrop}
          accept={fileTypes}
        />
      </div>
      {fileList.length > 0 ? (
        <div className=" flex flex-col  mt-5 border-none gap-2 ">
          {fileList.map((item, index) => (
            <div key={index} className="relative flex bg-white-100 p-3 rounded-md">
              <div className="">
                <p className="font-medium">{item.name}</p>
              </div>
              <span
                className=" bg-white-100 w-10 h-10 rounded-full flex items-center justify-center absolute right-3 top-1/2 	translate-y-[-50%] cursor-pointer"
                onClick={() => fileRemove(item)}
              >
                <img className="mx-auto" src="/assets/icons/deleteIcon.svg" alt="deleteIcon" />
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className="relative flex p-2 rounded-2xl mt-5  flex-col justify-center rounded-md h-12 bg-white-100">
          <div className=" flex flex-col justify-center  text-center items-center ">
            <p className="font-medium my-auto">No Selected File</p>
          </div>
        </div>
      )}
    </div>
  );
};

// DropFileInput.propTypes = {
//   onFileChange: PropTypes.func,
// };
DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};
export default DropFileInput;
