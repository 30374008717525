import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from 'features/authSlice';
import { useLoginMutation } from 'features/authApiSlice';
import { Link } from '@mui/material';
import { Button, Checkbox, TextInput, Typography } from 'modules/shared/components/atoms';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
const LoginForm = () => {
  const navigate = useNavigate();
  const [login, { isLoading, isError, isSuccess, data, error }] = useLoginMutation();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const { email: username, password } = values;
    try {
      const userData = await login({ username, password }).unwrap();
      dispatch(setCredentials({ ...userData }));
      navigate('/dashboard');
      toast.success('Login successful');
    } catch (err) {
      const {
        data: { message },
      } = err;
      toast.error(message);
    }
  };
  const initialValues = {
    email: '',
    password: '',
  };
  return (
    <div className="h-full shadow-sm flex flex-col items-center rounded-md px-20 py-8 bg-white">
      <article className="p-5 w-full">
        <div className="flex flex-col gap-6">
          <article className="text-center">
            <img className="inline-block" width={58} height={58} src="/logo.png" />
            <Typography htmlTag="h1" className="text-center" variant="hero">
              Name
            </Typography>
          </article>
          <article>
            <Typography htmlTag="h1" className="text-center" variant="hero">
              Welcome back !
            </Typography>
            <Typography variant="inputLabel" alignText="center" htmlTag="p">
              Login now to see your account updates
            </Typography>
          </article>
        </div>
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, handleChange, handleBlur, errors, touched }) => {
            return (
              <Form className="mt-2">
                <div className="flex flex-col gap-4 mt-7">
                  <div>
                    <TextInput
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      label="Email"
                      name="email"
                      type="text"
                      error={touched.email && errors.email}
                    />
                    <Typography variant="errorText">{errors.email && touched.email ? errors.email : ''}</Typography>
                  </div>
                  <div>
                    <TextInput
                      onChange={handleChange}
                      value={values.password}
                      onBlur={handleBlur}
                      label="Password"
                      name="password"
                      type="password"
                      error={touched.password && errors.password}
                    />
                    <Typography variant="errorText">
                      {errors.password && touched.password ? errors.password : ''}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-between text-center my-2">
                  {/* <div>
                    <Checkbox value="" name="terms">
                      <Typography variant="inputLabel">Remember me</Typography>
                    </Checkbox>
                  </div> */}
                  <div>
                    <Link href="/forgot-password">Forgot Password</Link>
                  </div>
                </div>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                  classNames="flex mx-auto bg-primary text-white border-none w-full"
                >
                  Login
                </Button>
                <div className="text-center">
                  <Link href="#" variant="body2">
                    Sign in with SSO
                  </Link>
                </div>
                <div className="text-center mt-4">
                  <div className="inline-block shadow-xs p-6 rounded-md">
                    <img className="inline-block" width={22} height={17} src="/assets/icons/sso_login.svg" />
                  </div>
                </div>
                <div className="flex justify-center gap-2 my-6">
                  <p className="text-base">Don’t have an account</p>
                  <Link className="text-primary text-base tracking-wide inline-block" href="/signup">
                    ? Sign up
                  </Link>{' '}
                  {'.'}
                </div>
              </Form>
            );
          }}
        </Formik>
      </article>
    </div>
  );
};

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
});

export default LoginForm;
