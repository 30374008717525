const PercentageBar = ({ percentage }) => {
  return (
    <div className="w-36 mx-auto flex items-center gap-2">
      <div className="w-full bg-primary bg-opacity-20 rounded-full h-2">
        <div className={`h-2  rounded-full bg-primary`} style={{ width: `${percentage}%` }}></div>
      </div>
      <div className="flex justify-between mb-1">
        <span className="text-[#044B5A] font-medium">{percentage}%</span>
      </div>
    </div>
  );
};

export default PercentageBar;
