import { Badge, Button } from 'modules/shared/components/atoms';
import Buttton from 'modules/shared/components/atoms/Buttton';
import React from 'react';

const Plans = () => {
  const data = [
    {
      id: '1',
      planName: 'Intro',
      price: '20',
      features: [
        {
          frature: 'All limited links',
        },
        { frature: 'Own analytics platform' },
        { frature: 'Chat support' },
        { frature: 'Optimize hashtags' },
        { frature: 'Unlimited users' },
      ],
    },
    {
      id: '2',
      planName: 'Pro',
      price: '100',
      features: [
        {
          frature: 'All limited links',
        },
        { frature: 'Own analytics platform' },
        { frature: 'Chat support' },
        { frature: 'Optimize hashtags' },
        { frature: 'Unlimited users' },
      ],
    },
    {
      id: '3',
      planName: 'Enterprise',
      price: '200',
      features: [
        {
          frature: 'All limited links',
        },
        { frature: 'Own analytics platform' },
        { frature: 'Chat support' },
        { frature: 'Optimize hashtags' },
        { frature: 'Unlimited users' },
      ],
    },
  ];
  return (
    <section className=" shadow-sm py-10 px-14 rounded-md">
      <span className="text-primary text-2xl font-semibold ">Plans</span>
      <div className="grid  grid-cols-3 gap-9   justify-between ">
        {data.map((item) => (
          <div className={`grid ${item.planName === 'Pro' ? ` gap-2  bg-primary rounded-lg` : ' content-end'}`}>
            <div className="flex justify-end mt-5 mr-5">
              {item.planName === 'Pro' ? (
                <Badge text="MOST POPULAR" variant="white" classNames="text-sm tracking-wide text-primary rounded-lg" />
              ) : (
                ''
              )}
            </div>
            <div className={`mx-6 `}>
              <div
                className={`flex mb-5 items-center tracking-widest ${
                  item.planName === 'Pro' ? 'text-white' : 'text-primary justify-center '
                }`}
              >
                <span className="text-2xl  font-semibold mr-2">${item.price}</span>
                <span>/month</span>
              </div>
              <p
                className={`text-xl font-semibold tracking-widest mb-3 ${
                  item.planName === 'Pro' ? 'text-white' : 'text-primary'
                }`}
              >
                {item.planName}
              </p>
              <p className={`${item.planName === 'Pro' ? 'text-white' : 'text-gray'} text-sm tracking-widest mb-5 `}>
                For most businesses that want to otpimize web queries
              </p>
              <div className={`flex flex-col gap-2 ${item.planName === 'Pro' ? 'text-white' : ' px-5 text-gray'}`}>
                {item.features.map((sub) => (
                  <div className={` text-sm gap-2 tracking-widest flex ${item.planName === 'Pro' ? 'mb-2' : 'mb-3'}`}>
                    <img
                      src={`${
                        item.planName === 'Pro' ? '/assets/icons/correct_icon2.svg' : '/assets/icons/correct_icon.svg'
                      }`}
                      alt="star"
                    />

                    {sub.frature}
                  </div>
                ))}

                <div className="">
                  <Button
                    classNames={`w-full rounded-lg  border-none  hover:text-primary  ${
                      item.planName === 'Pro'
                        ? ' bg-white  text-primary hover:bg-white mb-12 mt-1'
                        : 'bg-primary text-primary bg-opacity-10 hover:bg-opacity-10'
                    }	`}
                    onClick={''}
                    iconClassNames="filter-white "
                  >
                    Choose plan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Plans;
