import React from 'react';
import { calculateCompletionStatus } from 'modules/homepage/util';

const CompletionPercentage = ({ percentage = 0 }) => {
  const status = calculateCompletionStatus(percentage);
  const bgClassNames = {
    info: 'bg-info',
    success: 'bg-success',
    warning: 'bg-error',
  }[status];
  return (
    <span
      className={`inline-flex items-center justify-center w-10 h-5 text-white py-2 rounded-md text-sm ${bgClassNames}`}
    >
      {percentage}%
    </span>
  );
};

export default CompletionPercentage;
