import { SearchInput, SwitchButton, Typography } from 'modules/shared/components/atoms';
import React from 'react';

const Tracking = () => {
  return (
    <div className="grid mx-12 gap-12 ">
      <div className="grid grid-cols-2 items-center ">
        <Typography className="font-semibold text-xl tracking-widest">Tracking & archive</Typography>
        <SearchInput label="search for your needs" />
      </div>
      <div className="grid gap-4">
        <Typography variant="mainCardTitle" classNames="">
          GDPR Tracking
        </Typography>
        <article className="w-full py-6 shadow-lg rounded-md px-10  ">
          <Typography className="font-semibold text-xl tracking-widest">status</Typography>
          <article className="grid items-center grid-cols-3 ">
            <Typography variant="inputLabel" className="col-span-2 text-neutral tracking-wider">
              Enable the career page feature to gain access to your very own customizable career page to showcase your
              openings and collect applications.
            </Typography>
            <SwitchButton classNames="justify-self-end" />
          </article>
        </article>
      </div>
      <div className="grid gap-4">
        <Typography variant="mainCardTitle" classNames="">
          GDPR Tracking
        </Typography>
        <article className="w-full p-4 grid   shadow-lg rounded-md ">
          <div className="flex justify-between w-full">
            <Typography className="font-semibold text-xl tracking-widest">Clients</Typography>
            <div className="flex items-center gap-2">
              <span className="text-neutral-300 text-100 tracking-wider">312</span>
              <img src="/assets/icons/right_arrow.svg" alt="" />
            </div>
          </div>
          <div className="flex justify-between w-full">
            <Typography className="font-semibold text-xl tracking-widest">Jobs</Typography>
            <div className="flex items-center gap-2">
              <span className="text-neutral-300 text-100 tracking-wider">312</span>
              <img src="/assets/icons/right_arrow.svg" alt="" />
            </div>
          </div>
          <div className="flex justify-between w-full">
            <Typography className="font-semibold text-xl tracking-widest">Candidates</Typography>
            <div className="flex items-center gap-2">
              <span className="text-neutral-300 text-100 tracking-wider">312</span>
              <img src="/assets/icons/right_arrow.svg" alt="" />
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Tracking;
