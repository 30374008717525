import { BasicModal } from 'modules/shared/components/molecules';
import React, { useState } from 'react';
import { AddNewFolder } from '../../organisms';

const TableHeader = ({ candidatesData }) => {
  const [isModalOpended, setIsModalOpended] = useState(false);
  const closeModal = () => {
    setIsModalOpended(false);
  };
  const openModal = () => {
    setIsModalOpended(true);
  };
  return (
    <>
      <div className="flex flex-col mt-10 gap-3">
        <span className=" font-semibold text-xl tracking-widest flex  items-center gap-4">
          All folders <img src="/assets/icons/plus_icon2.svg" alt="" onClick={openModal} className="cursor-pointer" />
        </span>
        <div className="grid grid-cols-3   bg-blue bg-opacity-20 p-4 rounded-t-md">
          <span className="flex col-start-1 ml-20">Folder name</span>
          <span className="col-start-3 col-span-1">Owner</span>
        </div>
      </div>
      <BasicModal title="Add a folder" isOpen={isModalOpended} onClose={closeModal} closeOnOverlayClick={false}>
        <AddNewFolder candidatesData={candidatesData} closeModal={closeModal} />
      </BasicModal>
    </>
  );
};

export default TableHeader;
