import React from 'react';
import { Avatar } from 'modules/homepage/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import { JobForm } from 'modules/jobs/components/organisms';
import { Button, Typography } from 'modules/shared/components/atoms';
import { useDeleteJobMutation } from 'features/jobsSlice';
import { toast } from 'react-toastify';

const JobSummarySection = ({
  position = '',
  category = '',
  date = '',
  datePostedAge = '',
  avatarUrl = '',
  alt = '',
  refetchJobs,
  jobId = '',
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const [deleteJob, { isLoading: deleteJobLoading }] = useDeleteJobMutation();
  const handleDeleteSelectedClients = async () => {
    const ids = [jobId];
    const payload = { jobs: ids };

    try {
      await deleteJob(payload).unwrap();
      refetchJobs();
      setIsDeleteModalOpen(false);
      toast.success('Job deleted successfully');
    } catch (error) {
      const {
        data: { message },
      } = error;
      toast.error(message);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <article className="flex gap-8 items-center justify-between">
      <div className="flex gap-6">
        <div>
          <Avatar avatarUrl={avatarUrl} alt={alt} />
        </div>
        <div>
          <p className="inline-flex gap-2">
            <span className="font-semibold">{position}</span>
            <span className="text-md font-semibold text-primary">{category}</span>
          </p>
          <p className="flex gap-2 items-center">
            <span className="text-sm">{datePostedAge}</span>
            <span>-</span>
            <span className="text-sm">({date})</span>
          </p>
        </div>
      </div>
      <div className="flex gap-4 self-start">
        <div className="cursor-pointer" onClick={openModal}>
          <img src="/assets/icons/pencil.svg" alt="edit" />
        </div>
        <div className="cursor-pointer" onClick={() => setIsDeleteModalOpen(true)}>
          <img src="/assets/icons/trash.svg" alt="delete" />
        </div>
      </div>
      <BasicModal title="Edit Job" isOpen={isModalOpen} onClose={closeModal}>
        {' '}
        <JobForm closeModal={closeModal} jobId={jobId} isEditing={true} selectableRows={jobId} refetch={refetchJobs} />
      </BasicModal>
      <BasicModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
        <div className="flex flex-col items-center justify-center gap-5 mb-5">
          <img src="/assets/icons/delete-placement-icon.svg" alt="" />
          <p className="text-xl font-semibold text-center text-neutral">Delete Job</p>
          <p className="text-lg text-center text-neutral mx-5">Are you sure you want to delete the selected jobs?</p>
        </div>
        <div className="grid grid-cols-2 place-content-center mx-20">
          <div className="flex items-center justify-center px-4 cursor-pointer" onClick={closeDeleteModal}>
            <Typography variant="body" className="w-full text-center">
              Cancel
            </Typography>
          </div>
          <div>
            <Button
              type="submit"
              classNames="flex mx-auto bg-primary text-white border-none w-full"
              onClick={handleDeleteSelectedClients}
              disabled={deleteJobLoading ? true : false}
              isLoading={deleteJobLoading}
            >
              Delete
            </Button>
          </div>
        </div>
      </BasicModal>
    </article>
  );
};

export default JobSummarySection;
