import React from 'react';
import DataTable from 'react-data-table-component';
import { Avatar } from 'modules/homepage/components/atoms';
import { Badge } from 'modules/shared/components/atoms';

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400px',
      fontSize: ' 14px',
    },
  },
};

const data = [
  {
    id: 1,
    displayName: 'John',
    fullName: 'John M. Doe',
    emailAddress: 'john.doe@example.com',
    role: 'Admin',
    status: 'Active',
    createdDate: '2023-01-01',
    lastActiveDate: '2023-10-14 (3 min ago)',
  },
];

const ReportTable = () => {
  const handleDelete = (id) => {};
  const columns = [
    {
      name: 'Display Name',
      selector: 'displayName',
      sortable: true,
      cell: (row) => (
        <div className="flex items-center gap-2">
          <Avatar classNames="w-8 h-8 font-normal" avatarUrl={row.avatar} alt={row.displayName} />
          <span className="text-sm">{row.displayName}</span>
        </div>
      ),
      width: '150px',
    },
    {
      name: 'Full Name',
      selector: 'fullName',
      sortable: true,
      width: '130px',
    },
    {
      name: 'Email Address',
      selector: 'emailAddress',
      sortable: true,
      width: '200px',
    },
    {
      name: 'Role',
      selector: 'role',
      sortable: true,
      width: '90px',
    },
    {
      name: 'Status',
      selector: 'Status',
      sortable: true,
      width: '110px',
      cell: (row) => (
        <div className="flex gap-2">
          <Badge
            variant="success"
            textClassNames="text-success"
            classNames="tracking-wider bg-opacity-20"
            text={row.status}
          />
        </div>
      ),
    },
    {
      name: 'Last Active Date',
      selector: 'lastActiveDate',
      sortable: true,
    },
    {
      button: true,
      cell: (row) => (
        <img
          src="/assets/icons/red_trash.svg"
          className="cursor-pointer"
          alt="delete"
          onClick={() => handleDelete(row.id)}
        />
      ),
      width: '50px',
    },
  ];

  return <DataTable columns={columns} data={data} customStyles={customStyles} />;
};

export default ReportTable;
