import { useState } from 'react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { Button, TextInputCounter } from 'modules/shared/components/atoms';
import { twMerge } from 'tailwind-merge';
import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

const AddTemplateModal = ({ rowData, onAdd, onUpdate, onClose }) => {
  const initialValues = {
    name: rowData ? rowData.name : '',
    subject: rowData ? rowData.subject : '',
    description: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    subject: Yup.string().required('Subject is required'),
    description: Yup.string().required('Description is required'),
  });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleQuillChange = (value) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     description: value,
  //   }));
  // };

  const handleFormSubmission = (values) => {
    if (rowData) {
      onUpdate(values);
    } else {
      onAdd(values);
    }
    onClose();
  };

  return (
    <Formik onSubmit={handleFormSubmission} initialValues={initialValues} validationSchema={validationSchema}>
      {({ values, errors, setFieldValue, handleChange, handleBlur, touched, handleSubmit }) => (
        <Form>
          <div className="flex flex-col gap-6 items-center">
            <div className="w-full">
              <TextInputCounter
                classNames={`${touched.name && errors.name ? 'border-error' : ''}`}
                name="name"
                type="text"
                label="Name *"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage name="name" component="p" className="text-error text-sm" />
            </div>
            <div className="w-full">
              <TextInputCounter
                classNames={`${touched.subject && errors.subject ? 'border-error' : ''}`}
                name="subject"
                type="text"
                label="Subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage name="subject" component="p" className="text-error text-sm" />
            </div>
            <div className="w-full">
              <Field name="description">
                {({ field }) => (
                  <ReactQuill
                    className={twMerge(
                      errors.description && touched.description ? 'border-error ' : 'border-neutral-100',
                      'border rounded-md overflow-hidden',
                    )}
                    value={values.description}
                    onChange={(val) => setFieldValue('description', val)}
                  />
                )}
              </Field>
              <ErrorMessage name="description" component="p" className="text-error text-sm" />
            </div>
            <Button classNames="bg-primary text-white border-none w-3/5 font-semibold text-lg py-1.5" type="submit">
              {rowData ? 'Update Template' : 'Add Template'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTemplateModal;
