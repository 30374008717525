import { Field, Form, Formik, useFormik } from 'formik';
import { Button, Select, TextInput, Typography } from 'modules/shared/components/atoms';
import { ImageUploader } from 'modules/shared/components/molecules';
import { CustomSelect } from 'modules/shared/components/molecules';

import React from 'react';
import * as Yup from 'yup';
import { GuestTable } from '../../molecules';

const companyTypeOptions = [
  { label: 'Technology', value: 'technology' },
  { label: 'Finance', value: 'finance' },
  { label: 'Healthcare', value: 'healthcare' },
  { label: 'Retail', value: 'retail' },
  { label: 'Agency', value: 'agency' },
];

const initialValues = {
  image: null,
  companyName: '',
  type: '',
};

const validationSchema = Yup.object({
  companyName: Yup.string().required('Company name is required'),
  type: Yup.string().required('Type is required'),
});

const AccountDetails = () => {
  const handleSubmit = (values) => {};
  const customFieldStyles = {
    backgroundColor: '#f4f4f4',
    borderRadius: '12px',
    padding: '12px 24px',
    width: '100%',
    outline: 'none',
  };

  const styles = {
    control: {
      backgroundColor: '#F4F4F4',
      borderRadius: '12px',
      border: 'none',
    },
  };

  return (
    <article className="mx-12">
      <article>
        <Typography variant="mainCardTitle" className="tracking-widest mb-4">
          Account Details
        </Typography>
      </article>
      <article className="w-full py-8 shadow-lg rounded-md ">
        <Typography className="flex flex-col text-center mb-6 tracking-widest text-lg text-neutral-100">
          Your company’s logo
        </Typography>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
            <Form className="flex flex-col   gap-4 w-full">
              <ImageUploader
                onImageChange={(file) => {}}
                classNames={'bg-white border-dashed border border-neutral-100 w-32 h-32'}
                iconUrl="/assets/icons/upload_logo.svg"
                iconClassNames="w-8 right-12 top-5"
                text="Upload your logo"
                textClassName="text-neutral-100 text-sm right-6 bottom-8 w-20 tracking-widest"
              />
              <div className="grid grid-cols-6 gap-6  ">
                <div className="col-span-4 col-start-2 grid grid-cols-4 items-center">
                  <Typography className="font-semibold tracking-widest text-base mb-3 col-span-1">
                    Company name
                  </Typography>
                  <div className="col-span-3">
                    <Field
                      type="text"
                      id="cardNumber"
                      name="cardNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cardNumber}
                      style={{ ...customFieldStyles, columnSpan: 2 }}
                    />
                  </div>
                  <Typography variant="errorText">{touched.cardNumber && errors.cardNumber}</Typography>
                </div>

                <div className="col-span-4 col-start-2 grid grid-cols-4 items-center">
                  <Typography className="font-semibold tracking-widest text-base mb-3 col-span-1">Type</Typography>
                  <div className="col-span-3">
                    <CustomSelect
                      styles={styles}
                      error={errors.type && touched.type}
                      option={companyTypeOptions}
                      isMulti={false}
                      label="Select candidates *"
                      value={values.type}
                      onBlur={handleBlur}
                      onChange={(value) => setFieldValue('type', value)}
                    />

                    <Typography variant="errorText">
                      {errors.selectValue && touched.selectValue ? errors.selectValue : ''}
                    </Typography>
                  </div>
                  <Typography variant="errorText">{touched.expirationDate && errors.expirationDate}</Typography>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </article>
      <article className="my-16">
        <Typography variant="mainCardTitle" className="tracking-widest mb-4">
          Guest Management
        </Typography>
        <article className="w-full flex flex-col items-center p-8 shadow-lg rounded-md ">
          <img src="/assets/icons/guests_icon.svg" alt="guest management" />
          <Typography className=" tracking-widest text-lg text-neutral-100 ">You don’t have any guests yet</Typography>
          <Button classNames="bg-primary text-white text-lg font-semibold border-none mt-6 tracking-widest">
            Create guest
          </Button>
        </article>
      </article>
      <article className="">
        <div className="flex justify-between mb-4">
          <Typography variant="mainCardTitle" className="tracking-widest ">
            Guest Management
          </Typography>
          <Button classNames="bg-primary text-white border-none text-lg tracking-widest py-2 px-5">
            Invite new user
          </Button>
        </div>
        <article className="w-full flex flex-col items-center p-8 shadow-lg rounded-md ">
          <GuestTable />
        </article>
      </article>
    </article>
  );
};

export default AccountDetails;
