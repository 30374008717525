import React from 'react';

const RecentHistoryCard = () => (
  <div className="p-5 bg-white shadow-lg border border-gray-100 rounded-lg mt-10">
    <h2 className="text-xl ">Recent History</h2>
    <div className="mt-4 flex flex-col gap-2">
      <h3 className="text-lg">Took ownership of candidate</h3>
      <p className="text-gray-200">Apr 2024 - Mar 2024</p>
    </div>
  </div>
);

export default RecentHistoryCard;
