import React from 'react';

// Helper function to calculate the difference between two dates
const calculateDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : new Date(); // Use current date if endDate is not provided

  let years = end.getFullYear() - start.getFullYear();
  let months = end.getMonth() - start.getMonth();

  // Adjust if the end month is earlier than the start month in the same year
  if (months < 0) {
    years--;
    months += 12;
  }

  return { years, months };
};

// Function to calculate total work experience
const calculateTotalExperience = (experienceData) => {
  let totalMonths = 0;

  experienceData.forEach((job) => {
    const { years, months } = calculateDuration(job.start_date, job.end_date);
    totalMonths += years * 12 + months;
  });

  const totalYears = Math.floor(totalMonths / 12);
  const remainingMonths = totalMonths % 12;

  return { totalYears, remainingMonths };
};

const WorkExperienceCard = ({ experienceDAta }) => {
  const { totalYears, remainingMonths } = calculateTotalExperience(experienceDAta);

  return (
    <div className="p-5 bg-white shadow-lg border border-gray-100 rounded-lg mt-12">
      <h2 className="text-xl">Work Experience</h2>
      <p className="text-purple font-semibold">
        {totalYears} Years {remainingMonths} Months
      </p>

      {experienceDAta.map((job, index) => {
        const { years, months } = calculateDuration(job.start_date, job.end_date);
        return (
          <div key={index}>
            <div className="mt-4 flex justify-between">
              <div className="flex items-center gap-2">
                <p className="h-[18px] w-[18px] mb-5 bg-primary bg-opacity-10 flex justify-center items-center rounded-full">
                  <p className={`h-3 w-3 rounded-full ${job.end_date == null ? 'bg-primary' : 'bg-gray-200'}`}></p>
                </p>
                <div className="flex flex-col gap-1">
                  <h3 className="text-lg">
                    {job.title} at {job.company_name}
                  </h3>
                  <p className="text-gray-200">{job.company_location}</p>
                </div>
              </div>
              <div>
                <p className="text-gray-200 text-end">
                  {years > 0 && `${years} Year${years > 1 ? 's' : ''} `}
                  {months > 0 && `${months} Month${months > 1 ? 's' : ''}`}
                </p>
                <p className="text-neutral-100">
                  {job.start_date} / {job.end_date ? job.end_date : 'Present'}
                </p>
              </div>
            </div>

            {experienceDAta.length > 1 && index !== experienceDAta.length - 1 && (
              <p className="h-14 border-l-2 ml-2 mb-7 border-gray-100"></p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default WorkExperienceCard;
