import { useGetJobByIdQuery } from 'features/jobsSlice';
import {
  AttachmentList,
  JobActivity,
  JobCandidatesDetails,
  JobSummary,
  JobTeamTable,
  Notes,
} from 'modules/jobs/components/organisms';
import { TableLoader } from 'modules/shared/components/molecules';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const JobDetails = () => {
  let { id } = useParams();

  const { data: jobData, isLoading, refetch: refetchJob, error } = useGetJobByIdQuery(id);

  const [activeTab, setActiveTab] = useState('Summary');

  const tabs = ['Summary'];
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  if (isLoading) {
    return <TableLoader numRows={7} />;
  }

  if (error) {
    return <div>Error fetching job data</div>;
  }
  return (
    <div>
      <article>
        <div className=" bg-white rounded-lg shadow-md mb-8">
          <div className="flex justify-between items-center ">
            <div className="flex gap-5 items-center mb-4">
              <div className="mb-4 flex items-center">
                <NavLink to={'/dashboard/jobs/'} className="w-14">
                  <img src="/assets/icons/back_arrow.svg" alt="" />
                </NavLink>
                <h1 className="text-2xl font-semibold">{jobData[0]?.name}</h1>
              </div>
              {/* 
                <span className="text-success-100 flex gap-1 items-center text-lg">
                <p className="w-4 h-4 bg-success-100 rounded-full"></p>
                Published in career stage
              </span>
                */}
            </div>

            {/* 
            // <div className="flex justify-between items-center mb-4 text-lg">
            //   <span>Job Progress</span>
            //   <span className="w-20 border-2 ml-2 border-primary"></span>
            //   <span className="w-10 border-2 mr-2 border-primary opacity-10"></span>
            //   <span>Technical Interview</span>
            // </div>
            */}
          </div>
          {/*
                             <div className="flex justify-between items-center py-6 px-16  border border-gray rounded-md">
            <div className="flex flex-col gap-4  items-center">
              <p className="text-neutral-100">OWNER</p>
              <p className="text-lg font-semibold">Jasmine Doe</p>
            </div>
            <div className="flex flex-col gap-4  items-center">
              <p className="text-neutral-100">Organization</p>
              <p className="text-lg font-semibold">Tech crop</p>
            </div>
            <div className="flex flex-col gap-4  items-center">
              <p className="text-neutral-100">Candidates</p>
              <p className="text-lg font-semibold">8</p>
            </div>
            <div className="flex flex-col gap-4  items-center">
              <p className="text-neutral-100">Hired</p>
              <p className="text-lg font-semibold text-success-100">0</p>
            </div>
            <div className="flex flex-col gap-4  items-center">
              <p className="text-neutral-100">Dropped</p>
              <p className="text-lg font-semibold text-[#CC4C42]">2</p>
            </div>
          </div>
                           */}

          <div className="flex mt-12 text-lg  text-gray font-semibold">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`text-base md:text-lg px-4 py-2 ${
                  activeTab === tab ? 'text-primary border-b-2 ' : 'text-gray-400 hover:text-primary'
                }`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
        <div className={`${activeTab === 'Candidates' ? 'block' : 'hidden'}`}>
          <JobCandidatesDetails />
        </div>
        <div className={`${activeTab === 'Summary' ? 'block' : 'hidden'}`}>
          <JobSummary jobData={jobData[0]} />
        </div>
        <div className={`${activeTab === 'Teams' ? 'block' : 'hidden'}`}>
          <JobTeamTable />
        </div>
        <div className={`${activeTab === 'Activities' ? 'block' : 'hidden'}`}>
          <JobActivity />
        </div>
        <div className={`${activeTab === 'Notes' ? 'block' : 'hidden'}`}>
          <Notes />
        </div>
        <div className={`${activeTab === 'Attachments' ? 'block' : 'hidden'}`}>
          <AttachmentList />
        </div>
      </article>
    </div>
  );
};

export default JobDetails;
