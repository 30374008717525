import React from 'react';

const SourceInfoCard = () => (
  <div className="p-5 bg-white shadow-lg border border-gray-100 rounded-lg mt-10">
    <p className="text-gray-300 ">source</p>
    <p className="text-gray-800">Via LinkedIn extension</p>
    <p className="text-gray-300 mt-4">Resume</p>
    <p className="text-gray-800">Resume added</p>
    <p className="text-gray-300 mt-4">Date</p>
    <p className="text-gray-800">Jan 15, 2021</p>
    <p className="text-gray-300 mt-4">Created by</p>
    <p className="text-gray-800">Adam</p>
    <p className="text-gray-300 mt-4">Last update</p>
    <p className="text-gray-800">Jan 20, 2021</p>
  </div>
);

export default SourceInfoCard;
