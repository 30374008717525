import { Field, Formik } from 'formik';
import { SwitchButton, Typography } from 'modules/shared/components/atoms';
import React from 'react';

const ContactGuests = () => {
  const initialValuesPipeline = {
    guestsCanMoveCandidates: false,
    guestsCanDropCandidates: false,
  };

  const initialValuesVisibility = {
    jobCreatorVisibility: false,
    jobOwnerVisibility: false,
    resumeVisibility: false,
    socialMediaVisibility: false,
  };

  const handleSubmitPipeline = (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  const handleSubmitVisibility = (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  return (
    <>
      <Formik initialValues={initialValuesPipeline} onSubmit={handleSubmitPipeline}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="grid mx-12 gap-12">
            <div className="grid grid-cols-2 items-center">
              <Typography className="font-semibold text-2xl tracking-widest">Job board</Typography>
            </div>
            <article className="grid gap-4">
              <Typography variant="mainCardTitle">Pipeline Permissions</Typography>
              <div className="w-full py-8 shadow-lg rounded-md px-10 grid gap-6">
                <Field
                  name="guestsCanMoveCandidates"
                  render={({ field }) => (
                    <article className="grid items-center grid-cols-2">
                      <p className="text-lg tracking-wider">Guests can move candidates</p>
                      <SwitchButton
                        name={field.name}
                        checkboxValue={formik.values[field.name]}
                        onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                        classNames="justify-self-end"
                      />
                    </article>
                  )}
                />
                <Field
                  name="guestsCanDropCandidates"
                  render={({ field }) => (
                    <article className="grid items-center grid-cols-2">
                      <p className="text-lg tracking-wider">Guests can drop candidates</p>
                      <SwitchButton
                        name={field.name}
                        checkboxValue={formik.values[field.name]}
                        onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                        classNames="justify-self-end"
                      />
                    </article>
                  )}
                />
              </div>
            </article>
          </form>
        )}
      </Formik>

      <Formik initialValues={initialValuesVisibility} onSubmit={handleSubmitVisibility}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="grid mx-12 gap-12 mt-8">
            <article className="grid gap-4">
              <Typography variant="mainCardTitle">Information Visibility</Typography>
              <div className="w-full py-8 shadow-lg rounded-md px-10 grid gap-6">
                <Typography className="font-semibold text-xl tracking-widest">Job Information</Typography>
                <Field
                  name="jobCreatorVisibility"
                  render={({ field }) => (
                    <article className="grid items-center grid-cols-2">
                      <p className="text-lg tracking-wider">Job creator</p>
                      <SwitchButton
                        name={field.name}
                        checkboxValue={formik.values[field.name]}
                        onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                        classNames="justify-self-end"
                      />
                    </article>
                  )}
                />
                <Field
                  name="jobOwnerVisibility"
                  render={({ field }) => (
                    <article className="grid items-center grid-cols-2">
                      <p className="text-lg tracking-wider">Job owner</p>
                      <SwitchButton
                        name={field.name}
                        checkboxValue={formik.values[field.name]}
                        onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                        classNames="justify-self-end"
                      />
                    </article>
                  )}
                />
              </div>
              <div className="w-full py-8 shadow-lg rounded-md px-10 grid gap-6">
                <Typography className="font-semibold text-xl tracking-widest">Additional Information</Typography>
                <Field
                  name="resumeVisibility"
                  render={({ field }) => (
                    <article className="grid items-center grid-cols-2">
                      <p className="text-lg tracking-wider">Resume</p>
                      <SwitchButton
                        name={field.name}
                        checkboxValue={formik.values[field.name]}
                        onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                        classNames="justify-self-end"
                      />
                    </article>
                  )}
                />
                <Field
                  name="socialMediaVisibility"
                  render={({ field }) => (
                    <article className="grid items-center grid-cols-2">
                      <p className="text-lg tracking-wider">Social Media</p>
                      <SwitchButton
                        name={field.name}
                        checkboxValue={formik.values[field.name]}
                        onCheckboxChange={(value) => formik.setFieldValue(field.name, value)}
                        classNames="justify-self-end"
                      />
                    </article>
                  )}
                />
              </div>
            </article>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ContactGuests;
