import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import { Link } from '@mui/material';
import { Button, Checkbox, TextInput, Typography } from 'modules/shared/components/atoms';
import { twMerge } from 'tailwind-merge';
import { useRegisterMutation } from 'features/authApiSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  first_name: Yup.string().required('First name is required'),
  phone_number: Yup.string().required('Phone number is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
  terms: Yup.boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions'),
});

const initialValues = {
  first_name: '',
  phone_number: '',
  last_name: '',
  email: '',
  password: '',
  terms: false,
};

const SignupForm = () => {
  const navigate = useNavigate();

  const [register, { isLoading, isError, isSuccess, error }] = useRegisterMutation();
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await register(values).unwrap();
      toast.success(
        `A verification email has been sent to ${response.confirmation_delivery_details.destination}. Please verify your email before proceeding.`,
        setTimeout(() => navigate('/login'), 3000),
      );
    } catch (error) {
      const {
        data: { message },
      } = error;
      toast.error(message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="h-full shadow-sm flex flex-col items-center rounded-md px-20 py-8 bg-white">
      <article className="p-5 w-full">
        <div className="flex flex-col gap-6">
          <article className="text-center">
            <img className="inline-block" width={58} height={58} src="/logo.png" alt="logo" />
            <Typography htmlTag="h1" className="text-center" variant="hero">
              Name
            </Typography>
          </article>
          <article>
            <Typography htmlTag="h1" className="text-center" variant="hero">
              Start your free trial !
            </Typography>
            <Typography variant="inputLabel" alignText="center" htmlTag="p">
              14-day free trial and no credit card required.
            </Typography>
          </article>
        </div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => (
            <Form className="mt-2">
              <div className="flex flex-col gap-4 mt-7">
                <div className="">
                  <TextInput
                    label="First Name"
                    name="first_name"
                    type="text"
                    error={touched.first_name && errors.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                  />
                  <Typography className="" variant="errorText">
                    {touched.first_name && errors.first_name ? errors.first_name : ''}
                  </Typography>
                </div>
                <div className="">
                  <TextInput
                    label="Last Name"
                    name="last_name"
                    type="text"
                    error={touched.last_name && errors.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                  />
                  <Typography className="" variant="errorText">
                    {touched.last_name && errors.last_name ? errors.last_name : ''}
                  </Typography>
                </div>
                <div className="">
                  <div
                    className={twMerge(
                      `w-full h-12 border-solid border  focus:outline-none focus:shadow-outline  focus:border-primary appearance-none focus:border-2 leading-5 rounded-sm px-2`,
                      `${touched.phone_number && errors.phone_number ? 'text-error border-error' : ''}`,
                    )}
                  >
                    <PhoneInput
                      className="h-full"
                      id="phone"
                      name="phone_number"
                      type="text"
                      placeholder="Enter phone number"
                      onChange={(e) => {
                        setFieldValue('phone_number', e);
                      }}
                      onBlur={handleBlur}
                      value={values.phone_number}
                    />
                  </div>
                  <Typography className="" variant="errorText">
                    {touched.phone_number && errors.phone_number ? errors.phone_number : ''}
                  </Typography>
                </div>

                <div className="">
                  <TextInput
                    label="Company Email address"
                    name="email"
                    type="text"
                    error={touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <Typography className="" variant="errorText">
                    {touched.email && errors.email ? errors.email : ''}
                  </Typography>
                </div>
                <div className="">
                  <TextInput
                    label="Password"
                    name="password"
                    type="password"
                    error={touched.password && errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <Typography className="" variant="errorText">
                    {touched.password && errors.password ? errors.password : ''}
                  </Typography>
                </div>
              </div>
              <div>
                <div className="flex text-center my-2">
                  <Field type="checkbox" name="terms">
                    {({ field }) => (
                      <Checkbox {...field} checked={field.value} onChange={() => setFieldValue('terms', !field.value)}>
                        {/* Add any additional content you want to display next to the checkbox */}
                        <span className="text-base">
                          I agree to the{' '}
                          <Link className="text-primary text-base tracking-wide inline-block px-2" href="#">
                            privacy policy
                          </Link>{' '}
                          and{' '}
                          <Link className="text-primary text-base tracking-wide inline-block px-2" href="#">
                            terms and conditions
                          </Link>
                        </span>
                      </Checkbox>
                    )}
                  </Field>
                </div>
                <Typography className="" variant="errorText">
                  {touched.terms && errors.terms ? errors.terms : ''}
                </Typography>
              </div>
              <Button
                type="submit"
                classNames="my-5 w-full bg-primary text-white border-none tracking-widest font-semibold"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Sign up'}
              </Button>
              <div className="text-center">
                <Link href="#" variant="body2">
                  Sign in with SSO
                </Link>
              </div>
              <div className="text-center mt-4">
                <div className="inline-block shadow-xs p-6 rounded-md">
                  <img className="inline-block" width={22} height={17} src="/assets/icons/sso_login.svg" alt="sso" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </article>
    </div>
  );
};

export default SignupForm;
