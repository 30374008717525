import React from 'react';

const Header = ({ candidateName, currentPosition, location, email, phone, linkedinUrl, facebookUrl, twitterUrl }) => (
  <article className="flex items-center px-4 py-2 bg-white border border-gray-100 rounded-md">
    <img src="/assets/icons/avatar.png" alt="John Doe" className="w-44 h-44 rounded-full" />
    <div className="ml-6 flex flex-col gap-3">
      <h1 className="text-2xl font-bold tracking-wider">{candidateName}</h1>
      <p className="text-neutral-100 text-lg tracking-wider">{currentPosition}</p>
      <div className="flex gap-10">
        <div className="flex flex-col gap-4">
          <span className="text-base flex gap-2 leading-6 tracking-wider">
            <img src="/assets/icons/location-icon.png" alt="" className="w-4 h-5" />
            {location}
          </span>
          <span className="flex gap-2 tracking-wider">
            <img src="/assets/icons/linkedin-icon2.png" alt="" className="w-6" />
            {linkedinUrl.replace(/https?:\/\/(www\.)?/, '')}
          </span>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-base flex gap-2 tracking-wider">
            <img src="/assets/icons/phone-icon.png" alt="" className="w-5 h-5" />
            {phone}
          </span>
          {facebookUrl && (
            <span className="flex gap-2 tracking-wider">
              <img src="/assets/icons/facebook-icon.png" alt="Facebook" className="w-6" />
              {facebookUrl.replace(/https?:\/\/(www\.)?/, '')}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <span className="flex gap-2 tracking-wider">
            <img src="/assets/icons/mail-icon.png" alt="" className="w-5 h-4 mt-1" />
            {email}
          </span>
          {twitterUrl && (
            <span className="flex gap-2 tracking-wider">
              <img src="/assets/icons/X(Twitter)-icon.png" alt="Twitter" className="w-6" />
              {twitterUrl.replace(/https?:\/\/(www\.)?/, '')}
            </span>
          )}
        </div>
      </div>
    </div>
  </article>
);

export default Header;
