import { Button } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import React, { useState } from 'react';
import { AddNewFolder } from '../../organisms';

const EmptyFolderPlaceholder = ({ candidatesData }) => {
  const [isModalOpended, setIsModalOpended] = useState(false);
  const closeModal = () => {
    setIsModalOpended(false);
  };
  const openModal = () => {
    setIsModalOpended(true);
  };
  return (
    <div className="flex justify-center align-center flex-col ">
      <div className="flex justify-center">
        <img className=" w-40 " src="/assets/icons/folder_placeholder.png" alt="empty folder" />
      </div>
      <p className="flex justify-center pt-10 text-black font-bold font-poppins text-xl tracking-wide">
        You have not created any folders yet
      </p>
      <p className="flex justify-center pt-3 text-gray font-poppins font-sm tracking-widest">
        By creating folders , you will be allowed to organize your candidates
      </p>
      <Button
        classNames="mt-10 mx-auto w-60 bg-primary text-white  border-none"
        iconUrl="/assets/icons/icon_plus.svg"
        iconClassNames="filter-white mr-1.5"
        onClick={openModal}
      >
        Create folder
      </Button>
      <BasicModal title="Add a folder" isOpen={isModalOpended} onClose={closeModal} closeOnOverlayClick={false}>
        <AddNewFolder candidatesData={candidatesData} />
      </BasicModal>
    </div>
  );
};

export default EmptyFolderPlaceholder;
