import { Button, Checkbox, Select, SwitchButton, Typography } from 'modules/shared/components/atoms';
import React, { useRef, useState } from 'react';
import { DuplicateManagementTable, ReportTable } from '../../molecules';
const options = [
  {
    id: 1,
    value: 'English',
    label: 'English',
  },
  {
    id: 2,
    value: 'Arabic',
    label: 'Arabic',
  },
];
const ReportSection = () => {
  const [placementTemplate, setPlacementTemplate] = useState(null);

  const selectRef = useRef();

  return (
    <div className="">
      <div className="mt-12 grid gap-4">
        <Typography variant="mainCardTitle" className="tracking-widest">
          Report
        </Typography>
        <article className="grid gap-4 py-6 shadow-lg rounded-md px-10">
          <div className="flex items-center justify-between">
            <Typography className="text-base tracking-wider">status</Typography>
            <SwitchButton />
          </div>
          <article className="grid gap-8 py-8 shadow-lg rounded-md px-4 items-center text-center">
            <Typography className="font-semibold text-lg tracking-wider">Users</Typography>
            <ReportTable />
            <Button classNames="w-fit text-lg bg-primary text-white border-0 py-2 px-4 tracking-wider mx-auto">
              Add a candidate
            </Button>
          </article>
        </article>
      </div>
      <div className="mt-4 grid gap-4">
        <Typography variant="mainCardTitle" className="tracking-widest">
          Contacts and Guests Menu
        </Typography>
        <article className="grid gap-4 py-6 shadow-lg rounded-md px-10">
          <div className="flex items-center justify-between">
            <Typography className="text-base tracking-wider">status</Typography>
            <SwitchButton />
          </div>
        </article>
      </div>
      <div className="mt-4 grid gap-4">
        <Typography variant="mainCardTitle" className="tracking-widest">
          Report
        </Typography>
        <article className="grid gap-8 py-6 shadow-lg rounded-md px-10">
          <Typography className="text-lg font-semibold tracking-wider">Duplicate Management System</Typography>
          <article className="grid gap-8 py-8 shadow-lg rounded-md px-4 items-center text-center">
            <Typography className="font-semibold text-lg tracking-wider">Users</Typography>
            <DuplicateManagementTable />
            <Button classNames="w-fit text-lg bg-primary text-white border-0 py-2 px-4 tracking-wider mx-auto">
              Add a candidate
            </Button>
          </article>
          <div className="grid gap-4">
            <Typography className="text-base font-semibold tracking-wider">
              Duplicate Profile Detection Settings
            </Typography>
            <div className="flex">
              <Checkbox name="is_remote">
                <span className="text-black">Full name</span>
              </Checkbox>
            </div>
            <div className="flex">
              <Checkbox name="is_remote">
                <span className="text-black">Email address</span>
              </Checkbox>
            </div>
            <div className="flex">
              <Checkbox name="is_remote">
                <span className="text-black">Phone number</span>
              </Checkbox>
            </div>
          </div>
          <div className="grid gap-4">
            <Typography className="text-base font-semibold tracking-wider">Candidate Auto-Merging</Typography>
            <div className="flex items-center justify-between">
              <Typography className="text-base tracking-wider">status</Typography>
              <SwitchButton />
            </div>
          </div>
        </article>
        <article className="grid gap-6 py-6 shadow-lg rounded-md px-10  ">
          <Typography className="font-semibold text-lg tracking-widest">Referral Management System</Typography>
          <div className="flex items-center justify-between">
            <Typography className="text-base tracking-wider">status</Typography>
            <SwitchButton />
          </div>
        </article>
        <article className="grid gap-6 py-6 shadow-lg rounded-md px-10  ">
          <Typography className="font-semibold text-lg tracking-widest">Employment Management System</Typography>
          <div className="flex items-center justify-between">
            <Typography className="text-base tracking-wider">status</Typography>
            <SwitchButton />
          </div>
          <div className="flex items-center justify-between">
            <Typography className="text-base tracking-wider">Employees name</Typography>
            <Select
              selectRef={selectRef}
              labelClassNames="text-base leading-[3.5]"
              classNames={'w-3/12'}
              label="Placement/Placement"
              options={options}
              value={placementTemplate}
              setFieldValue={() => setPlacementTemplate(1)}
              onChange={() => {}}
            />
          </div>
        </article>
        <div className="grid gap-2 mt-8">
          <Typography variant="mainCardTitle" className="tracking-widest">
            Job Requisitions
          </Typography>
          <article className="grid gap-6 py-6 shadow-lg rounded-md px-10  ">
            <div className="flex items-center justify-between">
              <Typography className="text-base tracking-wider">status</Typography>
              <SwitchButton />
            </div>
          </article>
        </div>
        <div className="grid gap-2 mt-8">
          <Typography variant="mainCardTitle" className="tracking-widest">
            Revenue settings
          </Typography>
          <article className="grid gap-6 py-6 shadow-lg rounded-md px-10  ">
            <div className="flex items-center justify-between">
              <Typography className="text-base tracking-wider">status</Typography>
              <SwitchButton />
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default ReportSection;
