import React from 'react';
import { createBrowserRouter, RouterProvider, BrowserRouter, Route, Routes } from 'react-router-dom';
// import Login from '@modules/login';
// import { AuthLayout } from '@modules/shared/components/template';
import './App.css';

// import { Login, Signup } from 'modules/auth/pages';
import { ForgetPassqord, Login, Signup, VerifyOtp } from './modules/auth/pages';
import { PagesLayout, AuthLayout } from './modules/shared/components/template';
import { MainDashboard } from './modules/homepage/pages';
import { JobDetails, Jobs } from 'modules/jobs/pages';
import { Candidates, Folders } from 'modules/candidates/pages';
import { CandidatesTable, FavouriteTable } from 'modules/candidates/components/organisms';
import { Clients } from 'modules/clients/pages';
import RequireAuth from 'features/RequireAuth';
import {
  AccountAndUsers,
  Integrations,
  Subscription,
  JobBoard,
  Tracking,
  Logs,
  CarrerResumes,
  DataImport,
  ContactGuests,
  ProfileSettings,
  CustomizationCandidates,
  CustomizationClients,
  Features,
  CustomizationJobs,
} from 'modules/adminstration/pages';
import {
  EmailForwarding,
  EmailSynchronization,
  Notifications,
  TemplatesPage,
  Performance,
  EmailSignature,
  SettingsIntegrations,
} from 'modules/settings/pages';
import { Inbox } from 'modules/Inbox/pages';
import { Draft, InboxHome, Sent, Starred, Trash } from 'modules/Inbox/components/organisms';
import { MessageDetailes } from 'modules/Inbox/components/molecules';
import UserProfile from './modules/UserProfile/pages';
import Placement from 'modules/placement/pages';
import { PlacementDetails } from 'modules/placement/components/organisms';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="forgot-password" element={<ForgetPassqord />} />
          <Route path="verify-otp" element={<VerifyOtp />} />
        </Route>
        <Route path="dashboard" element={<PagesLayout />}>
          <Route element={<RequireAuth />}>
            <Route index element={<MainDashboard />} />
            <Route path="jobs" element={<Jobs />} />
            <Route path="jobs/:id" element={<JobDetails />} />
            <Route path="/dashboard/candidates" element={<Candidates />}>
              <Route path="/dashboard/candidates" element={<CandidatesTable />} />
              <Route path="/dashboard/candidates/folders" element={<Folders />} />
            </Route>
            <Route path="candidates/folders/:folderId" element={<FavouriteTable />} />
            <Route path="candidates/:candidateId" element={<UserProfile />} />
            <Route path="clients" element={<Clients />} />
            <Route path="placements" element={<Placement />} />
            <Route path="placements/:placementID" element={<PlacementDetails />} />
            <Route path="activities" element="activities" />
            <Route path="/dashboard/inbox" element={<Inbox />}>
              <Route
                path="/dashboard/inbox"
                element={
                  <div>
                    <InboxHome />
                  </div>
                }
              />
              <Route path="/dashboard/inbox/starred" element={<Starred />} />
              <Route path="/dashboard/inbox/sent" element={<Sent />} />
              <Route path="/dashboard/inbox/drafts" element={<Draft />} />
              <Route path="/dashboard/inbox/trash" element={<Trash />} />
            </Route>
            <Route
              path="/dashboard/inbox/:emailId"
              element={
                <div>
                  <MessageDetailes />
                </div>
              }
            />
            <Route path="/dashboard/reports">
              <Route path="/dashboard/reports/candidates" element={<div>candidates reports</div>} />
              <Route path="/dashboard/reports/jobs" element={<div>jobs reports</div>} />
              <Route path="/dashboard/reports/sales" element={<div>sales reports</div>} />
              <Route path="/dashboard/reports/hiring_performance" element={<div>Hiring Performance reports</div>} />
              <Route path="/dashboard/reports/leaderboard" element={<div>Leaderboard reports</div>} />
            </Route>
            <Route path="/dashboard/settings">
              <Route path="/dashboard/settings/profile" element={<ProfileSettings />} />
              <Route path="/dashboard/settings/email_settings">
                <Route path="/dashboard/settings/email_settings/templates" element={<TemplatesPage />} />
                <Route path="/dashboard/settings/email_settings/email-signature" element={<EmailSignature />} />
                <Route path="/dashboard/settings/email_settings/email-forwarding" element={<EmailForwarding />} />
                <Route
                  path="/dashboard/settings/email_settings/email-synchronization"
                  element={<EmailSynchronization />}
                />
              </Route>
              <Route path="/dashboard/settings/integrations" element={<SettingsIntegrations />} />
              <Route path="/dashboard/settings/performance" element={<Performance />} />
              <Route path="/dashboard/settings/notifications" element={<Notifications />} />
            </Route>
            <Route path="/dashboard/adminstration">
              <Route path="/dashboard/adminstration/account" element={<AccountAndUsers />} />
              <Route path="/dashboard/adminstration/integrations" element={<Integrations />} />
              <Route
                path="/dashboard/adminstration/carrer-resumes"
                element={
                  <div>
                    <CarrerResumes />
                  </div>
                }
              />
              <Route path="/dashboard/adminstration/features" element={<Features />} />
              <Route path="/dashboard/adminstration/data-management">
                <Route path="/dashboard/adminstration/data-management/data-import" element={<DataImport />} />
                <Route path="/dashboard/adminstration/data-management/logs" element={<Logs />} />
                <Route path="/dashboard/adminstration/data-management/tracking" element={<Tracking />} />
              </Route>
              <Route path="/dashboard/adminstration/subscription" element={<Subscription />} />
              <Route path="/dashboard/adminstration/job-boards" element={<JobBoard />} />
              <Route path="/dashboard/adminstration/customization">
                <Route path="/dashboard/adminstration/customization/contact-guests" element={<ContactGuests />} />
                <Route path="/dashboard/adminstration/customization/candidates" element={<CustomizationCandidates />} />
                <Route path="/dashboard/adminstration/customization/clients" element={<CustomizationClients />} />
                <Route path="/dashboard/adminstration/customization/jobs" element={<CustomizationJobs />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element="Not found" />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
