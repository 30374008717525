import { TemplatesTable } from 'modules/settings/components/organisms';
import React from 'react';

const TemplatesPage = () => {
  return (
    <div>
      <TemplatesTable />
    </div>
  );
};

export default TemplatesPage;
