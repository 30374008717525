import { Avatar, MenuNavLink } from 'modules/homepage/components/atoms';
import { useDispatch, useSelector } from 'react-redux';

import { Outlet } from 'react-router-dom';
import { TextInput } from '../../atoms';
import './styles.css';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { logOut, selectCurrentUser, selectIsLoggedIn } from 'features/authSlice';
import { openJobModal, openClientModal, openCandidateModal } from 'features/modalsSlice';
import { Navigate } from 'react-router-dom';
const linksData = [
  {
    text: 'Home',
    iconUrl: '/assets/icons/menu-icons/activities.svg',
    url: '/dashboard',
    active: true,
  },
  {
    text: 'Jobs',
    iconUrl: '/assets/icons/menu-icons/jobs.svg',
    url: '/dashboard/jobs',
  },
  {
    text: 'Candidates',
    iconUrl: '/assets/icons/menu-icons/clients.svg',
    url: '/dashboard/candidates',
  },
  {
    text: 'Clients',
    iconUrl: '/assets/icons/menu-icons/clients.svg',
    url: '/dashboard/clients',
  },
  {
    text: 'Placements',
    iconUrl: '/assets/icons/menu-icons/placements.svg',
    url: '/dashboard/placements',
  },
  // {
  //   text: 'Activities',
  //   iconUrl: '/assets/icons/menu-icons/activities.svg',
  //   url: '/dashboard/activities',
  // },
  // {
  //   text: 'inbox',
  //   iconUrl: '/assets/icons/menu-icons/inbox.svg',
  //   url: '/dashboard/inbox',
  // },
  // {
  //   text: 'reports',
  //   iconUrl: '/assets/icons/menu-icons/reports.svg',
  //   url: '/dashboard/reports',
  //   subLinks: [
  //     {
  //       text: 'Candidates',
  //       url: '/dashboard/reports/candidates',
  //       iconUrl: '/assets/icons/menu-icons/reports_candidates.svg',
  //     },
  //     {
  //       text: 'Jobs',
  //       url: '/dashboard/reports/jobs',
  //       iconUrl: '/assets/icons/menu-icons/reports_jobs.svg',
  //     },
  //     {
  //       text: 'Sales',
  //       url: '/dashboard/reports/sales',
  //       iconUrl: '/assets/icons/menu-icons/sales.svg',
  //     },
  //     {
  //       text: 'Hiring Performance',
  //       url: '/dashboard/reports/hiring_performance',
  //       iconUrl: '/assets/icons/menu-icons/customization.svg',
  //     },
  //     {
  //       text: 'Leaderboard',
  //       url: '/dashboard/reports/leaderboard',
  //       iconUrl: '/assets/icons/menu-icons/leaderboard.svg',
  //     },
  //   ],
  // },
  // {
  //   text: 'settings',
  //   iconUrl: '/assets/icons/menu-icons/settings.svg',
  //   url: '/dashboard/reports',
  //   subLinks: [
  //     {
  //       text: 'profile',
  //       url: '/dashboard/settings/profile',
  //       iconUrl: '/assets/icons/menu-icons/reports_candidates.svg',
  //     },
  //     {
  //       text: 'Email Settings',
  //       url: '/dashboard/settings/email_settings',
  //       iconUrl: '/assets/icons/menu-icons/reports_jobs.svg',
  //       subLinks: [
  //         {
  //           text: 'Templates',
  //           url: '/dashboard/settings/email_settings/templates',
  //         },
  //         {
  //           text: 'Email Signature',
  //           url: '/dashboard/settings/email_settings/email-signature',
  //         },
  //         {
  //           text: 'Email Forwarding',
  //           url: '/dashboard/settings/email_settings/email-forwarding',
  //         },
  //         {
  //           text: 'Email Synchronization',
  //           url: '/dashboard/settings/email_settings/email-synchronization',
  //         },
  //       ],
  //     },
  //     {
  //       text: 'Integrations',
  //       url: '/dashboard/settings/integrations',
  //       iconUrl: '/assets/icons/menu-icons/sales.svg',
  //     },
  //     {
  //       text: 'Preferences',
  //       url: '/dashboard/settings/performance',
  //       iconUrl: '/assets/icons/menu-icons/customization.svg',
  //     },
  //     {
  //       text: 'Notifications',
  //       url: '/dashboard/settings/notifications',
  //       iconUrl: '/assets/icons/menu-icons/leaderboard.svg',
  //     },
  //   ],
  // },
  // {
  //   text: 'Adminstration',
  //   iconUrl: '/assets/icons/menu-icons/lock.svg',
  //   url: '/dashboard/adminstration',
  //   subLinks: [
  //     {
  //       text: 'Account & Users',
  //       url: '/dashboard/adminstration/account',
  //       iconUrl: '/assets/icons/menu-icons/accounts.svg',
  //     },
  //     {
  //       text: 'Integrations',
  //       url: '/dashboard/adminstration/integrations',
  //       iconUrl: '/assets/icons/menu-icons/reports_jobs.svg',
  //     },
  //     {
  //       text: 'Career Page & resumes',
  //       url: '/dashboard/adminstration/carrer-resumes',
  //       iconUrl: '/assets/icons/menu-icons/resumes.svg',
  //     },
  //     {
  //       text: 'Features',
  //       url: '/dashboard/adminstration/features',
  //       iconUrl: '/assets/icons/menu-icons/features.svg',
  //     },
  //     {
  //       text: 'Data Management',
  //       url: '/dashboard/adminstration/data-management',
  //       iconUrl: '/assets/icons/menu-icons/data_mangement.svg',
  //       subLinks: [
  //         {
  //           text: 'Data import',
  //           url: '/dashboard/adminstration/data-management/data-import',
  //         },
  //         {
  //           text: 'Logs',
  //           url: '/dashboard/adminstration/data-management/logs',
  //         },
  //         {
  //           text: 'Tracking & archive',
  //           url: '/dashboard/adminstration/data-management/tracking',
  //         },
  //       ],
  //     },
  //     {
  //       text: 'Subscription',
  //       url: '/dashboard/adminstration/subscription',
  //       iconUrl: '/assets/icons/menu-icons/subscription.svg',
  //     },
  //     {
  //       text: 'Job Boards',
  //       url: '/dashboard/adminstration/job-boards',
  //       iconUrl: '/assets/icons/menu-icons/job_board.svg',
  //     },
  //     {
  //       text: 'Customization',
  //       url: '/dashboard/adminstration/job-board',
  //       iconUrl: '/assets/icons/menu-icons/customization.svg',
  //       subLinks: [
  //         {
  //           text: 'Contact & Guests',
  //           url: '/dashboard/adminstration/customization/contact-guests',
  //         },
  //         {
  //           text: 'Candidates',
  //           url: '/dashboard/adminstration/customization/candidates',
  //         },
  //         {
  //           text: 'Clients',
  //           url: '/dashboard/adminstration/customization/clients',
  //         },
  //         {
  //           text: 'Jobs',
  //           url: '/dashboard/adminstration/customization/jobs',
  //         },
  //       ],
  //     },
  //   ],
  // },
];
const PagesLayout = () => {
  const user = useSelector(selectCurrentUser);
  const firstName = user ? user.find((item) => item.Name === 'given_name') : null;
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [openNestedSubMenu, setOpenNestedSubMenu] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const handleSubMenuToggle = (index) => {
    setOpenSubMenu((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleNestedSubMenuToggle = (mainIndex, subIndex) => {
    const combinedIndex = `${mainIndex}-${subIndex}`;
    setOpenNestedSubMenu((prevIndex) => (prevIndex === combinedIndex ? null : combinedIndex));
  };

  const closeSubMenu = () => {
    setOpenSubMenu(null);
  };

  const closeNestedSubMenu = () => {
    setOpenNestedSubMenu(null);
  };
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="grid h-full">
      <header className={`bg-white h-24 py-4 px-11 border-b border-b-white-400 fixed top-0 left-0 right-0 z-10 `}>
        <nav className="grid grid-cols-3">
          <div className="grid grid-cols-3">
            <Link to="/dashboard" className="flex items-center gap-3">
              <img className="w-12 h-12 object-contain" src="/assets/logo/logo-main.svg" alt="ATS logo" />
              <p className="text-primary text-base">Re Hirez.ai</p>
            </Link>
          </div>
          <div>
            {/*
            <TextInput
              centerdLabel={true}
              name="search"
              onBlur={() => null}
              label="Search by Name , Job , Email or Client"
              classNames="rounded-lg py-2"
            />
             */}
          </div>
          <div className="flex justify-end items-center gap-20">
            <div className="flex items-center gap-8">
              <div className="cursor-pointer">
                {/* <img className="w-5 h-5" src="/assets/icons/header-icons/notification.svg" alt="" /> */}
              </div>
              <div className="relative" ref={dropdownRef}>
                <div className="cursor-pointer" onClick={toggleDropdown}>
                  <img className="w-5 h-5" src="/assets/icons/header-icons/add-icon.svg" alt="add icon" />
                </div>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-52 bg-white  rounded-sm shadow-lg text-[13px]">
                    <Link to="/dashboard/clients">
                      <div
                        onClick={() => {
                          dispatch(openClientModal());
                          setIsDropdownOpen(false);
                        }}
                        className="flex items-center gap-3 px-4 py-2 cursor-pointer hover:bg-white-400"
                      >
                        <img src="/assets/icons/menu-icons/clients.svg" alt="" className="w-4 h-4" />
                        Create Client
                      </div>
                    </Link>
                    <Link to="/dashboard/jobs">
                      <div
                        onClick={() => {
                          dispatch(openJobModal());
                          setIsDropdownOpen(false);
                        }}
                        className="flex items-center gap-3 px-4 py-2 cursor-pointer hover:bg-white-400"
                      >
                        <img src="/assets/icons/menu-icons/jobs.svg" alt="" className="w-4 h-4" />
                        Create Job
                      </div>
                    </Link>
                    <Link to="/dashboard/candidates">
                      <div
                        onClick={() => {
                          dispatch(openCandidateModal());
                          setIsDropdownOpen(false);
                        }}
                        className="flex items-center gap-3 px-4 py-2 cursor-pointer hover:bg-white-400"
                      >
                        <img src="/assets/icons/menu-icons/clients.svg" alt="" className="w-4 h-4" />
                        Create Candidate
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              <div className="cursor-pointer">
                {/* <img className="w-5 h-5" src="/assets/icons/header-icons/info.svg" alt="" /> */}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <p className="uppercase text-primary font-[500]">
                {firstName && <p className="uppercase text-primary font-[500]">{firstName.Value}</p>}
              </p>
              <button
                onClick={() => {
                  dispatch(logOut());
                  // logOut()
                }}
              >
                logout
              </button>
              <div className="">
                <Avatar avatarUrl="" alt={firstName?.Value} />
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="grid grid-cols-5 mt-24">
        <nav className="col-span-1 bg-white-100 py-8 pl-8 pr-2 h-full overflow-y-scroll fixed left-0 w-80 z-[1] pb-[15vh]">
          {linksData.map(({ text, iconUrl, url, active, subLinks }, index) => {
            return (
              <div key={index}>
                {subLinks ? (
                  <div
                    className="flex gap-4 py-4 px-4 items-center justify-start rounded-md cursor-pointer"
                    onClick={() => handleSubMenuToggle(index)}
                  >
                    <img src={iconUrl} className={openSubMenu === index ? 'filter-primary' : ''} alt="" />
                    <p
                      className={`text-base transition-all ${
                        openSubMenu === index ? 'text-primary' : ' text-neutral-100'
                      }`}
                    >
                      {text}
                    </p>
                    <span>
                      {openSubMenu === index ? (
                        <img className="w-4 h-2 filter-primary" src="/assets/icons/menu-icons/down_arrow.png" alt="" />
                      ) : (
                        <img className="w-4 h-2" src="/assets/icons/menu-icons/down_arrow.png" alt="" />
                      )}
                    </span>
                  </div>
                ) : (
                  <div onClick={closeSubMenu}>
                    <MenuNavLink key={index} active={active} iconUrl={iconUrl} text={text} url={url} />
                  </div>
                )}
                {openSubMenu === index && subLinks && (
                  <ul className="rounded-md cursor-pointer shadow-sm-inset pl-6 pb-2 w-full ">
                    {subLinks.map(({ text, iconUrl, url, subLinks: nestedSubLinks }, subIndex) => (
                      <div key={subIndex}>
                        {nestedSubLinks ? (
                          <div
                            className="flex gap-4 py-2 px-4 mr-6 items-center justify-start  cursor-pointer border-b border-neutral-200 "
                            onClick={() => handleNestedSubMenuToggle(index, subIndex)}
                          >
                            <img
                              src={iconUrl}
                              className={`w-4 h-6 ${
                                openNestedSubMenu === `${index}-${subIndex}` ? 'filter-primary' : ''
                              }`}
                              alt=""
                            />
                            <p
                              className={`text-[13px] transition-all ${
                                openNestedSubMenu === `${index}-${subIndex}` ? 'text-primary' : ' text-neutral-100'
                              }`}
                            >
                              {text}
                            </p>
                            <span>
                              <img
                                src="/assets/icons/menu-icons/down_arrow.png"
                                className={`w-4 h-2 ${
                                  openNestedSubMenu === `${index}-${subIndex}` ? 'filter-primary' : ''
                                }`}
                                alt=""
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="border-b border-neutral-200 mr-6 " onClick={closeNestedSubMenu}>
                            <MenuNavLink
                              classNames="py-2 px-4 text-[13px]"
                              key={subIndex}
                              text={text}
                              url={url}
                              iconUrl={iconUrl}
                            />
                          </div>
                        )}
                        {openNestedSubMenu === `${index}-${subIndex}` && nestedSubLinks && (
                          <ul className="ml-10 text-sm ">
                            {nestedSubLinks.map(({ text, url }, nestedIndex) => (
                              <div key={nestedIndex} className="flex ">
                                <MenuNavLink classNames="pt-2 px-4" key={nestedIndex} text={text} url={url} />
                              </div>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
        </nav>
        <main className="col-span-4 relative left-[270px]">
          <div className="p-8 ml-5">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default PagesLayout;
