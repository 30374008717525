import { apiSlice } from 'app/api/apiSlice';

const candidatesApi = apiSlice.injectEndpoints({
  reducerPath: 'candidates',
  endpoints(builder) {
    return {
      fetchCandidates: builder.query({
        query: ({ page, limit }) => {
          return {
            url: '/applicants/list',
            method: 'GET',
            params: {
              _page: page,
              _limit: limit,
            },
          };
        },
      }),
      createCandidate: builder.mutation({
        query: (body) => ({
          url: '/applicants/applicant/',
          method: 'POST',
          body,
        }),
      }),

      deleteCandidate: builder.mutation({
        query: (candidateId) => ({
          url: `/applicants/delete`,
          method: 'DELETE',
          body: candidateId,
        }),
      }),

      uploadResume: builder.mutation({
        query: (payload) => ({
          url: '/applicants/parse/',
          method: 'POST',
          body: payload,
        }),
      }),
      uploadJsonCsv: builder.mutation({
        query: (payload) => ({
          url: '/applicants/bulk/',
          method: 'POST',
          body: payload,
        }),
      }),

      getCandidateById: builder.query({
        query: (candidateId) => ({
          url: `/applicants/${candidateId}`,
          method: 'GET',
        }),
      }),

      updateCandidate: builder.mutation({
        query: ({ candidateId, data }) => ({
          url: `/applicants/${candidateId}`,
          method: 'PUT',
          body: data,
        }),
      }),
    };
  },
});

export const {
  useFetchCandidatesQuery,
  useCreateCandidateMutation,
  useDeleteCandidateMutation,
  useUploadResumeMutation,
  useUploadJsonCsvMutation,
  useGetCandidateByIdQuery,
  useUpdateCandidateMutation,
} = candidatesApi;

export { candidatesApi };
