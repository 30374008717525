import React from 'react';

const AttachmentList = () => {
  const attachments = [
    { name: 'Ahmed Resume', date: '28 Mar, 2024' },
    { name: 'Ahmed Resume', date: '28 Mar, 2024' },
    { name: 'Ahmed Resume', date: '28 Mar 2024' },
    { name: 'Ahmed Resume', date: '28 Mor, 2024' },
    { name: 'Ahmed Resume', date: '28 Mar, 2024' },
    { name: 'Ahmed Resume', date: '28 Mar, 2024' },
    { name: 'Ahmed Resume', date: '28 Mar 2024' },
    { name: 'Ahmed Resume', date: '28 Mor, 2024' },
  ];

  return (
    <div>
      <h2 className="text-2xl font-medium tracking-wide mb-4">Attachments</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
        {attachments.map((attachment) => (
          <div
            key={attachment.name}
            className="bg-white border border-white-300  rounded-md shadow-md p-4 flex flex-col items-center justify-between"
          >
            <img src="/assets/icons/file-pdf.png" alt="file" className="w-20 my-5 " />
            <div className="flex flex-col items-center">
              <h3 className="text-lg font-medium">{attachment.name}</h3>
              <p className="text-neutral-300 ">{attachment.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttachmentList;
