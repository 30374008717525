// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compose-modal-quill .ql-toolbar {
  position: absolute;
  width: 100%;
  z-index: 1;
  border: none;
  margin: 16px 0px 0 0px;
  border-radius: 0.5rem;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/modules/Inbox/components/atoms/ComposeModal/ComposeModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,qBAAqB;EACrB,+CAA+C;AACjD","sourcesContent":[".compose-modal-quill .ql-toolbar {\n  position: absolute;\n  width: 100%;\n  z-index: 1;\n  border: none;\n  margin: 16px 0px 0 0px;\n  border-radius: 0.5rem;\n  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
