import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

const EmptyPerformanceMetrics = () => {
  return (
    <article className="flex flex-col gap-8 shadow-lg rounded-md px-10 py-6">
      <div className="grid grid-cols-2">
        <Typography htmlTag="h3" variant="mainCardTitle" className="tracking-widest">
          Candidate performance
        </Typography>
        <Typography htmlTag="h3" variant="mainCardTitle" className="tracking-widest">
          Job performance
        </Typography>
      </div>
      <div className="flex flex-col items-center">
        <Typography className="font-semibold tracking-widest text-base">
          You don't have any applicants or jobs to be graphed.{' '}
        </Typography>
        <Typography className=" tracking-wider text-base">
          Once you add any job or candidate , the graph will appear here.{' '}
        </Typography>
      </div>
    </article>
  );
};

export default EmptyPerformanceMetrics;
