import { BasicModal } from 'modules/shared/components/molecules';
import { twMerge } from 'tailwind-merge';
import React, { useState } from 'react';
import { AddNewFolder } from '../../organisms';
import { useDeleteFolderMutation, useFetchFoldersQuery } from 'features/candidatesFoldersSlice';
import { toast } from 'react-toastify';
import Loader from 'modules/shared/components/atoms/Loader';
const CandidateCategoryCard = ({ numberOfCandidates, title, onClick, folderId, candidatesData }) => {
  const [isModalOpended, setIsModalOpended] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const { refetch: getFoldersList } = useFetchFoldersQuery({ page: 1, limit: 20 });
  const [deleteFolder, { isLoading, isSuccess, isError, error }] = useDeleteFolderMutation();
  const handleDelete = async (e) => {
    const currentFolderId = e.currentTarget.dataset.folderId;
    setCurrentFolderId(currentFolderId);
    try {
      await deleteFolder(folderId).unwrap();
      getFoldersList();
      toast.success('Folder deleted successfully');
      setCurrentFolderId(null);
    } catch (error) {
      const {
        data: { message },
      } = error;
      toast.error(message);
      setCurrentFolderId(null);
    }
  };

  const closeModal = () => {
    setIsModalOpended(false);
  };
  const openModal = () => {
    setIsModalOpended(true);
  };
  const isCurrentFolderId = currentFolderId === folderId;

  return (
    <div className="flex flex-row justify-between">
      <div
        className={twMerge(
          `relative flex flex-col items-start rounded-md p-2 shadow-sm shadow-gray	gap-1`,
          isCurrentFolderId ? 'opacity-50 opacity-50 pointer-events' : '',
        )}
      >
        <img className="absolute top-6 right-4" src="/assets/icons/star_icon.svg" alt="" />
        <img
          className="mx-auto my-4 cursor-pointer"
          src="/assets/icons/folder_placeholder.png"
          alt=""
          onClick={onClick}
        />
        <span className="border-b border-gray w-full mx-auto"></span>
        <h4 className="text-black text-sm tracking-wide">{title}</h4>
        <div className="flex justify-between mx-auto">
          <div className="flex justify-between mr-5 items-center text-sm text-primary truncate bg-secondary-shd3 py-1 px-2 rounded-md ">
            <img src="/assets/icons/persons_icon.svg" alt="" />
            <span className="ml-2">{numberOfCandidates} candidates</span>
          </div>
          <div className="flex justify-between">
            <img className="mr-3 cursor-pointer w-5" src="/assets/icons/folder_edit.svg" alt="" onClick={openModal} />
            {isCurrentFolderId ? <Loader size="sm" /> : ''}
            {!isCurrentFolderId ? (
              <img
                className="cursor-pointer w-4"
                src="/assets/icons/folder_delete.svg"
                data-folder-id={folderId}
                alt=""
                onClick={handleDelete}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <BasicModal
        isOpen={isModalOpended}
        onClose={closeModal}
        title={`Edit ${title} folder`}
        closeOnOverlayClick={false}
      >
        <AddNewFolder candidatesData={candidatesData} closeModal={closeModal} isEditing={true} folderId={folderId} />
      </BasicModal>
    </div>
  );
};

export default CandidateCategoryCard;
