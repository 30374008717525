export const notificationsEditData = [
  {
    id: 1,
    name: 'Account',
    description:
      'You will receive notifications when you make a change in the administration such as enabling or disabling features.',
  },
  {
    id: 2,
    name: 'Users',
    description: 'Admins will receive notifications when a user or a guest is added, updated, or deleted.',
  },
  {
    id: 3,
    name: 'Client',
    description:
      'You will receive notifications when a client you own is moved, edited, or archived as well as when its ownership or visibility is modified.',
  },
  {
    id: 4,
    name: 'Job',
    description:
      'You will receive notifications when a job you own is edited, archived or has its ownership transferred.',
  },
  {
    id: 5,
    name: 'Candidate',
    description:
      'You will receive notifications when a candidate you own is edited, archived or has its ownership transferred.',
  },
  {
    id: 6,
    name: 'Folder',
    description:
      'You will receive notifications when a folder you own , shared with a team member is edited or deleted.',
  },
  {
    id: 7,
    name: 'Pipelines',
    description:
      'You will receive notifications when a match you own or a match in a job you own is created, moved between stages, dropped, and un dropped.',
  },
  {
    id: 8,
    name: 'Activities',
    description:
      'You will receive notifications when an activity is added, edited, or deleted under a job, client or candidate you own.',
  },
  {
    id: 9,
    name: 'Notes',
    description:
      'You will receive notifications when a note is added, edited, or deleted under a job, client or candidate you own',
  },
  {
    id: 10,
    name: 'Attachments',
    description:
      'You will receive notifications when an attachment is uploaded or deleted under a job, client or candidate you own.',
  },
  {
    id: 11,
    name: 'Inbox',
    description: 'You will receive notifications when receiving email replies in email threads you are subscribed to.',
  },
];
