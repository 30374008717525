import { useCreatePlacementMutation } from 'features/placementsSlice';
import { Button, PercentageBar, Typography } from 'modules/shared/components/atoms';
import { BasicModal } from 'modules/shared/components/molecules';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PlacementCandidatesTable } from '../../molecules';

const CustomTableCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => {
  return (
    <>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="checkbox"
          className="
            relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[3px] bg-white border border-neutral-200 gradient-border-checkbox outline-none 
            before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] 
            checked:border-primary checked:bg-white checked:before:opacity-[0.16] 
            checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:transform checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-primary checked:after:bg-transparent checked:after:content-[''] 
            hover:cursor-pointer focus:shadow-none focus:before:scale-100 focus:before:opacity-[0.12] 
            focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] 
            checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
            checked:focus:after:absolute checked:focus:after:top-1/2 checked:focus:after:left-1/2 checked:focus:after:transform checked:focus:after:-translate-x-1/2 checked:focus:after:-translate-y-1/2 checked:focus:after:block checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-primary checked:focus:after:bg-transparent 
            dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary"
          style={{ height: '20px', width: '20px' }}
          ref={ref}
          onClick={onClick}
          {...rest}
        />
      </div>
    </>
  );
});

const customStyles = {
  headRow: {
    style: {
      borderRadius: '10px 10px 0 0 ',
      backgroundColor: '#E9F0FE',
    },
  },
  headCells: {
    style: {
      fontWeight: '400',
      fontSize: '14px',
      padding: '0px',
    },
  },
  cells: {
    style: {
      padding: '0px',
    },
  },
};

const PlacementCandidates = ({ data, selectedJob, refetchPlacements, removeSearchData, placementFolderData }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [createPlacement, { isLoading, error }] = useCreatePlacementMutation();
  const handleSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleCreatePlacementClick = () => {
    setIsModalOpen(true);
  };

  const handleSubmitPlacement = async (jobID, candidates, selectedJob, placementFolderData) => {
    const submissionData = {
      jobID,
      applicants: candidates,
      name: selectedJob,
      placement_name: placementFolderData.placement_name,
      placement_max_number: placementFolderData.placement_max_number,
    };
    try {
      await createPlacement(submissionData).unwrap();
      refetchPlacements();
      removeSearchData();
      setIsModalOpen(false);
      setSelectedRows([]);
      toast.success('Placement submitted successfully!');
    } catch (error) {
      const message = error?.data?.message || 'An unexpected error occurred. Please try again.';
      toast.error(message);
    }
  };

  const handleConfirmPlacement = () => {
    handleSubmitPlacement(data.jobID, selectedRows, selectedJob, placementFolderData);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const onCancel = () => {
    closeModal();
    removeSearchData();
  };

  return (
    <div className="w-full p-4 rounded-md border border-black-shd1">
      <div className="flex text-center justify-between items-center gap-3 py-2 mb-4">
        <h3 className="text-xl text-neutral font-semibold">{selectedJob}</h3>
        <Button
          classNames="text-primary  border-primary px-3 py-3 disabled:bg-white disabled:text-gray disabled:border-gray"
          onClick={handleCreatePlacementClick}
          disabled={selectedRows.length === 0}
        >
          Create Placement
        </Button>
      </div>
      <PlacementCandidatesTable
        data={data?.applicants}
        handleSelectedRowsChange={handleSelectedRowsChange}
        isSearchData={true}
      />
      <BasicModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="flex flex-col gap-4 mb-8 items-center">
          <img src="/assets/icons/placement-popup.svg" alt="delete" />
          <p className="text-center text-xl font-semibold text-neutral mt-4 tracking-wide">Create Placement</p>
          <p className="text-center text-lg  text-neutral tracking-wide">
            The placement has been successfully created.
          </p>
        </div>
        <div className="grid grid-cols-2 place-content-center mx-20">
          <div className="flex items-center justify-center px-4 cursor-pointer" onClick={onCancel}>
            <Typography variant="body" className="w-full text-center">
              Cancel
            </Typography>
          </div>
          <div>
            <Button
              type="submit"
              classNames="flex mx-auto bg-primary text-white border-none w-full"
              onClick={handleConfirmPlacement}
              disabled={isLoading ? true : false}
            >
              Continue
            </Button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};

export default PlacementCandidates;
