import { PaymentDetails, PaymentForm, Plans } from 'modules/adminstration/components/organisms';
import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

function index() {
  return (
    <div className="grid gap-14 px-10">
      <div className="grid gap-5">
        <Typography className="font-semibold text-xl tracking-widest">Subscription</Typography>
        <Plans />
      </div>
      <PaymentDetails />
      <PaymentForm />
    </div>
  );
}

export default index;
