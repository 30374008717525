import { Button, Typography } from 'modules/shared/components/atoms';
import React from 'react';
import { useParams } from 'react-router-dom';

const Email = () => {
  const { emailId } = useParams();
  return (
    <article className="shadow-xs">
      <div className="flex gap-6 border border-secondary-shd3 p-4 rounded-md">
        <img src="/assets/icons/back_arrow.svg" alt="arrow icon" className="w-5 opacity-50" />
        <img src="/assets/icons/download_icon.svg" alt="star icon" />
        <img src="/assets/icons/trash2.svg" alt="trash icon" className="filter-black opacity-50" />
      </div>
      <div className="flex justify-between  py-6 px-4">
        <div className="w-full flex gap-4 ml-10  text-center items-center">
          <Typography className="text-xl">Email Subject</Typography>
          <img src="/assets/icons/label_important.svg" alt="star icon" />
          <div className="flex text-center items-center gap-1 bg-white-400 px-1 rounded-[4px]">
            <span className="text-sm text-neutral-100 font-semibold"> Inbox</span>
            <img src="/assets/icons/close-icon2.svg" alt="star icon" />
          </div>
        </div>
        <img src="/assets/icons/print-icon.svg" alt="star icon" />
      </div>
      <div className="flex justify-between px-4 mb-5">
        <div className="flex gap-4 items-center Justify-between ">
          <div className="bg-white-400  rounded-full w-11 h-11 flex items-center justify-center">
            <img src="/assets/icons/empty_avatar.svg" alt="" />
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col">
              <span className="font-bold ">Michelle Rivera</span>
              <span className="text-sm text-neutral-100 ">to me</span>
            </div>
            <span className="text-sm text-neutral-100 mt-1 ">michelle.rivera@example.com</span>
          </div>
        </div>
        <div className="flex justify-between items-center gap-4">
          <span className="text-sm text-neutral-100"> 9:14 AM (8 hours ago)</span>
          <img src="/assets/icons/star_icon2.svg" alt="" className="filter-black w-6" />
        </div>
      </div>
      <div className="flex flex-row  py-1 pl-4 mr-14 border border-secondary-shd3">
        <img src="/assets/icons/image_off.svg" alt="" />
        <span className="font-semibold opacity-70 tracking-wide ml-3">Images are not displayed.</span>
        <span className="text-primary tracking-wide">
          Display images below-Always display images from email@domain.com
        </span>
      </div>
      <div className="p-4 h-40">
        <span className="text-sm tracking-widest pr-4">
          Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit
          dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute
          id deserunt nisi.
        </span>
      </div>
      <div className="flex gap-4 m-4 py-5 border-b border-secondary-shd3">
        <Button classNames="text-primary font-semibold text-[14px] px-4 py-2 border border-neutral-200 hover:bg-white hover:text-primary">
          Looking forward to it!
        </Button>
        <Button classNames="text-primary font-semibold text-[14px] px-4 py-2  border border-neutral-200 hover:bg-white hover:text-primary">
          We will be there!
        </Button>
        <Button classNames="text-primary font-semibold text-[14px] px-4 py-2  border border-neutral-200 hover:bg-white hover:text-primary">
          Thanks for the update!
        </Button>
      </div>
      <div className="flex gap-6 m-4 py-5">
        <Button
          iconUrl={'/assets/icons/reply-icon.svg'}
          classNames="text-neutral-100 font-semibold text-[14px] px-4 py-2 border border-neutral-100 hover:bg-white hover:text-primary"
        >
          Reply
        </Button>
        <Button
          iconUrl={'/assets/icons/right-arrow-bold.svg'}
          classNames="text-neutral-100 font-semibold text-[14px] px-4 py-2  border border-neutral-100 hover:bg-white hover:text-primary"
        >
          We will be there!
        </Button>
      </div>
    </article>
  );
};

export default Email;
