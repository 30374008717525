import React from 'react';
import Select from 'react-select';

const CustomSelect = ({
  selectDefaultValue,
  classNames,
  option,
  value,
  onChange,
  error = false,
  isMulti = true,
  styles,
  placeholder,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '5px',
      border: error
        ? state.isFocused
          ? '2px solid  #077D89'
          : '1px solid #E92C2C'
        : state.isFocused
        ? '2px solid  #077D89'
        : value.length > 0
        ? '1px solid  #077D89'
        : '1px solid #6B6B6B',
      borderRadius: '6px',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        // border: error ? state.isFocused ? '1px solid #077D96 ' : '1px solid #077D96' : '1px solid #077D96',
      },
      ...styles?.control, // Merge with user-provided styles for 'control'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'rgba(0, 133, 255, 0.10) ' : 'white',
      color: state.isFocused ? '#077D96' : '#333',
      ...styles?.option, // Merge with user-provided styles for 'option'
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: 'rgba(0, 133, 255, 0.10)',
        color: '#077D96',
        borderRadius: '6px',
        letterSpacing: '0.72px',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: '#077D96',
      postion: 'absolute',
      width: '20px',
      height: '15px',
      fontSize: '10px',
      right: '0',
      top: '0',
    }),
  };
  const defaultValue = (option, value) => {
    return option ? option.find((item) => item.value === value) : null;
  };
  return (
    <div>
      <Select
        defaultValue={selectDefaultValue}
        styles={{ ...customStyles }}
        isMulti={isMulti}
        placeholder={placeholder}
        value={defaultValue(option, value)}
        onChange={(value) => onChange(value)}
        options={option}
      />
    </div>
  );
};

export default CustomSelect;
